// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  margin-top: 60px;
}

h2 span,
h3 span {
  font-weight: normal;
}

.map-views,
.landmark {
  display: flex;
  width: 100%;
}

/* FIXME: 幅の指定がとりあえずのものです */
.data-area {
  width: 280px;
}

.landmark {
  margin-top: 70px;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/pages/details/details-page/weekly/weekly.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;;EAEE,aAAa;EACb,WAAW;AACb;;AAEA,2BAA2B;AAC3B;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["h2 {\n  margin-top: 60px;\n}\n\nh2 span,\nh3 span {\n  font-weight: normal;\n}\n\n.map-views,\n.landmark {\n  display: flex;\n  width: 100%;\n}\n\n/* FIXME: 幅の指定がとりあえずのものです */\n.data-area {\n  width: 280px;\n}\n\n.landmark {\n  margin-top: 70px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
