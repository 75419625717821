import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { BehavioralUsecase } from './behavioral.usecase';

@Component({
  selector: 'app-behavioral',
  templateUrl: './behavioral.component.html',
  styleUrls: ['./behavioral.component.scss'],
  providers: [BehavioralUsecase],
})
export class BehavioralComponent implements OnInit, OnChanges {
  @Input({ required: true }) mapID!: string;
  @Input({ required: true }) fromDateStr!: string;
  @Input({ required: true }) toDateStr!: string;
  @Input({ required: true }) enableHeatmap!: boolean;
  @Input({ required: true }) enableIllustmap!: boolean;
  @Input({ required: true })
  refreshDetection$!: Observable<void>;
  readonly onDestroy$ = new EventEmitter();

  readonly usecase = inject(BehavioralUsecase);

  ngOnInit(): void {
    this.refreshDetection$.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.usecase.fromDateStr.set(this.fromDateStr);
      this.usecase.toDateStr.set(this.toDateStr);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['mapID'] &&
      this.mapID &&
      (!this.usecase.mapID() ||
        (this.usecase.mapID() &&
          this.usecase.mapID() !== changes['mapID'].currentValue))
    ) {
      this.usecase.mapID.set(this.mapID);
    }

    if (
      changes['enableIllustmap'] &&
      changes['enableIllustmap'].currentValue !== null
    ) {
      this.usecase.enableIllustmap.set(this.enableIllustmap);
    }
  }

  handleCreateStandardMap(element: HTMLElement) {
    this.usecase.standardMapElm.set(element);
  }
}
