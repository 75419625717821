import { Injectable } from '@angular/core';

import * as L from 'leaflet';

/**
 * ログインユーザのデバイス情報.
 * PCかモバイルか, ブラウザ, 言語など.
 */
@Injectable({ providedIn: 'root' })
export class LoginUserDevice {
  /**
   * ログインユーザのデバイスがmobileかどうか判定する.
   * @returns true mobileである.
   *          false pcである.
   */
  get isMobile(): boolean {
    return (
      !sessionStorage.getItem('enablePCMode') &&
      L.Browser.mobile &&
      window.parent.screen.width < 1024 &&
      !(
        window.parent.screen.height >= 1024 &&
        window.orientation.toString().indexOf('90') != -1
      )
    );
  }

  /** ブラウザの言語 */
  get browserLang(): string {
    return (
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language
    );
  }
}
