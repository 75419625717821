import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDeviceComponent } from 'src/app/dashboard/ui/components/charts/chart-device/chart-device.component';
import { ChartLangComponent } from 'src/app/dashboard/ui/components/charts/chart-lang/chart-lang.component';
import {
  donutsChartOptions,
  donutsChartParams,
} from 'src/app/dashboard/ui/components/charts/chart-options';
import { ChartTimeflagComponent } from 'src/app/dashboard/ui/components/charts/chart-timeflag/chart-timeflag.component';
import { sumValues } from 'src/app/util';
import { ChartOsComponent } from '../../../../components/charts/chart-os/chart-os.component';
import { ChartUserAreaComponent } from '../../../../components/charts/chart-user-area/chart-user-area.component';
import { ColumnData, UserData } from './user-data-table/user-data';

interface UsageTimeData {
  timezone: string;
  users: number;
}

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['../../../../../../dashboard/common.css', './user.component.css'],
})
export class UserComponent implements OnInit {
  @Input() mapID!: string;
  @Input() fromDateStr!: string;
  @Input() toDateStr!: string;
  setDataFlag = false;

  language =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language;

  usageTimeCols: ColumnData[] = [];
  usageTimeData: UsageTimeData[] = [];

  commonCols: ColumnData[] = [];
  deviceData: UserData[] = [];
  osData: UserData[] = [];
  languageData: UserData[] = [];
  areaData: UserData[] = [];
  timezone: string;
  fileNemes: string[] = [];

  // 翻訳済みテキスト
  title = '';
  usageTime = '';
  device = '';
  download = '';
  languageLabel = '';
  area = '';
  timezoneLabel: string[] = [];
  dataNotFound = '';
  unknown = '';

  @ViewChild(ChartTimeflagComponent, { static: true })
  chartTimeflagComponent!: ChartTimeflagComponent;

  @ViewChild(ChartDeviceComponent, { static: true })
  chartDeviceComponent!: ChartDeviceComponent;

  @ViewChild(ChartOsComponent, { static: true })
  chartOsComponent!: ChartOsComponent;

  @ViewChild(ChartLangComponent, { static: true })
  chartLangComponent!: ChartLangComponent;

  @ViewChild(ChartUserAreaComponent, { static: true })
  chartUserAreaComponent!: ChartUserAreaComponent;

  dataTypeTimeflag = 'timeflag';

  dataTypeOs = 'os_version';
  chartTitleOs = 'OS';
  chartParamsOs = donutsChartParams;
  chartOptionsOs = donutsChartOptions;

  dataTypeArea = 'area';
  chartTitleArea = 'エリア';
  chartParamsArea = donutsChartParams;
  chartOptionsArea = donutsChartOptions;

  openFormatList_device = false;
  openFormatList_os = false;
  openFormatList_language = false;
  openFormatList_area = false;

  constructor(private translate: TranslateService) {
    this.translate
      .get([
        'Unknown',
        'user.Timezone',
        'user.Users',
        'user.SpentTime',
        'user.UserDistance',
        'user.Page',
        'user.Views',
        'user.iframe',
        'weekly.PageViews',
        'weekly.PageUsers',
        'user.iframeViews1',
        'user.iframeViews2',
        'user.iframeUsers',
        'weekly.iframeUsers',
        'user.Title',
        'user.UsageTime',
        'user.Device',
        'Download',
        'user.Language',
        'user.Area',
        'user.timezone1',
        'user.timezone2',
        'user.timezone3',
        'user.timezone4',
        'dashboard.DataNotFound',
      ])
      .subscribe((translations) => {
        this.unknown = translations['Unknown'];
        this.usageTimeCols = [
          {
            field: 'timezone',
            header: translations['user.Timezone'],
          },
          {
            field: 'users',
            header: translations['user.Users'],
          },
        ];

        this.commonCols = [
          {
            field: 'page_views',
            header:
              this.language.indexOf('ja') != -1
                ? [translations['user.Page'], translations['user.Views']]
                : [translations['weekly.PageViews'], ''],
          },
          {
            field: 'page_users',
            header:
              this.language.indexOf('ja') != -1
                ? [translations['user.Page'], translations['user.Users']]
                : [translations['weekly.PageUsers'], ''],
          },
          {
            field: 'iframe_views',
            header: [
              translations['user.iframeViews1'],
              translations['user.iframeViews2'],
            ],
          },
          {
            field: 'iframe_users',
            header:
              this.language.indexOf('ja') != -1
                ? [
                    translations['user.iframeViews1'],
                    translations['user.iframeUsers'],
                  ]
                : [translations['weekly.iframeUsers'], ''],
          },
        ];

        this.title = translations['user.Title'];
        this.usageTime = translations['user.UsageTime'];
        this.device = translations['user.Device'];
        this.download = translations['Download'];
        this.languageLabel = translations['user.Language'];
        this.area = translations['user.Area'];
        this.timezoneLabel[0] = translations['user.timezone1'];
        this.timezoneLabel[1] = translations['user.timezone2'];
        this.timezoneLabel[2] = translations['user.timezone3'];
        this.timezoneLabel[3] = translations['user.timezone4'];
        this.dataNotFound = translations['dashboard.DataNotFound'];

        this.chartTitleArea = this.area;
      });

    try {
      this.timezone =
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Tokyo'
          ? 'jst'
          : 'utc';
    } catch (e) {
      this.timezone = this.language.indexOf('ja') != -1 ? 'jst' : 'utc';
    }
  }

  ngOnInit(): void {
    this.setDummyData();
    // console.log(this.mapID, this.fromDateStr, this.toDateStr);
    this.refreshChart();
  }

  ngDoCheck(): void {
    this.setData();
  }

  refreshChart(): void {
    this.chartDeviceComponent.getAndDrawData(
      this.mapID,
      this.fromDateStr,
      this.toDateStr,
      this.timezone,
    );
    this.chartOsComponent.getAndDrawData(
      this.mapID,
      this.fromDateStr,
      this.toDateStr,
      this.timezone,
    );
    this.chartLangComponent.getAndDrawData(
      this.mapID,
      this.fromDateStr,
      this.toDateStr,
      this.timezone,
    );
    this.chartUserAreaComponent.getAndDrawData(
      this.mapID,
      this.fromDateStr,
      this.toDateStr,
      this.timezone,
    );
    this.chartTimeflagComponent.getAndDrawData(
      this.mapID,
      this.fromDateStr,
      this.toDateStr,
      this.timezone,
    );

    this.fileNemes = [];
    this.fileNemes.push('device_' + this.fromDateStr + '-' + this.toDateStr);
    this.fileNemes.push('os_' + this.fromDateStr + '-' + this.toDateStr);
    this.fileNemes.push('language_' + this.fromDateStr + '-' + this.toDateStr);
    this.fileNemes.push(
      'region-country_' + this.fromDateStr + '-' + this.toDateStr,
    );
  }

  setData(): void {
    // FIXME: 何度も呼ばれるので、値をチェックして制御したい
    // if (this.pageViews == undefined) return;
    // else if (this.setDataFlag) return;
    // console.log(this.pageViews != undefined, this.setDataFlag);
    // this.setDataFlag = true;

    this.usageTimeData = [
      {
        timezone: this.timezoneLabel[0],
        users: sumValues(this.chartTimeflagComponent.chartData[0].data),
      },
      {
        timezone: this.timezoneLabel[1],
        users: sumValues(this.chartTimeflagComponent.chartData[1].data),
      },
      {
        timezone: this.timezoneLabel[2],
        users: sumValues(this.chartTimeflagComponent.chartData[2].data),
      },
      {
        timezone: this.timezoneLabel[3],
        users: sumValues(this.chartTimeflagComponent.chartData[3].data),
      },
    ];

    /* !!!FIXME!!!
     * ページロード時に大量のエラーを吐くためデータが代入されるまではここでreturn
     */
    if (
      !this.chartDeviceComponent.aggData ||
      !this.chartOsComponent.aggData ||
      !this.chartLangComponent.aggData ||
      !this.chartUserAreaComponent.aggData
    ) {
      return;
    }

    this.deviceData = [];
    for (const key of this.chartDeviceComponent.tableDataLabels) {
      const obj = this.buildTableData(
        key.toString(),
        this.chartDeviceComponent.tableData,
      );
      this.deviceData.push(obj);
    }
    this.osData = [];
    for (const key of this.chartOsComponent.tableDataLabels) {
      const obj = this.buildTableData(
        key.toString(),
        this.chartOsComponent.tableData,
      );
      this.osData.push(obj);
    }
    this.languageData = [];
    for (const key of this.chartLangComponent.tableDataLabels) {
      const obj = this.buildTableData(
        key.toString(),
        this.chartLangComponent.tableData,
      );
      this.languageData.push(obj);
    }
    this.areaData = [];
    for (const key of this.chartUserAreaComponent.tableDataLabels) {
      const obj = this.buildTableData(
        key.toString(),
        this.chartUserAreaComponent.tableData,
      );
      this.areaData.push(obj);
    }
  }

  buildTableData(
    keyString: string,
    data: Record<string, Record<string, number>>,
  ): UserData {
    const obj: UserData = {
      category: '',
      page_views: 0,
      page_users: 0,
      iframe_views: 0,
      iframe_users: 0,
    };
    obj['category'] = keyString;
    obj['page_views'] = data['pv'][keyString] ? data['pv'][keyString] : 0;
    obj['page_users'] = data['uu'][keyString] ? data['uu'][keyString] : 0;
    obj['iframe_views'] = data['pv_iframe'][keyString]
      ? data['pv_iframe'][keyString]
      : 0;
    obj['iframe_users'] = data['uu_iframe'][keyString]
      ? data['uu_iframe'][keyString]
      : 0;
    return obj;
  }
  setDummyData(): void {
    this.usageTimeData = [
      {
        timezone: this.timezoneLabel[0],
        users: 0,
      },
      {
        timezone: this.timezoneLabel[1],
        users: 0,
      },
      {
        timezone: this.timezoneLabel[2],
        users: 0,
      },
      {
        timezone: this.timezoneLabel[3],
        users: 0,
      },
    ];
  }
}
