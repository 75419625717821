import {
  ILLUSTMAP_ROOT_URL,
  LandmarkDisp,
} from '../../ui/pages/details/details-page/behavioral/constants';
import { LandmarkCategories } from '../../ui/pages/details/details-page/behavioral/models';
import { LandmarkMarkers } from './landmark-markers';
import { XyHeatLayer, XyHeatmapData } from './xy-heat-layer';
import Stroly from 'stroly-js';

export class StrolyMap {
  private strolyMap!: Stroly;
  private landmarkMarkers!: LandmarkMarkers;
  private heatLayer!: XyHeatLayer;

  get hasHeatmapData() {
    return this.heatLayer?.hasHeatmapData ?? true;
  }

  private innerLandmarkCategories!: LandmarkCategories[];
  get landmarkCategories() {
    return this.innerLandmarkCategories;
  }

  constructor(
    readonly mapID: string,
    private readonly enableIllustmap: boolean,
  ) {}

  async init(
    element: HTMLElement,
    heatmapData: XyHeatmapData,
    landmarkDisp: LandmarkDisp,
  ) {
    await this.createStrolyMap(element);

    if (!this.enableIllustmap) {
      return;
    }

    this.heatLayer = new XyHeatLayer({
      maxPixel: {
        width: this.strolyMap.histmap.maxPixelW,
        height: this.strolyMap.histmap.maxPixelH,
      },
      width: this.strolyMap.histmap.w,
      height: this.strolyMap.histmap.h,
    });
    this.heatLayer.refreshHeatLayer(heatmapData);
    this.strolyMap.map.addLayer(this.heatLayer.layer);

    if (!this.innerLandmarkCategories.length) {
      this.strolyMap.histmap.initBounds();
      return;
    }

    this.landmarkMarkers = new LandmarkMarkers(
      this.innerLandmarkCategories,
      'Stroly',
    );
    this.landmarkMarkers.setLandmarkMarkers(landmarkDisp);
    this.strolyMap.map.addLayer(this.landmarkMarkers.layer);

    this.strolyMap.histmap.initBounds();
  }

  private async createStrolyMap(element: HTMLElement) {
    this.strolyMap = new Stroly(
      element,
      this.mapID,
      {
        tile: `${ILLUSTMAP_ROOT_URL}/tiles`,
        resource: `${ILLUSTMAP_ROOT_URL}`,
      },
      {
        mapOptions: {
          zoomSnap: 0,
          zoomDelta: 0.5,
          zoom: 5,
          attributionControl: false,
          scrollWheelZoom: false,
          preferCanvas: true,
        },
      },
    );

    return await this.strolyMap.renderBaseLayer({
      onReady: () => {
        this.innerLandmarkCategories = (
          this.strolyMap.getLandmarkCategories() as LandmarkCategories[]
        ).filter((category) =>
          category.landmark.find((landmark) => landmark.isPublished),
        );
      },
    });
  }

  refreshLandmarkMarkers(landmarkDisp: LandmarkDisp) {
    this.landmarkMarkers.setLandmarkMarkers(landmarkDisp);
  }

  refreshHeatLayer(data: XyHeatmapData) {
    if (this.heatLayer) {
      this.heatLayer.refreshHeatLayer(data);
    }
  }

  get center() {
    return this.strolyMap.map.getCenter();
  }

  resize(center: L.LatLngExpression) {
    this.strolyMap.map.invalidateSize();
    this.strolyMap.map.setView(center, this.strolyMap.map.getZoom());
  }
}
