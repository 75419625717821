import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'round',
})
export class RoundPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: number): string {
    if (typeof value !== 'number') {
      return value;
    }
    const length = value.toString().length;

    if (length < 6) {
      return this.decimalPipe.transform(value) ?? '';
    }

    return (
      this.decimalPipe.transform(value.toString().substr(0, length - 3)) + 'k'
    );
  }
}
