import { Injectable } from '@angular/core';

type CsvRow = string[] | Record<string, string>;

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  // refs.https://blog.mudatobunka.org/entry/2017/04/23/135753
  ARRAY;
  OBJECT;
  dataType: symbol | undefined;
  data: CsvRow[] = [];

  constructor() {
    this.ARRAY = Symbol('ARRAY');
    this.OBJECT = Symbol('OBJECT');
  }

  exportChartData(data: CsvRow[], filename: string): void {
    this.setData(data);
    this.save(filename + '.csv');
  }

  // NOTE: 単体テストを書くために一旦publicにしている.
  setData(data: CsvRow[]): void {
    this.data = data;

    if (0 == this.data.length) {
      this.dataType = this.ARRAY;
    } else if (this.isObject(data[0])) {
      this.dataType = this.OBJECT;
    } else if (this.isArray(data[0])) {
      this.dataType = this.ARRAY;
    } else {
      throw Error('Error: 未対応のデータ型です');
    }
  }

  // NOTE: 単体テストを書くために一旦publicにしている.
  toString(): string {
    if (this.dataType === this.ARRAY) {
      const data = this.data as string[][];
      return data
        .map((record) => record.map((field) => this.prepare(field)).join(','))
        .join('\n');
    } else if (this.dataType === this.OBJECT) {
      const data = this.data as Record<string, string>[];
      const keys = Array.from(this.extractKeys(data));

      const arrayData: string[][] = data.map((record) =>
        keys.map((key) => record[key]),
      );

      return ([] as string[][])
        .concat([keys], arrayData)
        .map((record) => record.map((field) => this.prepare(field)).join(','))
        .join('\n');
    } else {
      return '';
    }
  }

  private save(filename = 'data.csv'): void {
    if (!filename.match(/\.csv$/i)) {
      filename = filename + '.csv';
    }

    // console.info('filename:', filename);
    // console.table(this.data);

    const csvStr = this.toString();

    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csvStr], { type: 'text/csv' });
    const url = window.URL || window.webkitURL;
    const blobURL = url.createObjectURL(blob);

    const a = document.createElement('a');
    a.download = decodeURI(filename);
    a.href = blobURL;
    a.type = 'text/csv';

    a.click();
  }

  private extractKeys(data: Record<string, string>[]): Set<string> {
    return new Set(
      ([] as string[]).concat(
        ...data.map((record) => Object.getOwnPropertyNames(record)),
      ),
    );
  }

  private prepare(field: string | null): string {
    return field == null ? '""' : '"' + field.replace(/"/g, '""') + '"';
  }

  private isObject(obj: unknown): boolean {
    return '[object Object]' === Object.prototype.toString.call(obj);
  }

  private isArray(obj: unknown): boolean {
    return '[object Array]' === Object.prototype.toString.call(obj);
  }
}
