import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private readonly snackBar = inject(MatSnackBar);
  private snackbarCloseText = '';

  constructor() {
    inject(TranslateService)
      .get('snackbar.close')
      .subscribe((translation) => {
        this.snackbarCloseText = translation;
      });
  }

  openSnackbar(message: string) {
    this.snackBar.open(message, this.snackbarCloseText, {
      verticalPosition: 'top',
    });
  }
}
