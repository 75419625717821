// for dev env
export const environment = {
  envName: 'dev',
  production: false,
  defaultLang: 'en',
  firebase: {
    apiKey: 'AIzaSyAoGCm7W3vae09lceHvsr6Z9MnD6gQ2IVw',
    authDomain: 'dev-stroly-dashboard-aaa0d.firebaseapp.com',
    projectId: 'dev-stroly-dashboard-aaa0d',
    storageBucket: 'dev-stroly-dashboard-aaa0d.appspot.com',
    messagingSenderId: '785680512582',
    appId: '1:785680512582:web:39f5363406205fafcfbc47',
  },
  mapboxAccessToken:
    'pk.eyJ1IjoiY3JlYXRpdmUiLCJhIjoiY2twcDZ0MG9hMDZyaDJ2czR0bHNhcmExciJ9.NtDzj3SfvBvCFwMN4e4pQw',
  strolyURL: 'https://strolycom.gtest.dev.stroly.jp', // firebaseの開発環境用
  authApiHost: 'https://prod.dev.stroly.jp',
};
