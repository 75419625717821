// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-1 i {
  color: #cea716;
}

.label-2 i {
  color: #417b79;
}

.label-3 i {
  color: #6b96cb;
}

.label-4 i {
  color: #b4addf;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/components/charts/chart-timeflag/chart-timeflag.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".label-1 i {\n  color: #cea716;\n}\n\n.label-2 i {\n  color: #417b79;\n}\n\n.label-3 i {\n  color: #6b96cb;\n}\n\n.label-4 i {\n  color: #b4addf;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
