import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'updatedDate',
})
export class DisplayDatePipe implements PipeTransform {
  transform(date: Date): string {
    return dayjs(date).format('YYYY/MM/DD HH:mm');
  }
}
