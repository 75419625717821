import { Injectable, inject } from '@angular/core';
import { DatareportRepository } from '../repositories/datareport.repository';
import { XysDto } from '../repositories/dto/datareport.dto';

export type CentralData = XysDto['data'][number];

export interface XYData {
  v4: {
    data: CentralData[];
  };
}

@Injectable({ providedIn: 'root' })
export class HeatmapXyDataUsecase {
  private readonly datareportRepository = inject(DatareportRepository);

  async fetchXYData(
    mapID: string,
    fromDateStr: string,
    toDateStr: string,
  ): Promise<XYData> {
    const xysDtos = await this.datareportRepository.fetchXys(
      mapID,
      fromDateStr,
      toDateStr,
    );
    const xyData: XYData = {
      v4: {
        data: this.filterData(xysDtos.flatMap((xysDto) => xysDto.data)),
      },
    };
    return xyData;
  }

  private filterData(data: CentralData[]): CentralData[] {
    return data.filter((val) => val.x > 0 && val.y > 0);
  }
}
