import { Pipe, PipeTransform } from '@angular/core';
import { multiByteSubStr } from '../../util';

@Pipe({
  name: 'substr',
})
export class SubstringPipe implements PipeTransform {
  transform(str: string, len: number): string {
    if (typeof str !== 'string' || len == 0) {
      return str;
    }

    return multiByteSubStr(str, len);
  }
}
