// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-range {
  margin-right: 10px;
  height: 8px;
  width: 132px;
  background: linear-gradient(to right, #7a7acc 0%, #7a7acc 33%, #ccc 33%, #ccc 100%);
  border: none;
  border-radius: 8px;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
}

/* Chrome */
.input-range::-webkit-slider-thumb {
  position: relative;
  width: 17px;
  height: 17px;
  display: block;
  -webkit-appearance: none;
  cursor: pointer;
  background-color: #66c;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 3px 3px 6px -1px rgba(0, 0, 0, 0.8);
}

/* firefox */
input[type=range]::-moz-range-thumb {
  background: #66c;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  border: 2px solid #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/ui/components/input-range/input-range.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,mFAAA;EAOA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,oCAAA;EACA,wBAAA;AALF;;AAQA,WAAA;AACA;EACE,kBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,wBAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;EACA,0BAAA;EACA,sBAAA;EACA,+CAAA;AALF;;AAQA,YAAA;AACA;EACE,gBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;AALF","sourcesContent":[".input-range {\n  margin-right: 10px;\n  height: 8px;\n  width: 132px;\n  background: linear-gradient(\n    to right,\n    #7a7acc 0%,\n    #7a7acc 33%,\n    #ccc 33%,\n    #ccc 100%\n  );\n  border: none;\n  border-radius: 8px;\n  outline: none;\n  transition: background 450ms ease-in;\n  -webkit-appearance: none;\n}\n\n/* Chrome */\n.input-range::-webkit-slider-thumb {\n  position: relative;\n  width: 17px;\n  height: 17px;\n  display: block;\n  -webkit-appearance: none;\n  cursor: pointer;\n  background-color: #66c;\n  border-radius: 50%;\n  -webkit-border-radius: 50%;\n  border: 2px solid #fff;\n  box-shadow: 3px 3px 6px -1px rgba(0, 0, 0, 0.8);\n}\n\n/* firefox */\ninput[type='range']::-moz-range-thumb {\n  background: #66c;\n  width: 17px;\n  height: 17px;\n  border-radius: 50%;\n  border: 2px solid #fff;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
