import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { MapIdTitle } from '../ui/pages/index-page/index.page';
import urlJoin from 'proper-url-join';
import { environment } from '../../../environments/environment';
import { firstValueFrom } from 'rxjs';

interface JWTData {
  email: string;
  exp: number;
  orig_iat: number;
  user_id: number;
  username: string;
}

@Injectable({
  providedIn: 'root',
})
export class JwtAuthService {
  hostAddress = '';
  tokenAuthAddress = 'api/v1/r/token-auth/';
  tokenRefreshAddress = 'api/v1/r/token-refresh/';
  tokenVerifyAddress = 'api/v1/r/token-verify/';

  constructor(private http: HttpClient) {}

  setHostAddress(hostAddress: string): void {
    this.hostAddress = hostAddress;
  }

  logout(): void {
    localStorage.removeItem('strolyJwtToken');
    location.href = urlJoin(environment.authApiHost, 'logout');
  }

  decodeJWT(jwt: string): void {
    const decoded = jwt.split('.').map(decodeURI);
    // console.log(decoded);
  }

  parseJwt(token: string): JWTData {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  }

  async get_jwt_token(
    url: string,
    data: { email: string; password: string },
  ): Promise<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      }),
    };
    // console.log(httpOptions, data, url);
    return firstValueFrom(
      this.http.post<Record<string, string>>(url, data, httpOptions),
    )
      .then((result) => {
        return result.token;
      })
      .catch((err) => {
        // console.log(err);
        // if (err.error.text) {
        //   console.log(err.error.text);
        //   return JSON.parse(err.error.text);
        // }
        if (err.message) {
          console.log(err.message);
          // return err.message;
        }

        return Promise.reject(err);
      });
  }

  async get_from_authed_url(apiUrl: string, token: string): Promise<any> {
    // eslint-disable-line @typescript-eslint/no-explicit-any
    const url = urlJoin(this.hostAddress, apiUrl);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: 'JWT ' + token,
      }),
      withCredentials: true,
    };
    return firstValueFrom(this.http.get(url, httpOptions))
      .then((result) => {
        // console.log('authed_url: in then', result);
        return result;
      })
      .catch((err) => {
        // console.log('authed_url: in catch', err);
        console.log(err);
        if (err.error.text) {
          console.log(err.error.text);
          return JSON.parse(err.error.text);
        }
        if (err.message) {
          console.log(err.message);
          return err.message;
        }

        return Promise.reject(err);
      });
  }

  async get_from_authed_url_by_post(
    url: string,
    token: string,
  ): Promise<unknown> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
        Authorization: 'JWT ' + token,
      }),
      withCredentials: true,
    };
    return firstValueFrom(this.http.post(url, { token: token }, httpOptions))
      .then((result) => {
        // console.log('authed_url: in then', result);
        return result;
      })
      .catch((err) => {
        // console.log('authed_url: in catch', err);
        if (err.error.text) {
          console.log(err.error.text);
          return JSON.parse(err.error.text);
        }
        if (err.message) {
          console.log(err.message);
        }
        return err;

        return Promise.reject(err);
      });
  }

  async authToken(email: string, password: string): Promise<string> {
    const url = urlJoin(this.hostAddress, this.tokenAuthAddress, {
      trailingSlash: true,
    });
    const data: { email: string; password: string } = {
      email: email,
      password: password,
    };
    const authResult = await this.get_jwt_token(url, data);
    return authResult;
  }

  async refleshToken(token: string): Promise<Record<string, string>> {
    const url = urlJoin(this.hostAddress, this.tokenRefreshAddress, {
      trailingSlash: true,
    });
    const result_token = await this.tokenApi(url, token);
    return result_token;
  }

  async verifyToken(token: string): Promise<boolean> {
    const url = urlJoin(this.hostAddress, this.tokenVerifyAddress, {
      trailingSlash: true,
    });
    const result_token = await this.tokenApi(url, token);
    return result_token.token === token;
  }

  async tokenApi(
    url: string,
    token: string,
  ): Promise<{ [key: string]: string }> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      }),
    };
    return firstValueFrom(this.http.post(url, { token: token }, httpOptions))
      .then((result) => {
        // console.log('autheresult: ', result);
        return result;
      })
      .catch((err) => {
        // console.log('authed_url: in catch', err);
        if (err.error.text) {
          console.log(err.error.text);
          return JSON.parse(err.error.text);
        }
        if (err.message) {
          console.log(err.message);
          return err.message;
        }
        return Promise.reject(err);
      });
  }
}
