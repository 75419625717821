import { effect, inject, Injectable, signal } from '@angular/core';
import {
  HeatmapXyDataUsecase,
  XYData,
} from 'src/app/dashboard/usecases/heatmap-xy-data.usecase';
import {
  HeatmapLatlngDataUsecase,
  LatlngData,
} from 'src/app/dashboard/usecases/heatmap-latlng-data.usecase';
import { StrolyMap } from '../../../../../domains/map/stroly-map';
import { StandardMap } from '../../../../../domains/map/standard-map';
import {
  ADJUSTED_HEATMAP_RADIUS,
  DEFAULT_HEATMAP_RADIUS,
  LandmarkDisp,
  LIMIT_DISTANCE,
} from './constants';
import { LandmarkCategories } from './models';

type Landmark = {
  id: number;
  name: { [lang: string]: string };
  description: string;
  x?: string;
  y?: string;
  lat: string;
  lng: string;
};

export interface LandmarkCategory {
  landmark: Landmark[];
  name: Record<string, string>;
  styleUrl: string;
}

export interface IllustmapAttributes {
  category: string;
  name: { [lang: string]: string };
  description: string;
  idx: number;
  id: number;
}

@Injectable()
export class BehavioralUsecase {
  readonly heatmapRadius = signal(DEFAULT_HEATMAP_RADIUS);
  readonly landmarkDisp = signal(LandmarkDisp.Full);
  readonly fromDateStr = signal('');
  readonly toDateStr = signal('');
  readonly standardMapElm = signal<HTMLElement | undefined>(undefined);
  readonly enableIllustmap = signal<boolean | undefined>(undefined);
  readonly isHeatmapModalOpen = signal(false);
  private readonly landmarkCategories = signal<LandmarkCategories[]>([]);

  private strolyMap!: StrolyMap;
  private readonly standardMap = new StandardMap();
  private xyLocations: [number, number][] = [];
  private latLngLocations: [number, number][] = [];
  readonly mapID = signal('');

  private readonly init = effect(() => {
    if (
      this.fromDateStr() &&
      this.toDateStr() &&
      this.mapID() &&
      this.enableIllustmap() !== undefined
    ) {
      this.fetchDataAndRefresh();

      if (!this.strolyMap || this.mapID() !== this.strolyMap.mapID) {
        this.strolyMap = new StrolyMap(
          this.mapID(),
          this.enableIllustmap() as boolean,
        );
      }
    }
  });

  private isInitialized = false;
  private readonly initStandardMap = effect(() => {
    if (
      !this.standardMapElm() ||
      this.landmarkCategories().length === 0 ||
      this.enableIllustmap() !== false ||
      this.isInitialized
    ) {
      return;
    }

    this.standardMap.init(
      this.standardMapElm() as HTMLElement,
      this.landmarkCategories(),
      {
        heatmapRadius: this.heatmapRadius(),
        locationsLatlng: this.latLngLocations,
      },
      this.landmarkDisp(),
    );

    // 対角線上の距離が100kmを超える場合はヒートマップが見づらいので見た目の調整をする
    if (this.standardMap.defaultBoundsDistance > LIMIT_DISTANCE) {
      setTimeout(() => {
        this.updateHeatmapRadius(ADJUSTED_HEATMAP_RADIUS);
        this.updateLandmarkDisp(LandmarkDisp.Pin);
      });
    }

    this.isInitialized = true;
  });

  updateHeatmapRadius(radius: number) {
    this.heatmapRadius.set(radius);
    this.refreshHeatLayer(this.heatmapRadius());
  }

  updateLandmarkDisp(landmarkDisp: LandmarkDisp) {
    this.landmarkDisp.set(landmarkDisp);
    this.refreshLandmarkMarkers(this.landmarkDisp());
  }

  updateIsHeatmapModalOpen(isHeatmapModalOpen: boolean) {
    const center = this.enableIllustmap()
      ? this.strolyMap.center
      : this.standardMap.center;
    this.isHeatmapModalOpen.set(isHeatmapModalOpen);

    // 実施タイミングをズラさないとマップが動かない
    setTimeout(() => {
      if (this.enableIllustmap()) {
        this.strolyMap.resize(center);
      } else {
        this.standardMap.resize(center);
      }
    });
  }

  private challengeCount = 1;
  async initStrolyMap(element: HTMLElement) {
    if (this.innerIsDataFetchProgress && this.challengeCount < 10) {
      setTimeout(async () => await this.initStrolyMap(element), 500);
      this.challengeCount += 1;
      return;
    }

    await this.strolyMap.init(
      element,
      {
        heatmapRadius: this.heatmapRadius(),
        locations: this.xyLocations,
      },
      this.landmarkDisp(),
    );

    this.landmarkCategories.set(this.strolyMap.landmarkCategories);
  }

  private innerIsDataFetchProgress = false;
  get isDataFetchProgress() {
    return this.innerIsDataFetchProgress;
  }

  private readonly heatmapXyUsecase = inject(HeatmapXyDataUsecase);
  private readonly heatmapLatlngUsecase = inject(HeatmapLatlngDataUsecase);
  private async fetchDataAndRefresh(): Promise<void> {
    this.innerIsDataFetchProgress = true;
    if (this.enableIllustmap()) {
      const fetchedData: XYData = await this.heatmapXyUsecase
        .fetchXYData(this.mapID(), this.fromDateStr(), this.toDateStr())
        .catch(() => ({ v4: { data: [] } }));
      this.xyLocations = this.getLocations(fetchedData);
    } else {
      const fetchedDataLatlng: LatlngData = await this.heatmapLatlngUsecase
        .fetchLatlngData(this.mapID(), this.fromDateStr(), this.toDateStr())
        .catch(() => ({ v4: { data: [] } }));
      this.latLngLocations = this.getLocationsLatlng(fetchedDataLatlng);
    }

    this.refreshHeatLayer(this.heatmapRadius());
    this.innerIsDataFetchProgress = false;
  }

  private hasStrolyHeatmapData = false;
  private hasStandardHeatmapData = false;
  get hasHeatMapData() {
    return this.enableIllustmap()
      ? this.hasStrolyHeatmapData
      : this.hasStandardHeatmapData;
  }

  private refreshHeatLayer(radius: number) {
    if (this.enableIllustmap()) {
      if (!this.strolyMap) {
        return;
      }
      if (this.xyLocations.length === 0) {
        this.hasStrolyHeatmapData = false;
      } else {
        this.hasStrolyHeatmapData = true;

        this.strolyMap.refreshHeatLayer({
          heatmapRadius: radius,
          locations: this.xyLocations,
        });
      }
    } else {
      if (!this.standardMap) {
        return;
      }

      if (this.latLngLocations.length === 0) {
        this.hasStandardHeatmapData = false;
      } else {
        this.hasStandardHeatmapData = true;

        this.standardMap.refreshHeatLayer({
          heatmapRadius: radius,
          locationsLatlng: this.latLngLocations,
        });
      }
    }
  }

  private refreshLandmarkMarkers(landmarkDisp: LandmarkDisp) {
    if (this.strolyMap && this.enableIllustmap()) {
      this.strolyMap.refreshLandmarkMarkers(landmarkDisp);
    }

    if (this.standardMap && !this.enableIllustmap()) {
      this.standardMap.refreshLandmarkMarkers(landmarkDisp);
    }
  }

  private getLocations(d: XYData): [number, number][] {
    return d.v4.data.map((val) => [val.x, val.y]);
  }

  private getLocationsLatlng(d: LatlngData): [number, number][] {
    return d.v4.data.map((val) => [val.lat, val.long]);
  }
}
