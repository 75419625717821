import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  Inject,
  inject,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  DOCUMENT,
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';

import { DetailedComponent } from './detailed/detailed.component';
import { UserComponent } from './user/user.component';
import { environment } from '../../../../../../environments/environment';

import dayjs from 'dayjs';

import { PageScrollService } from 'ngx-page-scroll-core';
import {
  AuthedUserProfile,
  AuthedUserProfileService,
} from '../../../../services/authed-user-profile.service';
import { DateFilterFormBuilder } from './date-filter/date-filter-form';
import {
  MapDetailSummaryDto,
  MapUsecase,
} from 'src/app/dashboard/usecases/map.usecase';
import { LoginUserDevice } from 'src/app/shared/utils/login-user-device';
import { MapRepository } from 'src/app/dashboard/repositories/map.repository';
import { Subject } from 'rxjs';
import { RefreshHeatmapData } from './behavioral/heatmap-view/heatmap';
import { MapOptionsDto } from 'src/app/dashboard/repositories/dto/map-options.dto';

@Component({
  selector: 'app-dashboard',
  templateUrl: './details.page.html',
  styleUrls: ['./details.page.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class DetailsPage implements OnInit {
  mapID: string;

  protected readonly dateFilterForm = inject(DateFilterFormBuilder).build();
  private readonly mapUsecase = inject(MapUsecase);
  private readonly loginUserDevice = inject(LoginUserDevice);
  private readonly mapRepository = inject(MapRepository);

  // TODO: getter/setterはこのコンポーネントのリファクタ時に修正予定
  get fromDateStr(): string {
    if (!this.dateFilterForm?.fromDateControl) {
      return '';
    }
    return dayjs(this.dateFilterForm.fromDate).format('YYYYMMDD');
  }
  set fromDateStr(value: string) {
    if (!this.dateFilterForm?.fromDateControl) {
      return;
    }
    this.dateFilterForm.fromDateControl.setValue(dayjs(value).toDate());
  }

  get toDateStr(): string {
    return dayjs(this.dateFilterForm.toDate).format('YYYYMMDD');
  }
  set toDateStr(value: string) {
    this.dateFilterForm.toDateControl.setValue(dayjs(value).toDate());
  }

  private innerMapOptions?: MapOptionsDto;
  get mapOptions() {
    return this.innerMapOptions;
  }

  // 日付を更新した時に各グラフを更新するためのSubject
  private refreshDetection = new Subject<void>();
  protected readonly refreshDetection$ = this.refreshDetection.asObservable();
  // 日付を更新した時にヒートマップを更新するためのSubject
  private refreshHeatmapDetection = new Subject<RefreshHeatmapData>();
  protected readonly refreshHeatmapDetection$ =
    this.refreshHeatmapDetection.asObservable();

  @ViewChild(DetailedComponent)
  private detailedComponent!: DetailedComponent;

  @ViewChild(UserComponent)
  private userComponent!: UserComponent;

  activePart = 'weekly';

  mapTitle = '';
  thumbnailUrl = '';
  mapDescription = '';
  mapCreator = '';
  mapCopyright = '';
  mapTargetArea = '';
  mapUrl = '';
  headerOpen = true;
  headerPositionFixed = false;
  headerPosition = 0;
  headerHeight = '0px';
  headerWidth = '0px';
  dateFilterFixed = false;

  dateFilterPosition = 0;
  loading = true;
  isPublished = false;
  part = '';
  isScrollRunning = false;
  enableHeatmap = false;

  // 翻訳済みテキスト
  creater = '';
  copyright = '';
  targetArea = '';
  open = '';
  close = '';
  behavioralTitle = '';
  heatmapCaption = '';
  landmarkSetting = '';
  heatmapRadiusLabel = '';
  mapNoName = '';
  mapNoDescription = '';

  constructor(
    private cd: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private meta: Meta,
    private title: Title,
    private pageScrollService: PageScrollService,
    private location: Location,
    private authedUserProfileService: AuthedUserProfileService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.mapID = this.route.snapshot.paramMap.get('mapID') ?? '';
    this.route.fragment.subscribe((fragment) => {
      this.part = fragment ? fragment : '';
    });
    this.setDate();

    this.translate
      .get([
        'mapSelect.Description',
        'dashboard.Creator',
        'dashboard.Copyright',
        'dashboard.TargetArea',
        'dashboard.Open',
        'dashboard.Close',
        'dashboard.NoTitle',
        'dashboard.NoDescription',
      ])
      .subscribe((translations) => {
        // title系は地図名取得後に変更
        this.meta.updateTag({
          name: 'description',
          content: translations['mapSelect.Description'],
        });
        this.meta.updateTag({
          property: 'og:description',
          content: translations['mapSelect.Description'],
        });

        this.creater = translations['dashboard.Creator'];
        this.copyright = translations['dashboard.Copyright'];
        this.targetArea = translations['dashboard.TargetArea'];
        this.open = translations['dashboard.Open'];
        this.close = translations['dashboard.Close'];

        this.mapNoName = translations['dashboard.NoTitle'];
        this.mapNoDescription = translations['dashboard.NoDescription'];
      });
  }

  async ngOnInit(): Promise<void> {
    if (this.loginUserDevice.isMobile) {
      // NOTE: mobile版でinfoを表示するためにsessionStorageに値を入れる.
      // TODO: mobile用のinfoを扱うクラスに移動.
      sessionStorage.setItem('fromDetail', 'true');
      this.router.navigateByUrl('/');
    }

    await this.authedUserProfileService.initAuthedUserProfile();
    if (!this.authedUserProfile?.id) {
      window.location.href = `${environment.authApiHost}/login?next=${window.location.href}`;
    }

    if (
      this.authedUserProfile?.id &&
      (this.mapID, this.fromDateStr, this.toDateStr)
    ) {
      // 地図の編集権限があるかを確認
      await this.isMyMap();

      // mapIDが入ってからグラフ系の描写を行う
      const mapDetail = await this.mapUsecase.getMapDetailSummary(this.mapID);
      this.checkPermissionOrRedirect(mapDetail);
      await this.setMapDetailTexts(mapDetail);

      this.innerMapOptions = await this.mapUsecase.getMapOptions(this.mapID);

      this.enableHeatmap =
        mapDetail.mapOptionHeatmapEnable ||
        this.authedUserProfile.isStrolyStaff ||
        this.authedUserProfile.isProPlanUser;
      this.refresh();
      this.cd.markForCheck(); // marks path
      if (this.part != '') {
        setTimeout(() => {
          this.pageScrollService.scroll({
            document: this.document,
            scrollTarget: '#' + this.part,
          });
        }, 500);
      }
    }
    this.headerPosition =
      document.getElementById('db_header')?.getBoundingClientRect().top ??
      0 + window.pageYOffset;
    this.dateFilterPosition =
      document.getElementById('filter_date')?.getBoundingClientRect().top ??
      0 + window.pageYOffset;

    window.addEventListener('scroll', () => {
      if (!this.isScrollRunning) {
        this.isScrollRunning = true;

        // 描画する前のタイミングで呼び出してもらう
        window.requestAnimationFrame(() => {
          this.fixPositionElements();
          this.changeActivePart();
          this.cd.markForCheck();
          this.isScrollRunning = false;
        });
      }
    });
  }

  ngDoCheck(): void {
    this.fixPositionElements();
  }

  get authedUserProfile(): AuthedUserProfile {
    return this.authedUserProfileService.getAuthedUserProfile();
  }

  // ヒートマップや各グラフを更新.
  refresh(): void {
    this.refreshDetection.next();
  }

  drawComponents(): void {
    const queryParams = {
      date_s: this.fromDateStr,
      date_e: this.toDateStr,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      fragment: this.route.snapshot.fragment ?? '',
      queryParams: queryParams,
    });

    this.refresh();
    // TODO: 以下もrefreshDetection$ を利用して更新するようにしてください.
    this.detailedComponent.refreshChart();
    this.userComponent.refreshChart();
  }

  getCalcedDate(num: number): Date {
    const today = new Date();
    today.setDate(today.getDate() + num);
    return today;
  }

  getDateStr(date: Date): string {
    return dayjs(date).format('YYYYMMDD');
  }

  async isMyMap(): Promise<void> {
    // メールアドレスが@stroly.jpの場合は確認しない
    if (
      this.authedUserProfile &&
      (this.authedUserProfile.isStrolyStaff ||
        this.isSpecialUser(this.authedUserProfile, this.mapID))
    ) {
      return;
    }

    const mymaps = await this.mapRepository.fetchMyMapsWithTagName();
    // 地図未作成の場合はTOPへ移動
    if (mymaps['count'] == 0) {
      this.unvisibleMap('nomapdata');
    }

    for (const mapData of mymaps['results']) {
      if (mapData.map_key == this.mapID) {
        return;
      }
    }

    this.unvisibleMap('unauthorized');
  }

  unvisibleMap(type: string): void {
    // this.loading = false;
    if (type === 'unauthorized') {
      alert('このデータレポートを表示する権限が有りません');
    } else if (type === 'nomapdata') {
      alert('データがないか非公開のマップです');
    }
    location.href = '/';
  }

  // スタッフ権限以外で、アクセス権限を持たない地図のアクセス統計を
  // 特定のユーザのみ閲覧可能にする
  isSpecialUser(profile: AuthedUserProfile, mapid: string): boolean {
    const specials: Record<string, { mapids: string[] }> = {
      // 閲覧させるユーザのemail
      'h-ueda+admin@stroly.jp': {
        // 閲覧を許可する地図IDの配列
        mapids: ['1650529606'],
      },
    };
    if (profile.extended_userprofile.email in specials) {
      if (specials[profile.extended_userprofile.email].mapids.includes(mapid)) {
        console.warn(
          `You can show the stats of the "${mapid}" map because you are a special user`,
        );
        return true;
      }
    }
    return false;
  }

  /** 地図の閲覧権限があるか確認する. 閲覧権限がなければリダイレクトする. */
  private checkPermissionOrRedirect(mapDetail: MapDetailSummaryDto): void {
    const canViewMap =
      this.authedUserProfile &&
      (this.authedUserProfile.isStrolyStaff ||
        this.isSpecialUser(this.authedUserProfile, this.mapID));

    if (!mapDetail.isFoundMap) {
      if (canViewMap) {
        // スタッフ権限ありの場合は、非公開／削除地図でもアクセス統計を表示する
        console.warn(
          `[Warning]: "${this.mapID}" might have been deleted or unpublished map. `,
        );
      } else {
        this.unvisibleMap('nomapdata');
      }
    }
  }

  /** 地図情報をsetする. */
  private async setMapDetailTexts(
    mapDetail: MapDetailSummaryDto,
  ): Promise<void> {
    this.mapTitle = mapDetail.title;
    this.mapDescription = mapDetail.description;
    this.mapCreator = mapDetail.creator;
    this.mapCopyright = mapDetail.copyright;
    this.mapTargetArea = mapDetail.targetArea;
    this.mapUrl = mapDetail.url;
    this.isPublished = mapDetail.isPublished;
    this.thumbnailUrl = mapDetail.thumbnailUrl;

    this.updateMeta(this.mapTitle);
    this.loading = false;
    this.cd.markForCheck();
  }

  /** メタ情報の更新 */
  private updateMeta = (mapTitle: string): void => {
    this.translate
      .get('dashboard.PageTitle', { pageTitle: mapTitle })
      .subscribe((pageTitle: string) => {
        this.title.setTitle(pageTitle);
        this.meta.updateTag({
          property: 'og:title',
          content: pageTitle,
        });
        this.meta.updateTag({
          property: 'og:site_name',
          content: pageTitle,
        });
      });
  };

  toggleHeader(): void {
    this.headerOpen = !this.headerOpen;
  }

  fixPositionElements(): void {
    if (window.pageYOffset > this.headerPosition) {
      this.headerPositionFixed = true;
    } else {
      this.headerPositionFixed = false;
    }

    const header = document.getElementById('db_header');
    const clientHeight = header?.clientHeight ?? 0;
    const clientWidth = header?.clientWidth ?? 0;

    if (
      window.pageYOffset + clientHeight >=
      this.dateFilterPosition - clientHeight + 45 // +45は吸い付きの調整。できれば計算で出したい
    ) {
      this.headerHeight = clientHeight + 10 + 'px'; // 余白の調整
      this.headerWidth = clientWidth + 'px';
      this.dateFilterFixed = true;
    } else {
      this.dateFilterFixed = false;
    }
  }

  changeActivePart(): void {
    const partPosition: Record<string, number> = {
      weekly: 0,
      behavioral: 0,
      detailed: 0,
      user: 0,
    };

    try {
      Object.getOwnPropertyNames(partPosition).forEach((partName) => {
        partPosition[partName] =
          document.getElementById(partName)?.getBoundingClientRect().top ??
          0 + window.pageYOffset;
      });
    } catch (e) {
      // loadが終わってない時に発生するエラー
      return;
    }

    // ヘッダーが画面の中央より上に来たらアクティブを切り替え
    const keys = Object.getOwnPropertyNames(partPosition);
    const centerPosition = window.pageYOffset + window.innerHeight / 2;
    for (let i = 0; i < keys.length; i++) {
      if (centerPosition > partPosition[keys[i]]) {
        this.activePart = keys[i];
      }
    }
  }

  setDate(): void {
    const queryParams = this.route.snapshot.queryParams;
    const maxDate = this.getDateStr(this.getCalcedDate(-1));
    // 日付の整合性チェック
    if (
      !queryParams.date_s ||
      !queryParams.date_e ||
      isNaN(Number(queryParams.date_s)) ||
      isNaN(Number(queryParams.date_e)) ||
      Number(queryParams.date_s) > Number(queryParams.date_e) ||
      Number(queryParams.date_e) > Number(maxDate) ||
      this.computeDays(queryParams.date_s, queryParams.date_e) > 366
    ) {
      this.toDateStr = maxDate;
      this.fromDateStr = this.getDateStr(this.getCalcedDate(-28));
      if (queryParams.count == 0) {
        return;
      }

      if (location.hash == '') {
        this.location.replaceState(location.pathname);
      } else {
        this.location.replaceState(location.pathname + location.hash);
      }
      return;
    }

    this.fromDateStr = queryParams.date_s;
    this.toDateStr = queryParams.date_e;

    this.dateFilterForm.fromDateControl.setValue(
      dayjs(queryParams.date_s).toDate(),
    );
    this.dateFilterForm.toDateControl.setValue(
      dayjs(queryParams.date_e).toDate(),
    );
  }

  computeDays(fromDate = this.fromDateStr, toDate = this.toDateStr): number {
    return dayjs(toDate).diff(fromDate, 'day') + 1;
  }
}
