import { L } from 'stroly-js';
import 'leaflet.heat';

export interface XyHeatmapData {
  heatmapRadius: number;
  locations: [number, number][];
}

export class XyHeatLayer {
  constructor(
    private readonly histMapSize: {
      maxPixel: {
        width: number | null;
        height: number | null;
      };
      width: number | null;
      height: number | null;
    },
  ) {}

  private innerHeatLayer!: L.HeatLayer;
  get layer() {
    return this.innerHeatLayer;
  }

  private innerHasHeatmapData = false;
  get hasHeatmapData() {
    return this.innerHasHeatmapData;
  }

  refreshHeatLayer(data: XyHeatmapData) {
    const locations = data.locations;
    const heatmapRadius = data.heatmapRadius;

    const rate_x = (1 / (this.histMapSize.maxPixel.width ?? 1)) * 10;
    const rate_y = (1 / (this.histMapSize.maxPixel.height ?? 1)) * 10;

    const res: L.HeatLatLngTuple[] = locations
      .filter(
        (val) =>
          val[0] >= 0 &&
          val[0] <= (this.histMapSize.width ?? 0) &&
          val[1] >= 0 &&
          val[1] <= (this.histMapSize.height ?? 0),
      )
      .map((val) => [val[1] * rate_y, val[0] * rate_x, 1]);

    if (!res.length) {
      this.innerHasHeatmapData = false;
      return;
    }

    if (this.innerHeatLayer) {
      this.innerHeatLayer.setLatLngs(res); // 既存のレイヤーを更新
      this.innerHeatLayer.setOptions({ radius: heatmapRadius }); // オプションも更新
    } else {
      this.innerHeatLayer = L.heatLayer(res, { radius: heatmapRadius });
    }

    this.innerHasHeatmapData = true;
  }
}
