import { Output } from '@angular/core';
import { FireStoreService } from '../../../../services/fire-store.service';
import { TranslateService } from '@ngx-translate/core';
import { ChartConfiguration, ChartType, TooltipItem } from 'chart.js';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
} from '@angular/core';

import { formatDateLabel } from '../../../../../util';
import { DatareportRepository } from 'src/app/dashboard/repositories/datareport.repository';
import { DateUtil } from 'src/app/shared/utils/date-util';

@Component({
  selector: 'app-chart-distance',
  templateUrl: './chart-distance.component.html',
  styleUrls: [
    '../chart.component.css',
    './chart-distance.component.css',
    '../../../../../dashboard/common.css',
  ],
})
export class ChartDistanceComponent implements OnInit {
  @Output() dateChangeEvent = new EventEmitter();
  chartTitle = '期間内 移動距離';
  chartType: ChartType = 'line';
  chartOptions: ChartConfiguration['options'] = {
    animation: {
      delay: 0,
    },
    elements: {
      point: {
        radius: 4,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          autoSkipPadding: 10,
          color: '#989eb3',
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 4,
          color: '#989eb3',
          callback: (tickValue: string | number): string => {
            return tickValue.toString() + 'm';
          },
        },
        grid: {
          color: '#d8dde5',
          drawBorder: false,
        },
      },
    },
    responsive: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        position: 'average',
        backgroundColor: '#6666cc',
        borderColor: '#fff',
        titleFont: {
          style: 'normal',
        },
        bodySpacing: 6,
        callbacks: {
          label: (context: TooltipItem<'line'>): string => {
            const label = context.dataset.label ?? '';
            const yLabel = context.parsed.y ?? '';
            return `${label}: ${yLabel.toLocaleString()}m`;
          },
        },
      },
    },
  };
  chartLabels = [''];
  chartData = [
    {
      data: [0],
      label: '全ユーザー移動距離',
      lineTension: 0,
      fill: false,
      borderWidth: 4,
      pointBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 4,
      borderColor: '#efe08f',
      pointBorderColor: '#efe08f',
      pointBackgroundColor: '#efe08f',
      backgroundColor: '#efe08f',
      pointHoverBorderColor: '#efe08f',
      pointHoverBackgroundColor: '#efe08f',
    },
    {
      data: [0],
      label: 'ユーザー平均移動距離',
      lineTension: 0,
      fill: false,
      borderWidth: 4,
      pointBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 4,
      borderColor: '#8fb8ef',
      pointBorderColor: '#8fb8ef',
      pointBackgroundColor: '#8fb8ef',
      backgroundColor: '',
      pointHoverBorderColor: '#8fb8ef',
      pointHoverBackgroundColor: '#8fb8ef',
    },
  ];

  // allData: Record<string, Record<string, number>>;
  // TODO: `!` を使わないように検討してください.
  allData!: Record<string, [number, number]>;
  dateLabel: string[] = [];
  irregularData = false;

  get active(): { [key: string]: string } {
    return {
      visibility: this.allData && !this.irregularData ? 'visible' : 'hidden',
    };
  }

  constructor(
    private readonly fsService: FireStoreService,
    private readonly datareportRepository: DatareportRepository,
    private readonly cd: ChangeDetectorRef,
    private readonly translate: TranslateService,
  ) {
    const language =
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language;

    this.translate
      .get([
        'behavioral.UsersDistance',
        'behavioral.AverageDistance1',
        'behavioral.AverageDistance2',
      ])
      .subscribe((translations) => {
        this.chartData[0].label = translations['behavioral.UsersDistance'];
        if (language.indexOf('ja') != -1) {
          this.chartData[1].label =
            translations['behavioral.AverageDistance1'] +
            translations['behavioral.AverageDistance2'];
        } else {
          this.chartData[1].label =
            translations['behavioral.AverageDistance1'] +
            ' ' +
            translations['behavioral.AverageDistance2'];
        }
      });
  }

  ngOnInit(): void {}

  isNumberStr(str: string): boolean {
    return Number.isFinite(Number(str));
  }

  // Ver.0 用 (廃止予定)
  /*
  getAndDrawData(
    mapID: string,
    fromDate: string,
    toDate: string,
    timezone: string,
  ): void {
    this.dateLabel = this.fsService.makeDateLabel(fromDate, toDate);

    // timezone追加版
    // this.item = this.fsService.makeDocumentStream(
    //   "distance_" + timezone,
    //   mapID
    // );
    this.item = this.fsService.makeDocumentStream('distance', mapID);

    this.item.subscribe(docData => {
      // console.log(docData);
      this.data = docData;
      const filteredData = this.fsService.filterData(docData, fromDate, toDate);
      // console.log(filteredData);
      this.allData = filteredData;
      if (this.allData == undefined) return;
      this.checkData();
      this.drawData();
    });
  }
*/

  // Ver.1 用
  async getAndDrawData(
    mapID: string,
    fromDate: string,
    toDate: string,
    timezone: string,
  ) {
    this.dateLabel = DateUtil.dateRangeWithFormat(fromDate, toDate);
    const dtos = await this.datareportRepository.fetchDistance(
      mapID,
      fromDate,
      toDate,
    );
    const data = this.dateLabel.reduce(
      (acc: Record<string, [number, number]>, date) => {
        const dto = dtos.find((dto) => dto.date === date);
        if (dto) {
          acc[dto.date] = [dto.inmap.SUM, dto.inmap.AVG];
        } else {
          acc[date] = [0, 0];
        }
        return acc;
      },
      {},
    );

    this.allData = data;
    this.checkData();
    this.drawData();
  }

  drawData(): void {
    // console.log(this.allData);
    if (Object.values(this.allData).length !== 0) {
      const dataWithDateLabel = this.fsService.makeDataWithDateLabelForDistance(
        this.dateLabel,
        this.allData,
      );
      this.chartData[0].data = dataWithDateLabel[0];
      this.chartData[1].data = dataWithDateLabel[1];
      this.chartLabels = formatDateLabel(this.dateLabel);
      // this.chartData[0].data = Object.values(this.allData).map(val => val[0]);
      // this.chartData[1].data = Object.values(this.allData).map(val => val[1]);
      // this.chartLabels = Object.keys(this.allData);
      this.cd.markForCheck(); // marks path
    }
  }

  checkData(): void {
    if (
      Object.entries(this.allData)
        .map((val) => val[0])
        .filter((value) => value == undefined).length > 0 ||
      Object.entries(this.allData)
        .map((val) => val[1])
        .filter((value) => value == undefined).length > 0
    ) {
      this.irregularData = true;
    } else {
      this.irregularData = false;
    }
  }
}
