import { Component, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardDatepickerComponent } from '../../../components/dashboard-datepicker/dashboard-datepicker.component';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { DateFilterComponent } from '../../../pages/details/details-page/date-filter/date-filter.component';
import { DateFilterFormBuilder } from '../../../pages/details/details-page/date-filter/date-filter-form';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { InputRangeComponent } from 'src/app/shared/ui/components/input-range/input-range.component';

@Component({
  selector: 'app-components-list',
  standalone: true,
  templateUrl: './components-list.page.html',
  imports: [
    CommonModule,
    DashboardDatepickerComponent,
    DateFilterComponent,
    InputRangeComponent,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
})
export class ComponentsListPage {
  /**
   * DashboardDatepickerComponent
   */
  dashbaordDatepickerFrom = new FormControl(new Date(), { nonNullable: true });

  /**
   * DateFilterComponent
   */
  protected readonly dateFilterForm = inject(DateFilterFormBuilder).build();

  /**
   * InputRangeComponent
   */
  inputRangeValue = signal(10);
  updateValue(value: number) {
    this.inputRangeValue.set(value);
  }
  inputRangeValue2 = signal(15);
  updateValue2(value: number) {
    this.inputRangeValue2.set(value);
  }
}
