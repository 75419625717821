// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-compilation {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

img {
  width: 85px;
  height: 85px;
}

p {
  margin-left: 50px;
  font-size: 16px;
  line-height: 2;
  color: #333;
}

/* mobile */
@media screen and (max-width: 768px) {
  .data-compilation {
    margin-top: 80px;
    flex-direction: column;
    align-items: center;
  }

  p {
    margin-left: 0;
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/pages/index-page/data-compilation/data-compilation.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,cAAc;EACd,WAAW;AACb;;AAEA,WAAW;AACX;EACE;IACE,gBAAgB;IAChB,sBAAsB;IACtB,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;AACF","sourcesContent":[".data-compilation {\n  margin-top: 50px;\n  display: flex;\n  justify-content: center;\n}\n\nimg {\n  width: 85px;\n  height: 85px;\n}\n\np {\n  margin-left: 50px;\n  font-size: 16px;\n  line-height: 2;\n  color: #333;\n}\n\n/* mobile */\n@media screen and (max-width: 768px) {\n  .data-compilation {\n    margin-top: 80px;\n    flex-direction: column;\n    align-items: center;\n  }\n\n  p {\n    margin-left: 0;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
