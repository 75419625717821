import { Injectable, inject } from '@angular/core';
import {
  collectionGroup,
  getDocs,
  orderBy,
  where,
  DocumentData,
  Firestore,
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  QuerySnapshot,
  query,
} from '@angular/fire/firestore';
import dayjs, { Dayjs } from 'dayjs';
import {
  AccessDto,
  DistanceDto,
  RankDto,
  TimeflagDto,
  XysDto,
  LatlngsDto,
} from './dto/datareport.dto';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { chunk } from 'src/app/shared/utils/chunk';
import { DateUtil } from 'src/app/shared/utils/date-util';

/**
 * データレポートアプリのデータを取得するためのリポジトリ.
 *
 * ref: [データレポートアプリ Firebase データモデル(v1-2021年版) | stroly Kibela](https://stroly.kibe.la/@h-ueda/7295)
 */
@Injectable({ providedIn: 'root' })
export class DatareportRepository {
  private readonly firestore: Firestore = inject(Firestore);
  private readonly CHUNK_SIZE = 10;

  /** 指定のmapIdsと開始日の中でrankを取得. */
  fetchRankByMapIds = async (
    mapIds: string[],
    startDate: Date,
  ): Promise<RankDto[]> => {
    const tasks: Promise<QuerySnapshot<RankDto>>[] = [];
    // mapIdを10件ずつにして取得する意図は以下のいずれかだと推測している.
    // - レスポンス肥大化防止のため
    // - in句の上限数に到達しないようにするため
    // - 負荷軽減のため(ただし, それならPromise.allより直列に実行した方が良さそうではある)
    for (let i = 0; i < mapIds.length; i += 10) {
      const chunkedMapIds = mapIds.slice(
        i,
        i + 10 > mapIds.length ? mapIds.length : i + 10,
      );
      const proc = getDocs<RankDto>(
        query(
          collectionGroup(this.firestore, 'v1rank'),
          orderBy('timestamp'),
          where('timestamp', '>=', startDate),
          where('map_id', 'in', chunkedMapIds),
        ).withConverter(this.converter<RankDto>()),
      );
      tasks.push(proc);
    }
    const res = await Promise.all(tasks);
    return res.flatMap((snapshot) => snapshot.docs.map((doc) => doc.data()));
  };

  /** 指定日の中でrankが高い順に取得 */
  fetchTopRank = async (dates: Dayjs[]): Promise<RankDto[]> => {
    const formattedDates = dates.map((d) => d.format('YYYY-MM-DD'));
    const res = await getDocs(
      query(
        collectionGroup(this.firestore, 'v1rank'),
        where('date', 'in', formattedDates),
        where('rank', '<=', 500),
        orderBy('rank'),
      ).withConverter(this.converter<RankDto>()),
    );
    return res.docs.map((doc) => doc.data());
  };

  fetchDistance = async (
    mapID: string,
    fromDate: string,
    toDate: string,
  ): Promise<DistanceDto[]> => {
    return this.fetchByDates<DistanceDto>(
      'v1distance',
      mapID,
      fromDate,
      toDate,
    );
  };

  fetchTimeflag = async (
    mapID: string,
    fromDate: string,
    toDate: string,
  ): Promise<TimeflagDto[]> => {
    return this.fetchByDates<TimeflagDto>(
      'v1timeflag',
      mapID,
      fromDate,
      toDate,
    );
  };

  fetchXys = async (
    mapID: string,
    fromDate: string,
    toDate: string,
  ): Promise<XysDto[]> => {
    return this.fetchByDates<XysDto>('v1xys', mapID, fromDate, toDate);
  };

  fetchLatlngs = async (
    mapID: string,
    fromDate: string,
    toDate: string,
  ): Promise<LatlngsDto[]> => {
    return this.fetchByDates<LatlngsDto>('v1latlongs', mapID, fromDate, toDate);
  };

  fetchByDates = async <T extends DocumentData>(
    collection: string,
    mapID: string,
    fromDate: string,
    toDate: string,
  ): Promise<T[]> => {
    const dates = DateUtil.dateRangeWithFormat(fromDate, toDate);
    const chunkedDates: string[][] = chunk(dates, this.CHUNK_SIZE);

    const findBy = async (dates: string[]) => {
      return getDocs(
        query(
          collectionGroup(this.firestore, collection),
          where('map_id', '==', mapID),
          where('date', 'in', dates),
        ).withConverter(this.converter<T>()),
      );
    };
    const snapshots = await Promise.all(
      chunkedDates.map((dates) => findBy(dates)),
    );
    const dtos = snapshots.flatMap((snapshot) =>
      snapshot.docs.map((doc) => doc.data()),
    );
    return dtos;
  };

  private converter<T extends DocumentData>(): FirestoreDataConverter<T> {
    return {
      toFirestore: (rank: T): T => rank,
      fromFirestore: (snapshot: QueryDocumentSnapshot<T>): T => {
        const data = snapshot.data();
        return { ...data };
      },
    };
  }
}
