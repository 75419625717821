// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-date {
  margin-top: 30px;
  padding: 20px;
  display: flex;
  align-items: center;
  width: 1150px;
  box-shadow: 0 0 10px 0 rgba(102, 102, 204, 0.1);
  border-radius: 10px;
  box-sizing: border-box;
}
.filter-date:hover {
  box-shadow: 0 0 5px 0 #66c;
}
.filter-date p {
  margin-right: 20px;
  color: #66c;
  font-weight: 700;
  font-size: 14px;
}
.filter-date span {
  margin-left: 10px;
  margin-right: 10px;
}

.btn-date-change {
  margin-left: 10px;
  padding: 10px;
  background: linear-gradient(to bottom, #ffffff, #e2e2e2);
  border: 1px solid #e4e4e4;
  border-radius: 3px;
  cursor: pointer;
}
.btn-date-change:hover {
  background: linear-gradient(to bottom, #f0eff6, #cac7e0);
}

.date-alert {
  padding-left: 10px;
  color: #66c;
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/pages/details/details-page/date-filter/date-filter.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,+CAAA;EACA,mBAAA;EACA,sBAAA;AACF;AACE;EACE,0BAAA;AACJ;AAEE;EACE,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;AAAJ;AAGE;EACE,iBAAA;EACA,kBAAA;AADJ;;AAKA;EACE,iBAAA;EACA,aAAA;EACA,wDAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;AAFF;AAIE;EACE,wDAAA;AAFJ;;AAMA;EACE,kBAAA;EACA,WAAA;EACA,eAAA;AAHF","sourcesContent":[".filter-date {\n  margin-top: 30px;\n  padding: 20px;\n  display: flex;\n  align-items: center;\n  width: 1150px;\n  box-shadow: 0 0 10px 0 rgba(102, 102, 204, 0.1);\n  border-radius: 10px;\n  box-sizing: border-box;\n\n  &:hover {\n    box-shadow: 0 0 5px 0 #66c;\n  }\n\n  p {\n    margin-right: 20px;\n    color: #66c;\n    font-weight: 700;\n    font-size: 14px;\n  }\n\n  span {\n    margin-left: 10px;\n    margin-right: 10px;\n  }\n}\n\n.btn-date-change {\n  margin-left: 10px;\n  padding: 10px;\n  background: linear-gradient(to bottom, #ffffff, #e2e2e2);\n  border: 1px solid #e4e4e4;\n  border-radius: 3px;\n  cursor: pointer;\n\n  &:hover {\n    background: linear-gradient(to bottom, #f0eff6, #cac7e0);\n  }\n}\n\n.date-alert {\n  padding-left: 10px;\n  color: #66c;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
