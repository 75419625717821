import {
  Component,
  ViewChild,
  Input,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartDistanceComponent } from 'src/app/dashboard/ui/components/charts/chart-distance/chart-distance.component';
import { CsvService } from 'src/app/dashboard/services/csv.service';
import { XlsxService } from 'src/app/dashboard/services/xlsx.service';

interface TooltipContext {
  UsersDistance: string;
  AverageDistance: string;
}
const initTooltipContext = (): TooltipContext => {
  return {
    UsersDistance: '',
    AverageDistance: '',
  };
};

@Component({
  selector: 'app-user-movement',
  templateUrl: './user-movement.component.html',
  styleUrls: [
    './user-movement.component.scss',
    '../../../../../../../dashboard/common.css',
  ],
})
export class UserMovementComponent implements OnDestroy, OnChanges {
  @Input() mapID!: string;
  @Input() fromDateStr!: string;
  @Input() toDateStr!: string;
  usersDistance = 0;
  averageDistance = 0;
  openFormatList = false;
  setDataFlag = false;
  timezone: string;
  language =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language;

  readonly onDestroy$ = new EventEmitter();

  // 翻訳済みテキスト
  userMovement = '';
  userDistance = '';
  averageDistanceLabel: string[] = [];
  download = '';
  tooltipContext: TooltipContext = initTooltipContext();
  dateLabel = '';
  dataNotFound = '';

  @ViewChild(ChartDistanceComponent, { static: true })
  public chartDistanceComponent!: ChartDistanceComponent;

  constructor(
    private translate: TranslateService,
    private csvService: CsvService,
    private xlsxService: XlsxService,
  ) {
    this.translate
      .get([
        'behavioral.UserMovement',
        'behavioral.UsersDistance',
        'behavioral.AverageDistance1',
        'behavioral.AverageDistance2',
        'Download',
        'behavioral.UsersDistanceTooltip',
        'behavioral.AverageDistanceTooltip',
        'export.Date',
        'dashboard.DataNotFound',
      ])
      .subscribe((translations) => {
        this.userMovement = translations['behavioral.UserMovement'];
        this.userDistance = translations['behavioral.UsersDistance'];
        this.averageDistanceLabel = [
          translations['behavioral.AverageDistance1'],
          translations['behavioral.AverageDistance2'],
        ];
        this.download = translations['Download'];

        this.tooltipContext = {
          UsersDistance: translations['behavioral.UsersDistanceTooltip'],
          AverageDistance: translations['behavioral.AverageDistanceTooltip'],
        };

        this.dateLabel = translations['export.Date'];
        this.dataNotFound = translations['dashboard.DataNotFound'];
      });

    try {
      this.timezone =
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Tokyo'
          ? 'jst'
          : 'utc';
    } catch (e) {
      const language =
        (window.navigator.languages && window.navigator.languages[0]) ||
        window.navigator.language;
      this.timezone = language.indexOf('ja') != -1 ? 'jst' : 'utc';
    }
  }

  ngOnDestroy() {
    this.onDestroy$.emit();
  }
  ngDoCheck(): void {
    this.setData();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fromDateStr'] || changes['toDateStr']) {
      this.refreshChart();
    }
  }

  refreshChart(): void {
    this.chartDistanceComponent.getAndDrawData(
      this.mapID,
      this.fromDateStr,
      this.toDateStr,
      this.timezone,
    );
  }

  setData(): void {
    // FIXME: 何度も呼ばれるので、値をチェックして制御したい
    // if (this.pageViews == undefined) return;
    // else if (this.setDataFlag) return;
    // console.log(this.pageViews != undefined, this.setDataFlag);
    // this.setDataFlag = true;

    this.usersDistance = Object.values(
      this.chartDistanceComponent.chartData[0].data,
    ).reduce((a: number, x: number) => (a += x), 0);
    const _averageDistance =
      Object.values(this.chartDistanceComponent.chartData[1].data).reduce(
        (a: number, x: number) => (a += x),
        0,
      ) / this.chartDistanceComponent.chartData[1].data.length;

    this.averageDistance =
      _averageDistance > 0 ? Math.round(_averageDistance * 100) / 100 : 0;
  }

  exportTable(fileType: 'csv' | 'xlsx'): void {
    const filename =
      'user-behavioral_' + this.fromDateStr + '-' + this.toDateStr;
    const exportData: string[][] = [];

    const labelRow = [this.dateLabel];
    for (let i = 0; i < this.chartDistanceComponent.chartData.length; i++) {
      labelRow.push(this.chartDistanceComponent.chartData[i].label);
    }
    exportData.push(labelRow);

    for (let i = 0; i < this.chartDistanceComponent.dateLabel.length; i++) {
      const values: string[] = [];
      values.push(this.chartDistanceComponent.dateLabel[i]);
      for (let j = 0; j < this.chartDistanceComponent.chartData.length; j++) {
        values.push(
          this.chartDistanceComponent.chartData[j].data[i].toString(),
        );
      }
      exportData.push(values);
    }

    switch (fileType) {
      case 'csv':
        this.csvService.exportChartData(exportData, filename);
        break;
      case 'xlsx':
        this.xlsxService.exportExcel(exportData, filename);
        break;
      default:
        throw Error('Error: 不正なパラメータです');
    }
  }
}
