// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.feature-item {
  margin-top: 55px;
}

h3 {
  margin-top: 10px;
  display: inline-block;
}

.download-box {
  float: right;
}

:host ::ng-deep .p-datatable .header {
  margin-left: 0;
}

:host ::ng-deep .p-datatable thead th:nth-of-type(1) {
  padding-left: 0;
  width: 70%;
}

:host ::ng-deep .p-datatable thead th:nth-of-type(n + 2) {
  width: 15%;
}

:host ::ng-deep .p-datatable tbody td:first-of-type {
  padding-left: 20px;
  text-align: left;
  line-height: 1em;
}

:host ::ng-deep .p-datatable td {
  height: 40px;
}

:host ::ng-deep .p-datatable.p-component {
  margin-top: 0;
}

:host ::ng-deep .p-datatable .p-datatable-header {
  padding-right: 0;
  background-color: #f0f2f8;
  border: none;
}

.not-found-box {
  padding: 15px 10px;
}

.not-found {
  margin-bottom: 50px;
  color: #666;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/pages/details/details-page/detailed/landmark-ranking/landmark-ranking.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,eAAe;AACjB","sourcesContent":[".feature-item {\n  margin-top: 55px;\n}\n\nh3 {\n  margin-top: 10px;\n  display: inline-block;\n}\n\n.download-box {\n  float: right;\n}\n\n:host ::ng-deep .p-datatable .header {\n  margin-left: 0;\n}\n\n:host ::ng-deep .p-datatable thead th:nth-of-type(1) {\n  padding-left: 0;\n  width: 70%;\n}\n\n:host ::ng-deep .p-datatable thead th:nth-of-type(n + 2) {\n  width: 15%;\n}\n\n:host ::ng-deep .p-datatable tbody td:first-of-type {\n  padding-left: 20px;\n  text-align: left;\n  line-height: 1em;\n}\n\n:host ::ng-deep .p-datatable td {\n  height: 40px;\n}\n\n:host ::ng-deep .p-datatable.p-component {\n  margin-top: 0;\n}\n\n:host ::ng-deep .p-datatable .p-datatable-header {\n  padding-right: 0;\n  background-color: #f0f2f8;\n  border: none;\n}\n\n.not-found-box {\n  padding: 15px 10px;\n}\n\n.not-found {\n  margin-bottom: 50px;\n  color: #666;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
