import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ChartUuComponent } from 'src/app/dashboard/ui/components/charts/chart-uu/chart-uu.component';
import { ChartLandmarkComponent } from 'src/app/dashboard/ui/components/charts/chart-landmark/chart-landmark.component';

import dayjs from 'dayjs';
import { FireStoreService } from 'src/app/dashboard/services/fire-store.service';
import { sumValues } from 'src/app/util';

interface WeeklyValue {
  count: number;
  fromPreviousDay: string;
}
const initWeeklyValue = (): WeeklyValue => {
  return {
    count: 0,
    fromPreviousDay: 'SAME',
  };
};
interface TooltipContext {
  PageViews: string;
  iframeViews: string;
  PageUsers: string;
  iframeUsers: string;
  LandmarkClick: string;
  LandmarkUsers: string;
}
const initTooltipContext = (): TooltipContext => {
  return {
    PageViews: '',
    iframeViews: '',
    PageUsers: '',
    iframeUsers: '',
    LandmarkClick: '',
    LandmarkUsers: '',
  };
};

@Component({
  selector: 'app-weekly',
  templateUrl: './weekly.component.html',
  styleUrls: [
    './weekly.component.css',
    '../../../../../../dashboard/common.css',
  ],
})
export class WeeklyComponent implements OnInit {
  @Input() mapID!: string;

  pageViews: WeeklyValue = initWeeklyValue();
  iframeViews: WeeklyValue = initWeeklyValue();
  pageUsers: WeeklyValue = initWeeklyValue();
  iframeUsers: WeeklyValue = initWeeklyValue();
  landmarkClick: WeeklyValue = initWeeklyValue();
  landmarkUsers: WeeklyValue = initWeeklyValue();

  // 翻訳済みテキスト
  tooltipContext: TooltipContext = initTooltipContext();
  title = '';
  titleSupplement = '';
  mapViewsLabel = '';
  pageViewsLabel = '';
  iframeViewsLabel = '';
  pageUsersLabel = '';
  iframeUsersLabel = '';
  landmarkViewsLabel = '';
  landmarkClickLabel: string[] = [];
  landmarkUsersLabel: string[] = [];

  @ViewChild(ChartUuComponent, { static: true })
  chartUuComponent!: ChartUuComponent;

  @ViewChild(ChartLandmarkComponent, { static: true })
  chartLandmarkComponent!: ChartLandmarkComponent;

  fromDateStr: string;
  toDateStr: string;
  setDataFlag = false;
  timezone: string;
  language =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language;

  constructor(
    private fsService: FireStoreService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    this.fromDateStr = dayjs().add(-7, 'day').format('YYYYMMDD');
    this.toDateStr = dayjs().add(-1, 'day').format('YYYYMMDD');

    this.translate
      .get([
        'weekly.PageViewsTooltip',
        'weekly.iframeViewsTooltip',
        'weekly.PageUsersTooltip',
        'weekly.iframeUsersTooltip',
        'weekly.LandmarkClickTooltip',
        'weekly.LandmarkUsersTooltip',
        'weekly.Title',
        'weekly.Span',
        'weekly.MapViews',
        'weekly.PageViews',
        'weekly.iframeViews',
        'weekly.PageUsers',
        'weekly.iframeUsers',
        'weekly.LandmarkViews',
        'weekly.LandmarkClick1',
        'weekly.LandmarkClick2',
        'weekly.LandmarkUsers1',
        'weekly.LandmarkUsers2',
      ])
      .subscribe((translations) => {
        this.tooltipContext = {
          PageViews: translations['weekly.PageViewsTooltip'],
          iframeViews: translations['weekly.iframeViewsTooltip'],
          PageUsers: translations['weekly.PageUsersTooltip'],
          iframeUsers: translations['weekly.iframeUsersTooltip'],
          LandmarkClick: translations['weekly.LandmarkClickTooltip'],
          LandmarkUsers: translations['weekly.LandmarkUsersTooltip'],
        };

        this.title = translations['weekly.Title'];
        this.titleSupplement = translations['weekly.Span'];
        this.mapViewsLabel = translations['weekly.MapViews'];
        this.pageViewsLabel = translations['weekly.PageViews'];
        this.iframeViewsLabel = translations['weekly.iframeViews'];
        this.pageUsersLabel = translations['weekly.PageUsers'];
        this.iframeUsersLabel = translations['weekly.iframeUsers'];
        this.landmarkViewsLabel = translations['weekly.LandmarkViews'];
        this.landmarkClickLabel = [
          translations['weekly.LandmarkClick1'],
          translations['weekly.LandmarkClick2'],
        ];
        this.landmarkUsersLabel = [
          translations['weekly.LandmarkUsers1'],
          translations['weekly.LandmarkUsers2'],
        ];
      });

    try {
      this.timezone =
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Tokyo'
          ? 'jst'
          : 'utc';
    } catch (e) {
      const language =
        (window.navigator.languages && window.navigator.languages[0]) ||
        window.navigator.language;
      this.timezone = language.indexOf('ja') != -1 ? 'jst' : 'utc';
    }
  }

  ngOnInit(): void {
    // for v1
    (async () => {
      await this.chartUuComponent.getAndDrawData(
        this.mapID,
        this.fromDateStr,
        this.toDateStr,
        this.timezone,
      );
      await this.chartLandmarkComponent.getAndDrawData(
        this.mapID,
        this.fromDateStr,
        this.toDateStr,
        this.timezone,
      );
      await this.makeCompareData();
    })();
  }

  ngDoCheck(): void {
    this.setData();
  }

  makeDiffMark(
    thisWeekData: Record<string, Record<string, number>>,
    oneWeekAgoData: Record<string, Record<string, number>>,
    dataType: string,
  ): string {
    const diff =
      sumValues(Object.values(thisWeekData[dataType])) -
      sumValues(Object.values(oneWeekAgoData[dataType]));
    if (diff > 0) {
      return 'UP';
    } else if (diff < 0) {
      return 'DOWN';
    } else {
      return '-';
    }
  }

  // V1 用
  async makeCompareData(): Promise<void> {
    const startDate = dayjs().add(-15, 'day').format('YYYYMMDD');
    const endDate = dayjs().add(-8, 'day').format('YYYYMMDD');

    if (
      this.chartUuComponent.graphData.pv &&
      Object.keys(this.chartUuComponent.graphData.pv).length > 0
    ) {
      const { allarea } = await this.fsService.getV1Data(
        'v1access',
        this.mapID,
        startDate,
        endDate,
        this.timezone,
        true,
      );

      const oneWeekAgoData = allarea;
      const thisWeekData = this.chartUuComponent.graphData;

      this.pageViews.fromPreviousDay = this.makeDiffMark(
        thisWeekData,
        oneWeekAgoData,
        'pv',
      );
      this.iframeViews.fromPreviousDay = this.makeDiffMark(
        thisWeekData,
        oneWeekAgoData,
        'pv_iframe',
      );
      this.pageUsers.fromPreviousDay = this.makeDiffMark(
        thisWeekData,
        oneWeekAgoData,
        'uu',
      );
      this.iframeUsers.fromPreviousDay = this.makeDiffMark(
        thisWeekData,
        oneWeekAgoData,
        'uu_iframe',
      );
    }

    if (
      this.chartLandmarkComponent.graphData.pv &&
      Object.keys(this.chartLandmarkComponent.graphData.pv).length > 0
    ) {
      const oneWeekAgoData = (
        await this.fsService.getV1Data(
          'v1landmark',
          this.mapID,
          startDate,
          endDate,
          this.timezone,
          true,
        )
      ).allarea;
      const thisWeekData = this.chartLandmarkComponent.graphData;

      this.landmarkClick.fromPreviousDay = this.makeDiffMark(
        thisWeekData,
        oneWeekAgoData,
        'pv',
      );
      this.landmarkUsers.fromPreviousDay = this.makeDiffMark(
        thisWeekData,
        oneWeekAgoData,
        'uu',
      );
    }
  }

  // V0 用 (廃止予定)
  /*
  makeCompareData(): void {
    const itemUu = this.fsService.makeDocumentStream('uu_data', this.mapID);
    const startDate = dayjs()
      .add(-15, 'day')
      .format('YYYYMMDD');
    const endDate = dayjs()
      .add(-8, 'day')
      .format('YYYYMMDD');
    itemUu.subscribe(docData => {
      const filteredData = this.fsService.filterData(
        docData,
        startDate,
        endDate,
      );
      const oneWeekAgoData = this.fsService.reshapeDataPropDateSum(
        filteredData,
      );
      const thisWeekData = this.chartUuComponent.graphData;

      this.pageViews.fromPreviousDay = this.makeDiffMark(
        thisWeekData,
        oneWeekAgoData,
        'pv',
      );
      this.iframeViews.fromPreviousDay = this.makeDiffMark(
        thisWeekData,
        oneWeekAgoData,
        'pv_iframe',
      );
      this.pageUsers.fromPreviousDay = this.makeDiffMark(
        thisWeekData,
        oneWeekAgoData,
        'uu',
      );
      this.iframeUsers.fromPreviousDay = this.makeDiffMark(
        thisWeekData,
        oneWeekAgoData,
        'uu_iframe',
      );
    });
    const itemLandmark = this.fsService.makeDocumentStream(
      'landmark',
      this.mapID,
    );
    itemLandmark.subscribe(docData => {
      const filteredData = this.fsService.filterData(
        docData,
        startDate,
        endDate,
      );
      const oneWeekAgoData = this.fsService.reshapeDataPropDateSum(
        filteredData,
      );
      const thisWeekData = this.chartLandmarkComponent.graphData;

      this.landmarkClick.fromPreviousDay = this.makeDiffMark(
        thisWeekData,
        oneWeekAgoData,
        'pv',
      );
      this.landmarkUsers.fromPreviousDay = this.makeDiffMark(
        thisWeekData,
        oneWeekAgoData,
        'uu',
      );
    });
  }
*/

  setData(): void {
    // FIXME: 何度も呼ばれるので、値をチェックして制御したい
    // if (this.pageViews == undefined) return;
    // else if (this.setDataFlag) return;
    // console.log(this.pageViews != undefined, this.setDataFlag);
    // this.setDataFlag = true;

    this.pageViews.count = this.fsService.sumValues(
      this.chartUuComponent.chartData[0].data,
    );
    this.iframeViews.count = this.fsService.sumValues(
      this.chartUuComponent.chartData[1].data,
    );
    this.pageUsers.count = this.fsService.sumValues(
      this.chartUuComponent.chartData[2].data,
    );
    this.iframeUsers.count = this.fsService.sumValues(
      this.chartUuComponent.chartData[3].data,
    );
    this.landmarkClick.count = this.fsService.sumValues(
      this.chartLandmarkComponent.chartData[0].data as number[],
    );
    this.landmarkUsers.count = this.fsService.sumValues(
      this.chartLandmarkComponent.chartData[1].data as number[],
    );
  }
}
