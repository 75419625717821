import dayjs, { Dayjs } from 'dayjs';

export class DateUtil {
  static dateRange = (
    fromDate: dayjs.ConfigType,
    toDate: dayjs.ConfigType,
  ): Dayjs[] => {
    const startDate = dayjs(fromDate),
      endDate = dayjs(toDate);
    const dates: Dayjs[] = [];
    for (let date = startDate; date <= endDate; date = date.add(1, 'day')) {
      dates.push(date);
    }
    return dates;
  };

  static dateRangeWithFormat = (
    fromDate: dayjs.ConfigType,
    toDate: dayjs.ConfigType,
  ): string[] => {
    return this.dateRange(fromDate, toDate).map((d) => d.format('YYYY-MM-DD'));
  };
}
