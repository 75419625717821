import { inject, Injectable } from '@angular/core';

import { Overlay, OverlayRef, OverlayContainer } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LoadingComponent } from '../ui/components/loading/loading.component';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private overlayRef: OverlayRef;

  constructor() {
    const overlay = inject(Overlay);

    const positionStrategy = overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    this.overlayRef = overlay.create({
      positionStrategy,
      hasBackdrop: true,
    });

    inject(OverlayContainer).getContainerElement().style.zIndex = '1400';
  }

  start() {
    this.overlayRef.attach(new ComponentPortal(LoadingComponent));
  }

  end() {
    this.overlayRef.detach();
  }
}
