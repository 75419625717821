import { Injectable, inject } from '@angular/core';
import { MapRepository } from 'src/app/dashboard/repositories/map.repository';
import { MapDetailDto } from '../repositories/dto/map-detail.dto';
import { LangService } from 'src/app/dashboard/services/lang.service';
import { environment } from 'src/environments/environment';
import { LoginUserDevice } from 'src/app/shared/utils/login-user-device';
import { MapOptionsRepository } from '../repositories/map-options.repository';
import { MapOptionsDto } from '../repositories/dto/map-options.dto';

// FIXME: 適切な名前にする.
// detailsページで表示する地図情報.
export interface MapDetailSummaryDto {
  title: string;
  description: string;
  creator: string;
  copyright: string;
  targetArea: string;
  url: string;
  isPublished: boolean;
  thumbnailUrl: string;
  mapOptionHeatmapEnable: boolean;
  isFoundMap: boolean;
}

@Injectable({ providedIn: 'root' })
export class MapUsecase {
  private readonly mapDateRepository = inject(MapRepository);
  private readonly mapOptionsRepository = inject(MapOptionsRepository);
  private readonly langService = inject(LangService);
  private readonly loginUserDevice = inject(LoginUserDevice);

  /**
   * 地図の詳細情報の概要を取得する.
   */
  getMapDetailSummary = async (mapID: string): Promise<MapDetailSummaryDto> => {
    const mapDetail = await this.mapDateRepository.fetch(mapID).then(
      (res) => res,
      () => undefined,
    );
    return this.toMapDetailSummary(mapID, mapDetail);
  };

  private toMapDetailSummary = (
    mapID: string,
    mapDetail: MapDetailDto | undefined,
  ): MapDetailSummaryDto => {
    const mapLang = this.getMapLang(mapID, mapDetail);

    const mapDetailSummary: MapDetailSummaryDto = {
      title: mapLang?.title ?? '',
      description: mapLang?.dc_description ?? '',
      creator: mapLang?.dc_creator ?? '',
      copyright: mapLang?.dc_rights ?? '',
      targetArea: mapDetail !== undefined ? mapDetail.map_image.place : '',
      url: `${environment.strolyURL}/viewer/${mapID}`,
      isPublished: mapDetail !== undefined ? mapDetail.is_published : false,
      // ローカル環境などで本番のデータを利用するため直にドメイン指定している.
      thumbnailUrl: `https://stroly.com/media/thumbnails/${mapID}_512.jpg`,
      mapOptionHeatmapEnable:
        mapDetail !== undefined
          ? mapDetail.map_options.options['enable_heatmap_in_data_report']
          : false,
      isFoundMap: mapDetail !== undefined,
    };
    return mapDetailSummary;
  };

  /**
   * マップの設定を取得する
   */
  getMapOptions = async (mapID: string): Promise<MapOptionsDto | undefined> => {
    return await this.mapOptionsRepository.fetch(mapID).then(
      (res) => res,
      () => undefined,
    );
  };

  /** ユーザの言語に合わせた地図の説明. */
  private getMapLang = (
    mapID: string,
    mapDetail: MapDetailDto | undefined,
  ): MapDetailDto['map_image']['langs'][number] | undefined => {
    const mapLangs =
      mapDetail !== undefined
        ? mapDetail.map_image.langs
        : this.notFoundMapLangs(mapID);
    return this.langService.getMapLangs(
      mapLangs,
      this.loginUserDevice.browserLang,
    );
  };

  /** 非公開や削除された地図を見るときの地図の説明. */
  private notFoundMapLangs = (
    mapID: string,
  ): MapDetailDto['map_image']['langs'] => {
    return [
      {
        title: `Map ID: "${mapID}"`,
        lang: 'en',
        dc_description: 'This map might have been deleted or unpublished.',
      },
      {
        title: `地図ID: "${mapID}"`,
        lang: 'ja',
        dc_description:
          'この地図は非公開化、または、削除されました。過去のアクセス統計についてのみ閲覧可能です。',
      },
    ].map((v) => {
      return {
        dc_contributor: '',
        dc_created: '',
        dc_creator: '',
        dc_rights: '',
        dc_title: '',
        ...v,
      };
    });
  };
}
