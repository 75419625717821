import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-irregular-data',
  templateUrl: './irregular-data.component.html',
  styleUrls: ['./irregular-data.component.css'],
})
export class IrregularDataComponent implements OnInit {
  message: string[] = [];

  constructor(private translate: TranslateService) {
    this.translate
      .get([
        'irregularData.Message1',
        'irregularData.Message2',
        'irregularData.Message3',
      ])
      .subscribe((translations) => {
        this.message.push(translations['irregularData.Message1']);
        this.message.push(translations['irregularData.Message2']);
        this.message.push(translations['irregularData.Message3']);
      });
  }

  ngOnInit(): void {}
}
