export const chunk = <T>(arr: T[], chunkSize: number): T[][] => {
  if (chunkSize <= 0) {
    throw new Error('chunkSize must be greater than 0');
  }
  return arr.reduce(
    (acc, _, i) =>
      i % chunkSize ? acc : [...acc, arr.slice(i, i + chunkSize)],
    [] as T[][],
  );
};
