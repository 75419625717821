import { FireStoreService } from '../../../../../../services/fire-store.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CsvService } from 'src/app/dashboard/services/csv.service';
import { XlsxService } from '../../../../../../services/xlsx.service';
import { getStrLength } from 'src/app/util';

interface ColumnData {
  field: string;
  header: string;
}

interface StringOrNumber {
  [k: string]: string | number;
}
interface RowData extends StringOrNumber {
  title: string;
  rank: number;
  click: number;
}

@Component({
  selector: 'app-landmark-ranking',
  templateUrl: './landmark-ranking.component.html',
  styleUrls: [
    '../../../../../../../dashboard/common.css',
    './landmark-ranking.component.css',
  ],
})
export class LandmarkRankingComponent implements OnInit {
  aggData: Record<string, number> = {};
  aggDataKeys: string[] = [];
  graphData: Record<string, Record<string, number>> = {};
  aggDataInmap: Record<string, number> = {};
  aggDataKeysInmap: string[] = [];

  aggMaxPaging = 5;
  inmapMaxPaging = 5;

  cols: ColumnData[] = [];
  aggDisplayData: RowData[] = [];
  inmapDisplayData: RowData[] = [];

  openFormatList_all = false;
  openFormatList_inmap = false;

  downloadFileName_all = '';
  downloadFileName_inmap = '';

  dataTitleMaxLength = 40;

  // 翻訳済みテキスト
  download = '';
  dataNotFound = '';
  allLandmarkClick = '';
  inmapLandmarkClick = '';

  constructor(
    private fsService: FireStoreService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private csvService: CsvService,
    private xlsxService: XlsxService,
  ) {
    this.translate
      .get([
        'landmarkRanking.LandmarkTitle',
        'landmarkRanking.Rank',
        'landmarkRanking.Click',
        'Download',
        'dashboard.DataNotFound',
        'landmarkRanking.AllLandmarkClick',
        'landmarkRanking.InmapLandmarkClick',
      ])
      .subscribe((translations) => {
        this.cols = [
          {
            field: 'title',
            header: translations['landmarkRanking.LandmarkTitle'],
          },
          { field: 'rank', header: translations['landmarkRanking.Rank'] },
          { field: 'click', header: translations['landmarkRanking.Click'] },
        ];

        this.download = translations['Download'];
        this.dataNotFound = translations['dashboard.DataNotFound'];
        this.allLandmarkClick =
          translations['landmarkRanking.AllLandmarkClick'];
        this.inmapLandmarkClick =
          translations['landmarkRanking.InmapLandmarkClick'];
      });
  }

  ngOnInit(): void {}

  // Ver.1 用
  async getData(
    mapID: string,
    fromDateStr: string,
    toDateStr: string,
    timezone: string,
  ): Promise<void> {
    const res = await this.fsService.getV1Data(
      'v1landmark',
      mapID,
      fromDateStr,
      toDateStr,
      timezone,
    );
    this.aggData = res.allarea.pv;
    const newObj: Record<string, number> = {};
    Object.getOwnPropertyNames(this.aggData).map(
      (key) => (newObj[key] = this.aggData[key]),
    );
    const sortedData = this.fsService.sortByValue(newObj);
    this.aggDisplayData = [];
    Array.from(sortedData.keys()).map((key, idx) => {
      this.aggDisplayData.push({
        title: key,
        rank: idx + 1,
        click: this.aggData[key],
      });
    });

    this.aggDataKeys = Array.from(sortedData.keys());
    this.downloadFileName_all =
      'all-landmark-clicks' + fromDateStr + '-' + toDateStr;

    this.aggDataInmap = res.inmap.pv;

    const newObjInamp: Record<string, number> = {};
    Object.getOwnPropertyNames(this.aggDataInmap).map(
      (key) => (newObjInamp[key] = this.aggDataInmap[key]),
    );
    const sortedDataInmap = this.fsService.sortByValue(newObjInamp);
    this.inmapDisplayData = [];
    Array.from(sortedDataInmap.keys()).map((key, idx) => {
      this.inmapDisplayData.push({
        title: key,
        rank: idx + 1,
        click: this.aggDataInmap[key],
      });
    });

    this.aggDataKeysInmap = Array.from(sortedDataInmap.keys());
    this.downloadFileName_inmap =
      'landmark-clicks-within-map' + fromDateStr + '-' + toDateStr;

    this.cd.markForCheck(); // marks path
  }

  // Ver.0 用 (廃止予定)
  /*
  getData(
    mapID: string,
    fromDateStr: string,
    toDateStr: string,
    timezone: string,
  ): void {
    // timezone追加版
    // this.item = this.fsService.makeDocumentStream(
    //   "landmark_" + timezone,
    //   mapID
    // );
    this.item = this.fsService.makeDocumentStream('landmark', mapID);

    this.item.subscribe(docData => {
      this.data = docData;
      const filteredData = this.fsService.filterData(
        docData,
        fromDateStr,
        toDateStr,
      );

      const newFilteredData = {};
      Object.keys(filteredData).map(val => {
        newFilteredData[val] = filteredData[val]['pv'];
      });
      this.aggData = this.fsService.aggregateFSData(newFilteredData);

      const newObj = {};
      Object.getOwnPropertyNames(this.aggData).map(
        key => (newObj[key] = this.aggData[key]),
      );
      const sortedData = this.fsService.sortByValue(newObj);
      this.aggDisplayData = [];
      Array.from(sortedData.keys()).map((key, idx) => {
        this.aggDisplayData.push({
          title: key,
          rank: idx + 1,
          click: this.aggData[key],
        });
      });

      this.aggDataKeys = Array.from(sortedData.keys());
      this.downloadFileName_all =
        'all-landmark-clicks' + fromDateStr + '-' + toDateStr;
      this.cd.markForCheck(); // marks path
    });

    // inamp
    // timezone追加版
    // this.itemInmap = this.fsService.makeDocumentStream(
    //   "landmark_inmap_" + timezone,
    //   mapID
    // );
    this.itemInmap = this.fsService.makeDocumentStream('landmark_inmap', mapID);

    this.itemInmap.subscribe(docData => {
      this.dataInmap = docData;
      const filteredDataIn = this.fsService.filterData(
        docData,
        fromDateStr,
        toDateStr,
      );
      const newFilteredDataIn = {};
      Object.keys(filteredDataIn).map(val => {
        newFilteredDataIn[val] = filteredDataIn[val]['pv'];
      });
      this.aggDataInmap = this.fsService.aggregateFSData(newFilteredDataIn);

      const newObjInamp = {};
      Object.getOwnPropertyNames(this.aggDataInmap).map(
        key => (newObjInamp[key] = this.aggDataInmap[key]),
      );
      const sortedDataInmap = this.fsService.sortByValue(newObjInamp);
      this.inmapDisplayData = [];
      Array.from(sortedDataInmap.keys()).map((key, idx) => {
        this.inmapDisplayData.push({
          title: key,
          rank: idx + 1,
          click: this.aggDataInmap[key],
        });
      });

      this.aggDataKeysInmap = Array.from(sortedDataInmap.keys());
      this.downloadFileName_inmap =
        'landmark-clicks-within-map' + fromDateStr + '-' + toDateStr;
      this.cd.markForCheck(); // marks path
    });
  }
*/

  exportTable(category: 'all' | 'inmap', fileType: 'csv' | 'xlsx'): void {
    const filename =
      category == 'all'
        ? this.downloadFileName_all
        : this.downloadFileName_inmap;
    const data =
      category == 'all' ? this.aggDisplayData : this.inmapDisplayData;

    const exportData: string[][] = [];
    const labelRow: string[] = [];
    for (let i = 0; i < this.cols.length; i++) {
      labelRow.push(this.cols[i].header);
    }
    exportData.push(labelRow);

    for (let i = 0; i < data.length; i++) {
      const values: string[] = [];
      Object.values(data[i]).map((value) => {
        values.push(value.toString());
      });
      exportData.push(values);
    }

    switch (fileType) {
      case 'csv':
        this.csvService.exportChartData(exportData, filename);
        break;
      case 'xlsx':
        this.xlsxService.exportExcel(exportData, filename);
        break;
      default:
        throw Error('Error: 不正なパラメータです');
    }
  }

  getLength(str: string): number {
    return getStrLength(str);
  }
}
