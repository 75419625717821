import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';
import {
  ADJUSTED_HEATMAP_RADIUS,
  DEFAULT_HEATMAP_RADIUS,
} from 'src/app/dashboard/ui/pages/details/details-page/behavioral/constants';

@Component({
  selector: 'app-input-range',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './input-range.component.html',
  styleUrls: ['./input-range.component.scss'],
})
export class InputRangeComponent implements OnChanges {
  formControl = new FormControl(0, { nonNullable: true });

  @Input()
  initValue = 0;
  @Input()
  min = 0;
  @Input()
  max = 100;

  @Output()
  onChange = new EventEmitter<number>();

  protected _onChange(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      this.onChange.emit(Number(event.target.value));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentInitValue = changes['initValue'].currentValue;
    if (
      currentInitValue === DEFAULT_HEATMAP_RADIUS ||
      currentInitValue === ADJUSTED_HEATMAP_RADIUS
    ) {
      this.registerUpdatingStyleHandler();
    }
  }

  // ngOnInit時にelementにアクセスできるようにstatic: trueにしている.
  @ViewChild('ref', { static: true })
  inputElem!: ElementRef<HTMLInputElement>;

  private calcPercent(value: number): number {
    const size = this.max - this.min;
    const position = value - this.min;
    return (position / size) * 100;
  }

  // ピッカーがwebkit系で埋もれてしまうのを改善
  // NOTE: angular的な書き方`(input)="xxx"`のようにしてstyleを更新しようとすると画面が重くなるので, 以下のようにしている.
  private registerUpdatingStyleHandler(): void {
    const elem = this.inputElem.nativeElement;
    // 初期化時のスタイルを設定
    const percent = this.calcPercent(this.initValue);
    elem.style.background = this.backgroundStyle(percent);

    // inputイベントでスタイルを更新
    elem.oninput = () => {
      const el = this.inputElem.nativeElement;
      const percent = this.calcPercent(Number(el.value));
      el.style.background = this.backgroundStyle(percent);
    };
  }

  private backgroundStyle(percent: number): string {
    return `linear-gradient(to right, #7a7acc 0%, #7a7acc ${percent}%, #ccc ${percent}%, #ccc 100%)`;
  }
}
