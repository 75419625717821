import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-no-map',
  templateUrl: './no-map.component.html',
  styleUrls: ['./no-map.component.css'],
})
export class NoMapComponent implements OnInit {
  @Input() reason!: string;
  icon = '';
  title = '';
  caption: string[] = [];
  makeMap = '';

  strolyURL = environment.strolyURL;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.icon = this.reason == 'not found' ? 'search' : 'map';

    this.translate
      .get([
        'noMap.NotFound',
        'noMap.NotFoundCaption',
        'noMap.Nothing',
        'noMap.NothingCaption1',
        'noMap.NothingCaption2',
        'noMap.MakeMap',
      ])
      .subscribe((translations) => {
        if (this.reason == 'not found') {
          this.title = translations['noMap.NotFound'];
          this.caption = [translations['noMap.NotFoundCaption'], ''];
        } else if (this.reason == 'nothing') {
          this.title = translations['noMap.Nothing'];
          this.caption = [
            translations['noMap.NothingCaption1'],
            translations['noMap.NothingCaption2'],
          ];
          this.makeMap = translations['noMap.MakeMap'];
        }
      });
  }
}
