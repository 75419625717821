import { L } from 'stroly-js';
import 'leaflet.heat';

export interface LatLngHeatmapData {
  heatmapRadius: number;
  locationsLatlng: [number, number][];
}

export class LatLngHeatLayer {
  private innerHasHeatmapData = false;
  get hasHeatmapData() {
    return this.innerHasHeatmapData;
  }

  private innerHeatLayer!: L.HeatLayer;
  get layer() {
    return this.innerHeatLayer;
  }

  refreshHeatLayer(data: LatLngHeatmapData) {
    const locationsLatlng = data.locationsLatlng;
    const heatmapRadius = data.heatmapRadius;

    // ヒートマップデータ (緯度, 経度, 強度)
    const heatData: L.HeatLatLngTuple[] = locationsLatlng.map((location) => {
      if (location.length === 2) {
        return [...location, 1.0]; // 強度を追加
      }
      return [1, 1, 1];
    });

    if (this.innerHeatLayer) {
      this.innerHeatLayer.setLatLngs(heatData);
      this.innerHeatLayer.setOptions({
        radius: heatmapRadius,
        blur: 15,
      });
    } else {
      this.innerHeatLayer = L.heatLayer(heatData, {
        radius: heatmapRadius,
        blur: 15,
      });
    }

    this.innerHasHeatmapData = true;
  }
}
