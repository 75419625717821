// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend {
  margin-top: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
}

.legend span {
  display: inline-block;
  width: 130px;
  text-align: left;
}

.legend i {
  margin-right: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/components/charts/chart-os/chart-os.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".legend {\n  margin-top: 20px;\n  font-size: 12px;\n  font-weight: 400;\n  color: #333;\n}\n\n.legend span {\n  display: inline-block;\n  width: 130px;\n  text-align: left;\n}\n\n.legend i {\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
