import { Injectable } from '@angular/core';
import * as Xlsx from 'xlsx';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class XlsxService {
  // 参考 https://github.com/primefaces/primeng/blob/master/src/app/showcase/components/table/tableexportdemo.ts
  constructor() {}

  exportExcel(data: string[][], fileName: string): void {
    const worksheet = Xlsx.utils.aoa_to_sheet(data);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer: ArrayBuffer = Xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.save(excelBuffer, fileName);
  }

  save(buffer: ArrayBuffer, fileName: string): void {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
}
