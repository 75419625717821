// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-area {
  width: 575px;
  text-align: right;
}

:host ::ng-deep .p-datatable .header,
:host ::ng-deep .p-datatable .header,
:host ::ng-deep .p-datatable .header,
:host ::ng-deep .p-datatable .header {
  line-height: 0.8;
}

:host ::ng-deep .p-datatable tbody tr td:first-of-type {
  height: 54px;
  word-break: break-all;
}

:host ::ng-deep .p-datatable td:first-of-type {
  text-align: left;
}

:host ::ng-deep .p-datatable td:nth-of-type(n + 2) {
  width: 103px;
}

.user-data-table th:nth-of-type(n + 2),
.user-data-table td:nth-of-type(n + 2) {
  max-width: 9em;
  white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/pages/details/details-page/user/user-data-table/user-data-table.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;;;;EAIE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,cAAc;EACd,qBAAqB;AACvB","sourcesContent":[".data-area {\n  width: 575px;\n  text-align: right;\n}\n\n:host ::ng-deep .p-datatable .header,\n:host ::ng-deep .p-datatable .header,\n:host ::ng-deep .p-datatable .header,\n:host ::ng-deep .p-datatable .header {\n  line-height: 0.8;\n}\n\n:host ::ng-deep .p-datatable tbody tr td:first-of-type {\n  height: 54px;\n  word-break: break-all;\n}\n\n:host ::ng-deep .p-datatable td:first-of-type {\n  text-align: left;\n}\n\n:host ::ng-deep .p-datatable td:nth-of-type(n + 2) {\n  width: 103px;\n}\n\n.user-data-table th:nth-of-type(n + 2),\n.user-data-table td:nth-of-type(n + 2) {\n  max-width: 9em;\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
