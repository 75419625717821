import { L } from 'stroly-js';
import {
  LandmarkIcon,
  LatLng,
} from '../../ui/pages/details/details-page/behavioral/models';
import {
  ILLUSTMAP_ROOT_URL,
  LANDMARK_ICON,
} from '../../ui/pages/details/details-page/behavioral/constants';

export class LandmarkMarker {
  readonly marker: L.Marker;

  constructor(
    readonly latlng: LatLng,
    readonly icon: LandmarkIcon,
    readonly spotName: string | undefined,
    readonly enableDisplaySpotName: boolean,
  ) {
    this.marker = new L.Marker([latlng.lat, latlng.lng], {
      icon: LandmarkMarker.createIcon({
        icon: icon?.iconStyle?.icon,
        spotName,
        enableDisplaySpotName,
        isHighDefinition: icon?.iconStyle?.isHighDefinition,
      }),
      draggable: false,
      riseOnHover: true,
      riseOffset: 2000,
      zIndexOffset: 1000,
      bubblingMouseEvents: false,
    });
  }

  static createIcon(
    {
      icon,
      isSelected,
      spotName,
      enableDisplaySpotName,
      isHighDefinition,
      animate,
      fadeIn,
    }: {
      icon: LandmarkIcon['iconStyle']['icon'] | undefined;
      isSelected?: boolean;
      spotName: string | undefined;
      enableDisplaySpotName: boolean;
      isHighDefinition?: boolean;
      animate?: boolean;
      fadeIn?: boolean;
    } = {
      icon: undefined,
      isSelected: false,
      spotName: undefined,
      enableDisplaySpotName: false,
      animate: false,
      fadeIn: false,
    },
  ) {
    const MarkerWidth = 160;
    const iconUrl = icon
      ? `${ILLUSTMAP_ROOT_URL}${icon.href.replace(/\.\//gi, '/')}`
      : isSelected
      ? LANDMARK_ICON.icon.href
      : LANDMARK_ICON.selectedIcon.href;

    const defaultIconSize = icon
      ? { width: icon.width, height: icon.height }
      : { width: 50 / 2, height: 60 / 2 };

    const iconSize = isHighDefinition
      ? { width: defaultIconSize.width / 2, height: defaultIconSize.height / 2 }
      : defaultIconSize;

    const defaultIconAnchor: [number, number] = icon
      ? [icon.hotSpotPxX, icon.hotSpotPxY]
      : [50 / 4, 60 / 2];

    const iconAnchor = L.point(
      isHighDefinition
        ? [defaultIconAnchor[0] / 2, defaultIconAnchor[1] / 2]
        : defaultIconAnchor,
    );

    const style = {
      marker: `
        position: relative;
        display: flex;
        place-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: ${iconSize.width}px;
        height: ${iconSize.height}px;
      `,
      nameWrapper: `
        position: absolute;
        bottom: ${iconSize.height}px;
        width: ${MarkerWidth}px;
      `,
      name: `
        filter: drop-shadow(0 0 2px #929292);
        max-width: ${MarkerWidth}px;
        text-align: left;
        font-weight: bold;
        color: #424242;
        text-shadow:
          1px 1px 0 #fff, -1px -1px 0 #fff,
          -1px 1px 0 #fff, 1px -1px 0 #fff,
          0px 1px 0 #fff,  0-1px 0 #fff,
          -1px 0 0 #fff, 1px 0 0 #fff;
        display: inline-block;
        word-wrap: break-word;
        hyphens: auto;
        white-space: break-spaces;
        font-size: 14px;
      `,
    };

    const name =
      spotName && enableDisplaySpotName
        ? `
      <p style="${style.nameWrapper}">
        <strong style="${style.name}">${spotName}</strong>
      </p>
    `
        : '';

    const imageClass = fadeIn ? 'fade-in' : '';

    return L.divIcon({
      className: animate ? 'landmark-icon-selected' : 'landmark-icon',
      iconAnchor,
      html: `
        <div style="${style.marker}">
          ${name}
          <img
            class="${imageClass}"
            src="${iconUrl}"
            width="${iconSize.width}"
            height="${iconSize.height}"
            alt="${spotName}"
            loading="lazy"
          />
        </div>
        `,
    });
  }
}
