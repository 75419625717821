import { Component, ElementRef, EventEmitter, Output } from '@angular/core';
import type { OnInit } from '@angular/core';

@Component({
  selector: 'app-map-ref',
  template: `<div style="height: 100%; width: 100%;"></div>`,
})
export class MapRefComponent implements OnInit {
  @Output() createMap = new EventEmitter<HTMLElement>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    // 地図の描画とDOMの描画のタイミングによって、
    // 地図の表示がおかしくなることがあるので、
    // setTimeoutをかまして、別タスクにしている
    setTimeout(() => {
      const element = this.elementRef.nativeElement;
      this.createMap.emit(element.childNodes[0]);
    });
  }
}
