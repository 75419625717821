import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { MapDetailDto } from './dto/map-detail.dto';
import { MapListWithTagNameDto } from './dto/map-list-with-tag-name.dto';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class MapRepository {
  private readonly http = inject(HttpClient);

  fetch = (mapID: string): Promise<MapDetailDto> => {
    // NOTE: 環境によらず固定で本番環境を利用している.
    const apiUrl = `https://stroly.com/api/v1/r/mapbykey/${mapID}`;
    return firstValueFrom(this.http.get<MapDetailDto>(apiUrl));
  };

  /**
   * 自分が作ったマップと自分が所属しているボードにあるマップの一覧を取得する.
   */
  fetchMyMapsWithTagName = (): Promise<MapListWithTagNameDto> => {
    // NOTE: クエリパラメータが共通なので固定にしています.
    const apiUrl = `${environment.authApiHost}/api/v1/l/mymaps-including-joined-board/?page_size=100&sort=latest`;
    return firstValueFrom(
      this.http.get<MapListWithTagNameDto>(apiUrl, { withCredentials: true }),
    );
  };
}
