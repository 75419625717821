import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { FireStoreService } from '../../../../services/fire-store.service';
import { TranslateService } from '@ngx-translate/core';
import { formatDateLabel } from '../../../../../util';

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chart-landmark',
  templateUrl: './chart-landmark.component.html',
  styleUrls: [
    './chart-landmark.component.css',
    '../../../../../dashboard/common.css',
  ],
})
export class ChartLandmarkComponent implements OnInit {
  chartTitle = 'スポットクリック数';
  chartType: ChartType = 'line';
  chartOptions: ChartOptions = {
    animation: {
      delay: 0,
    },
    elements: {
      point: {
        radius: 4,
      },
    },
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          autoSkipPadding: 10,
          color: '#989eb3',
        },
        grid: {
          display: false,
        },
      },

      y: {
        beginAtZero: true,
        ticks: {
          color: '#989eb3',
          maxTicksLimit: 4,
        },
        grid: {
          color: '#d8dde5',
          drawBorder: false,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        position: 'average',
        backgroundColor: '#6666cc',
        borderColor: '#fff',
        borderWidth: 1,
        titleFont: {
          style: 'normal',
        },
        bodySpacing: 6,
      },
    },
  };
  chartLabels = [''];

  chartData = [
    {
      data: [0],
      label: 'clicks',
      lineTension: 0,
      fill: false,
      borderWidth: 4,
      pointBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 4,
      borderColor: '#efe08f',
      pointBorderColor: '#efe08f',
      pointBackgroundColor: '#efe08f',
      backgroundColor: '#efe08f',
      pointHoverBorderColor: '#efe08f',
      pointHoverBackgroundColor: '#efe08f',
    },
    {
      data: [0],
      label: 'users',
      type: 'bar',
      borderWidth: 1,
      borderColor: '#ffffff',
      backgroundColor: '#b4addf',
      hoverBackgroundColor: '#7F7BAF',
      hoverBorderColor: '#fff',
      barPercentage: 1,
      barThickness: 20,
    },
  ];
  datasets = this.chartData as ChartDataset<ChartType>[];

  graphData: Record<string, Record<string, number>> = {};
  dateLabel: string[] = [];
  irregularData = false;

  get visibility(): { [key: string]: string } {
    return {
      visibility: this.irregularData ? 'hidden' : 'visible',
    };
  }

  constructor(
    private fsService: FireStoreService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    this.translate
      .get([
        'weekly.LandmarkClick1',
        'weekly.LandmarkClick2',
        'weekly.LandmarkUsers1',
        'weekly.LandmarkUsers2',
      ])
      .subscribe((translations) => {
        this.chartData[0].label =
          translations['weekly.LandmarkClick1'] +
          translations['weekly.LandmarkClick2'];
        this.chartData[1].label =
          translations['weekly.LandmarkUsers1'] +
          translations['weekly.LandmarkUsers2'];
      });
  }

  ngOnInit(): void {}

  isNumberStr(str: string): boolean {
    return Number.isFinite(Number(str));
  }

  getDateRange(fromDate: string, toDate: string): number {
    const dt1 = new Date(this.fsService.getWithHyphenDate(fromDate));
    const dt2 = new Date(this.fsService.getWithHyphenDate(toDate));
    return (dt2.getTime() - dt1.getTime()) / 1000 / 60 / 60 / 24 + 1;
  }

  drawData(): void {
    const dataWithDateLabel = this.fsService.makeDataWithDateLabel(
      this.dateLabel,
      this.graphData['pv'],
    );
    const dataWithDateLabelInmap = this.fsService.makeDataWithDateLabel(
      this.dateLabel,
      this.graphData['uu'],
    );
    this.chartData[0].data = dataWithDateLabel;
    this.chartData[1].data = dataWithDateLabelInmap;
    this.chartLabels = formatDateLabel(this.dateLabel);

    this.cd.markForCheck(); // marks path
  }

  // Ver.0 用 (廃止予定)
  /*
  getAndDrawData(
    mapID: string,
    fromDateStr: string,
    toDateStr: string,
    timezone: string,
  ): void {
    this.dateLabel = this.fsService.makeDateLabel(fromDateStr, toDateStr);

    // timezone追加版
    // this.item = this.fsService.makeDocumentStream(
    //   "landmark_" + timezone,
    //   mapID
    // );
    this.item = this.fsService.makeDocumentStream('landmark', mapID);

    this.item.subscribe(docData => {
      this.data = docData;
      const filteredData = this.fsService.filterDataLandmark(
        docData,
        fromDateStr,
        toDateStr,
      );
      this.graphData = this.fsService.reshapeDataPropDateSum(filteredData);

      // this.checkData();
      this.drawData();
      this.allData = this.fsService.dataToCount(filteredData);
      this.cd.markForCheck(); // marks path
    });

    // inamp
    // timezone追加版
    // this.itemInmap = this.fsService.makeDocumentStream(
    //   "landmark_inmap_" + timezone,
    //   mapID
    // );
    this.itemInmap = this.fsService.makeDocumentStream('landmark_inmap', mapID);

    this.itemInmap.subscribe(docData => {
      this.dataInmap = docData;
      const filteredDataIn = this.fsService.filterDataLandmark(
        docData,
        fromDateStr,
        toDateStr,
      );
      this.inmapData = this.fsService.dataToCount(filteredDataIn);
      this.checkData();
      this.drawData();

      this.cd.markForCheck(); // marks path
    });
  }
*/

  // Ver.1 用
  async getAndDrawData(
    mapID: string,
    fromDate: string,
    toDate: string,
    timezone: string,
  ) {
    const { allarea } = await this.fsService.getV1Data(
      'v1landmark',
      mapID,
      fromDate,
      toDate,
      timezone,
      true,
    );
    this.graphData = allarea;
    this.dateLabel = Object.keys(allarea.pv).sort();
    this.checkData();
    this.drawData();
    this.cd.markForCheck(); // marks path
  }

  checkData(): void {
    if (!this.graphData?.pv || Object.keys(this.graphData.pv).length === 0) {
      this.irregularData = true;
    } else {
      this.irregularData = false;
    }
  }
}
