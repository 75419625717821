import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css'],
})
export class TooltipComponent implements OnInit {
  @Input() iconMode!: boolean;
  @Input() displayText!: string;
  @Input() iconClass!: string;
  @Input() detail!: string;
  @Input() length!: number;
  @Input() position!: 'top' | 'left' | 'bottom' | 'right';
  @Input() detailWidth!: string;

  constructor() {}

  ngOnInit(): void {}

  get detailStyle(): { [key: string]: string } {
    return {
      width: this.detailWidth != '' ? this.detailWidth : '100%',
    };
  }
}
