import { Component, OnInit, Input } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-side-col',
  templateUrl: './side-col.component.html',
  styleUrls: ['./side-col.component.css'],
  providers: [Location],
})
export class SideColComponent implements OnInit {
  @Input() activePart!: string;

  // 翻訳済みテキスト
  weekly = '';
  behavioral = '';
  detailed = '';
  user = '';

  constructor(private translate: TranslateService, private location: Location) {
    this.translate
      .get([
        'sideCol.Weekly',
        'sideCol.Behavioral',
        'sideCol.detailed',
        'sideCol.user',
      ])
      .subscribe((translations) => {
        this.weekly = translations['sideCol.Weekly'];
        this.behavioral = translations['sideCol.Behavioral'];
        this.detailed = translations['sideCol.detailed'];
        this.user = translations['sideCol.user'];
      });
  }

  ngOnInit(): void {}

  setHash(hash: string): void {
    const queryParams = new URL(location.href).searchParams;
    const fromDate = queryParams.get('date_s');
    const toDate = queryParams.get('date_e');
    if (fromDate && toDate) {
      this.location.replaceState(
        location.pathname,
        `date_s=${fromDate}&date_e=${toDate + hash}`,
      );
    } else {
      this.location.replaceState(location.pathname + hash);
    }
  }
}
