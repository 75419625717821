import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FunctionApiService {
  constructor(private http: HttpClient) {}

  async putMapImage(url: string): Promise<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      }),
    };
    // console.log(url);
    return firstValueFrom(this.http.get(url, httpOptions))
      .then((result) => {
        // console.log(result);
        return result;
      })
      .catch((err) => {
        console.log(err);
        console.log(err.error.text);
        return JSON.parse(err.error.text);
      });
  }

  async get_from_function_url(
    url: string,
  ): Promise<{ [key: string]: number | unknown[] }> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Origin': 'http://localhost:4200',
        // 'Access-Control-Allow-Headers': 'Origin'
        'Access-Control-Allow-Headers':
          'X-Requested-With, Origin, X-Csrftoken, Content-Type, Accept', // この辺は使うフレームワークにより異なるが許可するヘッダーを定義しておく。
      }),
    };
    // console.log(url);
    return firstValueFrom(this.http.post(url, '', httpOptions))
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.log(err);
        if (err.error.text) {
          console.log(err.error.text);
          return err;
          // return JSON.parse(err.error.text);
        }
        if (err.message) {
          console.log(err.message);
          return err;
        }

        // return Promise.reject(err);
      });
  }
  async get_jwt_token(
    url: string,
    data: { email: string; password: string },
  ): Promise<unknown> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': '*',
      }),
    };
    // console.log(httpOptions, data, url);
    return firstValueFrom(
      this.http.post<Record<string, string>>(url, data, httpOptions),
    )
      .then((result) => {
        // console.log(result);
        return result.token;
      })
      .catch((err) => {
        console.log(err);
        if (err.error.text) {
          console.log(err.error.text);
          return JSON.parse(err.error.text);
        }
        if (err.message) {
          console.log(err.message);
          return err.message;
        }

        return Promise.reject(err);
      });
  }
  async get_from_authed_url(url: string, jwt: string): Promise<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Origin': 'http://localhost:4200',
        'Access-Control-Allow-Headers': '*',
        Authorization: 'JWT ' + jwt,
      }),
      withCredentials: true,
    };
    // console.log(jwt);
    return firstValueFrom(this.http.get(url, httpOptions))
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.log(err);
        if (err.error.text) {
          console.log(err.error.text);
          return JSON.parse(err.error.text);
        }
        if (err.message) {
          console.log(err.message);
          return err.message;
        }

        return Promise.reject(err);
      });
  }
}
