// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.usage-time {
  min-height: 835px;
}

:host ::ng-deep .usage-time .p-datatable {
  margin-top: 40px;
}

:host ::ng-deep .p-datatable thead th:first-of-type {
  width: 150px;
}

:host ::ng-deep .p-datatable td:first-of-type {
  text-align: left;
}

.device,
.os,
.language,
.area {
  display: flex;
  align-items: baseline;
}

.device {
  margin-top: 70px;
  height: 500px;
}

.os,
.language,
.area {
  margin-top: 75px;
  max-height: 500px;
}

.area {
  margin-bottom: 150px;
}

.chart-area {
  width: 50%;
}

.chart-area {
  margin-left: 0;
}

:host ::ng-deep .p-paginator {
  margin-bottom: 70px;
}

:host ::ng-deep .p-datatable-header {
  background-color: #f0f2f8;
  border: none;
}

:host ::ng-deep .p-datatable-header.ng-star-inserted {
  padding: 0 0 1rem 1rem;
}

.not-found {
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/pages/details/details-page/user/user.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;;;;EAIE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;;;EAGE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".usage-time {\n  min-height: 835px;\n}\n\n:host ::ng-deep .usage-time .p-datatable {\n  margin-top: 40px;\n}\n\n:host ::ng-deep .p-datatable thead th:first-of-type {\n  width: 150px;\n}\n\n:host ::ng-deep .p-datatable td:first-of-type {\n  text-align: left;\n}\n\n.device,\n.os,\n.language,\n.area {\n  display: flex;\n  align-items: baseline;\n}\n\n.device {\n  margin-top: 70px;\n  height: 500px;\n}\n\n.os,\n.language,\n.area {\n  margin-top: 75px;\n  max-height: 500px;\n}\n\n.area {\n  margin-bottom: 150px;\n}\n\n.chart-area {\n  width: 50%;\n}\n\n.chart-area {\n  margin-left: 0;\n}\n\n:host ::ng-deep .p-paginator {\n  margin-bottom: 70px;\n}\n\n:host ::ng-deep .p-datatable-header {\n  background-color: #f0f2f8;\n  border: none;\n}\n\n:host ::ng-deep .p-datatable-header.ng-star-inserted {\n  padding: 0 0 1rem 1rem;\n}\n\n.not-found {\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
