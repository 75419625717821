// FIXME: ts-ignoreを使わないように修正してください.
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  inject,
} from '@angular/core';
import * as L from 'leaflet';
import { Observable, firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';

import dayjs from 'dayjs';
import { FunctionApiService } from '../../../services/function-api.service';
import {
  AuthedUserProfile,
  AuthedUserProfileService,
} from '../../../services/authed-user-profile.service';
import { environment } from 'src/environments/environment';
import { isBussiness } from '../../../../util';
import { Table } from 'primeng/table';
import { FilterMetadata } from 'primeng/api';
import { MapRepository } from 'src/app/dashboard/repositories/map.repository';
import { DatareportRepository } from 'src/app/dashboard/repositories/datareport.repository';
import { RankDto } from 'src/app/dashboard/repositories/dto/datareport.dto';
import { MapListWithTagNameDto } from 'src/app/dashboard/repositories/dto/map-list-with-tag-name.dto';

export interface MapIdTitle {
  /**
   * @deprecated
   * 現状未使用. データレポートでは `map_key` のほうを利用してください。
   */
  map_id: string;
  map_key: string;
  map_title: string;
  map_image: { langs: [{ lang: string; title: string }] };
  url: string;
  uu_data: number;
}

export type RankData = [MapIdTitle] | [];

interface ColumnData {
  field: string;
  header: string;
}
interface MapData {
  image_key: string;
}

@Component({
  selector: 'app-map-select',
  templateUrl: './index.page.html',
  styleUrls: ['./index.page.scss'],
})
export class IndexPage implements OnInit {
  private readonly mapRepository = inject(MapRepository);
  private readonly datareportRepository = inject(DatareportRepository);

  @ViewChild('userEmailInput') userEmail!: ElementRef;
  @ViewChild('passwordInput') password!: ElementRef;
  @ViewChild('setTokenInput') setTokenValue!: ElementRef;
  @ViewChild('tt') tt!: Table;

  rankData: RankData = [];
  mapList: MapIdTitle[] = [];
  displayMapList: MapIdTitle[] = [];
  isMobile: boolean =
    L.Browser.mobile &&
    !sessionStorage.getItem('enablePCMode') &&
    window.parent.screen.width < 1024 &&
    !(
      window.parent.screen.height >= 1024 &&
      window.orientation.toString().indexOf('90') != -1
    );
  keyword = '';
  perPage = 10; // 1Pあたりの表示件数
  pageTotal = 0;
  maxPaging: number = this.isMobile ? 5 : 7; // ページングに表示する最大数(...を含まない)
  // モバイル版で `詳細なデータはPC版からご確認ください` の旨のinfoを閉じたかどうか
  confirmed = false;
  cols: ColumnData[] = [];
  noMap = false; // マップ未作成のフラグ
  noMapData = false; // 開発環境などでマップデータが拾えなかったときのフラグ
  dataCompilation = false; // データ集計中のフラグ
  loading = true;
  timezone: string;
  mapSelectSetting = (function () {
    const setting = sessionStorage.getItem('mapSelectSetting');
    return setting ? JSON.parse(setting) : null;
  })();

  // 翻訳済みテキスト
  confirmMessage = '';
  beingDisplayed = '';
  statusOpen = '';
  statusPrivate = '';
  statusLimited = '';
  views = '';
  likes = '';
  searchMap = '';

  isBussiness = isBussiness;
  unknown = '';

  constructor(
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private meta: Meta,
    private title: Title,
    private functionApiService: FunctionApiService,
    private authedUserProfileService: AuthedUserProfileService,
  ) {
    this.translate
      .get([
        'mapSelect.ConfirmMessage',
        'mapSelect.BeingDisplayed',
        'mapSelect.Title',
        'mapSelect.PreviousDayViews',
        'mapSelect.PreviousDayLike',
        'mapSelect.Status',
        'mapSelect.UpdatedAt',
        'mapSelect.Board',
        'mapSelect.PageTitle',
        'mapSelect.Description',
        'mapSelect.Open',
        'mapSelect.Private',
        'mapSelect.Limited',
        'mapSelect.Views',
        'mapSelect.Like',
        'mapSelect.Search',
        'Unknown',
      ])
      .subscribe((translations) => {
        // Tableのヘッダ
        this.cols = [
          { field: 'map_title', header: translations['mapSelect.Title'] },
          {
            field: 'uu_data',
            header: translations['mapSelect.PreviousDayViews'],
          },
          {
            field: 'liked_change',
            header: translations['mapSelect.PreviousDayLike'],
          },
          { field: 'is_published', header: translations['mapSelect.Status'] },
          { field: 'updated_at', header: translations['mapSelect.UpdatedAt'] },
          { field: 'board_name', header: translations['mapSelect.Board'] },
        ];

        // meta情報
        this.title.setTitle(translations['mapSelect.PageTitle']);
        this.meta.updateTag({
          property: 'og:title',
          content: translations['mapSelect.PageTitle'],
        });
        this.meta.updateTag({
          property: 'og:site_name',
          content: translations['mapSelect.PageTitle'],
        });
        this.meta.updateTag({
          name: 'description',
          content: translations['mapSelect.Description'],
        });
        this.meta.updateTag({
          property: 'og:description',
          content: translations['mapSelect.Description'],
        });

        // 表示用テキスト
        this.confirmMessage = translations['mapSelect.ConfirmMessage'];
        this.statusOpen = translations['mapSelect.Open'];
        this.statusPrivate = translations['mapSelect.Private'];
        this.statusLimited = translations['mapSelect.Limited'];
        this.views = translations['mapSelect.Views'];
        this.likes = translations['mapSelect.Like'];
        this.searchMap = translations['mapSelect.Search'];
        this.unknown = translations['Unknown'];
      });

    // パラメータ付きの翻訳取得
    this.translate
      .get('mapSelect.BeingDisplayed', { yesterday: this.getYesterday() })
      .subscribe((text: string) => {
        this.beingDisplayed = text;
      });

    try {
      this.timezone =
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Tokyo'
          ? 'jst'
          : 'utc';
    } catch (e) {
      const language =
        (window.navigator.languages && window.navigator.languages[0]) ||
        window.navigator.language;
      this.timezone = language.indexOf('ja') != -1 ? 'jst' : 'utc';
    }
  }

  async ngOnInit(): Promise<void> {
    this.confirmed = this.getConfirm();
    await this.authedUserProfileService.initAuthedUserProfile();
    // // 認証されていたらユーザーのメールアドレスによって取得するマップリストを変更
    if (this.authedUserProfile?.id && !this.authedUserProfile?.isStrolyStaff) {
      // 一般利用者
      await this.getAuthedMymaps();
      this.cd.markForCheck();
    } else if (this.authedUserProfile?.isStrolyStaff) {
      // Strolyのアドレスを持っている人
      this.getMapData();
    } else if (!this.authedUserProfile?.id) {
      // ログインしていない
      window.location.href = `${environment.authApiHost}/login?next=${window.location.href}`;
    }
  }

  get authedUserProfile(): AuthedUserProfile {
    return this.authedUserProfileService.getAuthedUserProfile();
  }

  async getAuthedMymaps(): Promise<void> {
    // this.mapList = await this.getAPIMapData();
    this.mapList = await this.getAPIMapDataByFirebase();

    this.mapList.sort(this.compareUuDataDesc);
    this.loading = false;

    if (this.mapList.length == 0) {
      this.noMap = true;
    }
    this.cd.markForCheck();
  }

  compareUuDataDesc(a: MapIdTitle, b: MapIdTitle): number {
    // ソート処理
    const genreA = a.uu_data;
    const genreB = b.uu_data;

    let comparison = 0;
    if (genreA > genreB) {
      comparison = 1;
    } else if (genreA < genreB) {
      comparison = -1;
    }
    return comparison * -1;
  }

  async getAPIMapDataByFirebase(): Promise<MapIdTitle[]> {
    const mymaps = await this.mapRepository.fetchMyMapsWithTagName();

    // 地図未作成
    if (mymaps && mymaps['count'] === 0) {
      this.noMap = true;
      return [];
    }

    // uu, likesの情報を取得しapiDataObjに格納
    const mapIds = mymaps['results'].map((val) => val.map_key);

    const targetDate = dayjs().subtract(2, 'day').startOf('day').toDate();
    const res = await this.datareportRepository.fetchRankByMapIds(
      mapIds,
      targetDate,
    );
    const rankdata: MapIdTitle[] = [];
    for (const dto of res) {
      if (rankdata.find((_d) => _d['map_key'] === dto['map_key'])) {
        continue;
      }
      rankdata.push(this.convV1RankToV0Structure(dto));
    }

    const apiDataObj: { [k: string]: MapIdTitle } = {};
    for (const val of rankdata) {
      apiDataObj[val.map_key] = val;
    }

    // APIからの情報をmapListに追加
    const newMapList: MapIdTitle[] = [];
    for (const mapData of mymaps['results']) {
      const obj: { [k: string]: any } = {};
      // @ts-ignore
      map_data_col_names.map((key) => (obj[key] = mapData[key]));
      // @ts-ignore
      map_image_col_names.map((key) => (obj[key] = mapData.map_image[key]));
      obj['map_id'] = mapData.map_key;
      obj['map_title'] = getLang(mapData.map_image).title;
      for (const lang of obj['langs']) {
        if (lang.lang === obj['default_lang']) {
          obj['dc_description'] = lang.dc_description;
        }
      }
      obj['tags_with_name'] = [];
      for (const tag of mapData['tags_with_name'] || []) {
        obj['tags_with_name'].push(tag.tag);
      }
      // NOTE: obj(=MapListWithTagNameDto['results'][number]のプロパティのいずれか)にmap_idは存在しない. Deprecatedである. map_idが存在する場合はデータ定義も古い.
      if (
        apiDataObj[obj['map_id']] !== null &&
        apiDataObj[obj['map_id']] !== undefined
      ) {
        const apiData = apiDataObj[obj['map_id']] as any;
        obj['uu_data'] = isNaN(apiData.uu_data) ? 0 : apiData.uu_data;
        obj['uu_change'] =
          apiData.uu_change.indexOf('↑') >= 0 ? '↑' : apiData.uu_change;
        obj['liked_change'] = apiData.liked_change;
      } else {
        obj['uu_data'] = 0;
        obj['uu_change'] = '-';
        obj['liked_change'] = 0;
      }
      newMapList.push(obj as MapIdTitle);
    }
    return newMapList;
  }

  /**
   * @deprecated
   * こちらのメソッドは利用されていません. getAPIMapDataByFirebaseを利用してください.
   */
  async getAPIMapData(): Promise<MapIdTitle[]> {
    const mymaps = await this.mapRepository.fetchMyMapsWithTagName();
    // 地図未作成
    if (mymaps && mymaps['count'] == 0) {
      this.noMap = true;
      return [];
    }

    // uu, likesの情報を取得しapiDataObjに格納
    const mapIds = mymaps['results'].map((val) => val.map_key);
    //apiUrl = 'https://asia-northeast1-bq-dwh-test.cloudfunctions.net/map_data_api_csql';
    const apiUrl = `https://nuuxz3fw62jtbhwpg6abkcbfcu0sihqw.lambda-url.us-west-2.on.aws/?data_type=map_ids&target=${mapIds}&changed=true`;
    const data = await (<{ [key: string]: MapData[] }>(
      (<unknown>this.functionApiService.get_from_function_url(apiUrl))
    ));
    const apiDataObj: { [k: string]: MapData } = {};
    if (!data.error) {
      data.map_data.map((val) => {
        apiDataObj[val.image_key] = val;
      });
    } else {
      // this.noMapData = true;
    }

    // APIからの情報をmapListに追加
    const newMapList: MapIdTitle[] = [];
    for (const mapData of mymaps['results']) {
      const obj: { [k: string]: any } = {};
      // @ts-ignore
      map_data_col_names.map((key) => (obj[key] = mapData[key]));
      // @ts-ignore
      map_image_col_names.map((key) => (obj[key] = mapData.map_image[key]));
      obj['map_id'] = mapData.map_key;
      obj['map_title'] = getLang(mapData.map_image).title;
      for (const lang of obj['langs']) {
        if (lang.lang == obj['default_lang']) {
          obj['dc_description'] = lang.dc_description;
        }
      }
      obj['tags_with_name'] = [];
      for (const tag of mapData['tags_with_name']) {
        obj['tags_with_name'].push(tag.tag);
      }
      // NOTE: obj(=MapListWithTagNameDto['results'][number]のプロパティのいずれか)にmap_idは存在しない. Deprecatedである. map_idが存在する場合はデータ定義も古い.
      if (apiDataObj[obj['map_id']] != null) {
        // FIXME: apiDataにuu_data, uu_change, liked_changeは存在しなさそうであるので要確認 & 修正.
        const apiData = apiDataObj[obj['map_id']];
        // @ts-ignore
        obj['uu_data'] = isNaN(apiData['uu_data']) ? 0 : apiData['uu_data'];
        obj['uu_change'] =
          // @ts-ignore
          apiData['uu_change'].indexOf('↑') >= 0 ? '↑' : apiData['uu_change'];
        // @ts-ignore
        obj['liked_change'] = apiData['liked_change'];
      } else {
        obj['uu_data'] = 0;
        obj['uu_change'] = '-';
        obj['liked_change'] = 0;
      }
      newMapList.push(obj as MapIdTitle);
    }
    return newMapList;
  }

  async getMapData(): Promise<void> {
    this.mapList = await this.getAPIMapDataByFirebase();

    const dates = [dayjs().subtract(1, 'day'), dayjs().subtract(2, 'day')];
    const res = await this.datareportRepository.fetchTopRank(dates);
    this.rankData = [];

    // 前日のランキングがない場合は2日前のランキングを表示
    let dtos = res.filter((dto) => dto.date === dates[0].format('YYYY-MM-DD'));
    if (dtos.length === 0) {
      dtos = res;
    }

    for (const dto of dtos) {
      this.rankData.push(this.convV1RankToV0Structure(dto) as never);
    }

    this.rankData.forEach((mapData: MapIdTitle) => {
      if (isNaN(mapData.uu_data)) {
        mapData.uu_data = 0;
      }
      this.mapList.push(mapData);
    });

    this.mapList.sort(this.compareUuDataDesc);
    this.loading = false;
    if (this.mapList.length) {
      this.noMap = false;
    }
    this.cd.markForCheck(); // marks path
  }

  getYesterday(): string {
    const language =
      (window.navigator.languages && window.navigator.languages[0]) ||
      window.navigator.language;
    const yesterdayDate = dayjs().add(-1, 'day');

    return language === 'ja'
      ? yesterdayDate.format('MM月DD日')
      : yesterdayDate.format('MM-DD');
  }

  /** モバイル版で `詳細なデータはPC版からご確認ください` の旨のinfoを閉じたかを取得する.
   * ただし, 一度閉じてもdetailsページからリダイレクトされた場合は再度infoを表示する.
   */
  getConfirm(): boolean {
    const cookies = document.cookie.split(';');
    for (const c of cookies) {
      const cookie = c.split('=');
      if (cookie[0].replace(' ', '') == 'DataDashbord.confirmed') {
        if (sessionStorage.getItem('fromDetail')) {
          sessionStorage.removeItem('fromDetail');
          return false;
        }
        return true;
      }
    }
    return false;
  }

  /** モバイル版で `詳細なデータはPC版からご確認ください` の旨のinfoを一度閉じたあとに再びinfoを表示しないようにする. */
  setConfirmed(): void {
    document.cookie = 'DataDashbord.confirmed=1;max-age=31536000';
    this.confirmed = true;
  }

  searchMapInputEvent(event: Event): void {
    if (event.target instanceof HTMLInputElement) {
      this.tt.filterGlobal(event.target.value, 'contains');
    }
  }

  get searchMapInputValue(): string {
    if (this.tt === undefined) {
      return '';
    }
    return this.tt.filters.global
      ? (this.tt.filters.global as FilterMetadata).value
      : '';
  }

  // 新データモデル(Ver.1-v1rank)をアプリで使うために旧データモデル(Ver.0)に変換
  // FIXME: 戻り値の型は `MapIdTitle` ではなさそうなので要修正.
  private convV1RankToV0Structure(v1: RankDto): MapIdTitle {
    const v0 = {
      ...v1['metadata'],
      map_id: v1['map_id'],
      map_key: v1['map_key'],
      url: v1['map_key'],
      uu_data: v1['uu'],
      liked_count: v1['liked'],
      liked_change: v1['liked_ytd'],
      uu_change: v1['uu_status'],
      rank_change: v1['rank_status'],
    };
    return v0;
  }
}

const map_data_col_names = [
  'board_name',
  'board_is_default',
  'board_openness',
  'created_user_name',
  'created_user_avatar',
  'liked_count',
  'view_count',
  'map_key',
  'openness',
  'is_publish_reserved',
  'is_published',
  'token',
  'created_at',
  'updated_at',
  'published_at',
  'memo',
  'board',
];
const map_image_col_names = [
  'langs',
  'image_key',
  'image',
  'thumbnail',
  'thumbnail_512',
  'default_lang',
  'dc_reference',
  'dc_license',
];

function getLang(
  map_image: MapListWithTagNameDto['results'][number]['map_image'],
) {
  // var default_lang = map_image.default_lang;
  if (map_image.langs.length > 0) {
    const lang = map_image.langs[0];
    return lang;
  } else {
    return {
      dc_creator: '',
      dc_description: '',
      dc_rights: '',
      dc_title: '',
      id: 1,
      lang: '',
      title: '',
    };
  }
}
