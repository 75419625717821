import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  inject,
} from '@angular/core';

import { Location } from '@angular/common';
import * as L from 'leaflet';

import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { JwtAuthService } from 'src/app/dashboard/services/jwt-auth.service';
import {
  AuthedUserProfile,
  AuthedUserProfileService,
} from '../../../services/authed-user-profile.service';
import { AuthService } from '../../../services/auth.service';
import { LoadingService } from '../../../../shared/services/loading.service';
import { SnackbarService } from '../../../../shared/services/snackbar.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css'],
  providers: [Location],
})
export class ToolbarComponent implements OnInit {
  private readonly authService = inject(AuthService);
  private readonly loadingService = inject(LoadingService);
  private readonly snackbarService = inject(SnackbarService);

  @Input() locationPathname!: string;
  @ViewChild('userEmailInput') userEmail!: ElementRef;
  @ViewChild('passwordInput') password!: ElementRef;
  @ViewChild('loginFailed') loginFailedMessage!: ElementRef;

  userMenuActive = false;
  enablePCMode = sessionStorage.getItem('enablePCMode');
  isMobile =
    L.Browser.mobile &&
    !this.enablePCMode &&
    window.parent.screen.width < 1024 &&
    !(
      window.parent.screen.height >= 1024 &&
      window.orientation.toString().indexOf('90') != -1
    );
  under1024px =
    L.Browser.mobile &&
    this.enablePCMode &&
    window.parent.screen.width < 1024 &&
    !(
      window.parent.screen.height >= 1024 &&
      window.orientation.toString().indexOf('90') != -1
    );

  toTopButtonVisible = false;
  hasAuthedUserProfile = false;
  windowHeight = window.innerHeight > 1000 ? 1000 : window.innerHeight;
  language =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language;

  // 翻訳済みテキスト
  dataReport = '';
  logOut = '';
  myPage = '';
  editProfile = '';
  newMap = '';
  newBoard = '';
  home = '';
  search = '';
  helpGuide = '';
  feedback = '';
  terms = '';
  privacyPolicy = '';
  operatingCompany = '';
  changePCMode = '';
  changeSPMode = '';
  logoutErrorMessage = '';

  loading = true;
  isScrollRunning = false;

  authApiHost = environment.authApiHost;
  strolyURL = environment.strolyURL;

  get active(): { [key: string]: string } {
    return {
      display: this.userMenuActive ? 'block' : 'none',
    };
  }

  get pcMode(): { [key: string]: string } {
    return {
      position: this.isMobile ? 'inherit' : 'relative',
    };
  }

  get toTopBtnPos(): { [key: string]: string } {
    let toTopBtnMargin: string;
    if (window.innerWidth > 1300) {
      toTopBtnMargin = location.pathname !== '/' ? '1240px' : '1210px';
    } else {
      toTopBtnMargin = (window.innerWidth - 130).toString() + 'px';
    }

    return {
      marginLeft: toTopBtnMargin,
    };
  }

  constructor(
    private jwtAuth: JwtAuthService,
    public translate: TranslateService,
    private cd: ChangeDetectorRef,
    private authedUserProfileService: AuthedUserProfileService,
  ) {
    this.translate
      .get([
        'toolbar.DataReport',
        'toolbar.LogOut',
        'toolbar.MyPage',
        'toolbar.EditProfile',
        'toolbar.NewMap',
        'toolbar.NewBoard',
        'toolbar.Home',
        'toolbar.Search',
        'toolbar.HelpGuide',
        'toolbar.Feedback',
        'toolbar.Terms',
        'toolbar.PrivacyPolicy',
        'toolbar.OperatingCompany',
        'toolbar.ChangePCMode',
        'toolbar.ChangeSPMode',
        'error.logout',
      ])
      .subscribe((translations) => {
        this.dataReport = environment.production
          ? translations['toolbar.DataReport']
          : translations['toolbar.DataReport'] + `(${environment.envName})`;
        this.logOut = translations['toolbar.LogOut'];
        this.myPage = translations['toolbar.MyPage'];
        this.editProfile = translations['toolbar.EditProfile'];
        this.newMap = translations['toolbar.NewMap'];
        this.newBoard = translations['toolbar.NewBoard'];
        this.home = translations['toolbar.Home'];
        this.search = translations['toolbar.Search'];
        this.helpGuide = translations['toolbar.HelpGuide'];
        this.feedback = translations['toolbar.Feedback'];
        this.terms = translations['toolbar.Terms'];
        this.privacyPolicy = translations['toolbar.PrivacyPolicy'];
        this.operatingCompany = translations['toolbar.OperatingCompany'];
        this.changeSPMode = translations['toolbar.ChangeSPMode'];
        this.changePCMode = translations['toolbar.ChangePCMode'];
        this.logoutErrorMessage = translations['error.logout'];

        cd.detectChanges(); // 404の時に表示が欠ける不具合の対策
      });

    if (
      this.enablePCMode ||
      (window.parent.screen.width >= 1024 && L.Browser.mobile) ||
      (L.Browser.mobile &&
        window.parent.screen.height >= 1024 &&
        window.orientation.toString().indexOf('90') != -1)
    ) {
      this.setViewport();
    }

    window.addEventListener('scroll', () => {
      if (!this.isScrollRunning) {
        this.isScrollRunning = true;

        // 描画する前のタイミングで呼び出してもらう
        window.requestAnimationFrame(() => {
          this.visibleToTopButton();
          this.cd.markForCheck();
          this.isScrollRunning = false;
        });
      }
    });

    this.jwtAuth.setHostAddress(environment.authApiHost);
  }

  async ngOnInit(): Promise<void> {
    await this.authedUserProfileService.initAuthedUserProfile();
    if (this.authedUserProfile) {
      this.hasAuthedUserProfile = true;
      this.loading = false;
      this.cd.markForCheck();
    } else {
      this.loading = false;
      this.cd.markForCheck();
    }
  }

  get authedUserProfile(): AuthedUserProfile {
    return this.authedUserProfileService.getAuthedUserProfile();
  }

  logout(): void {
    this.loadingService.start();

    try {
      this.authService.logout();
    } catch (error) {
      console.error(error);
      this.loadingService.end();
      this.snackbarService.openSnackbar(this.logoutErrorMessage);
    }
  }

  toggleUserMenu(): void {
    this.userMenuActive = !this.userMenuActive;
    if (this.isMobile) {
      const container = document.getElementsByClassName('container')[0];
      if (this.userMenuActive) {
        container.setAttribute('style', 'display: none;');
      } else {
        container.removeAttribute('style');
      }
    }
  }

  changeToPCMode(): void {
    sessionStorage.setItem('enablePCMode', 'true');
    location.reload();
  }

  changeToSPMode(): void {
    sessionStorage.removeItem('enablePCMode');
    location.reload();
  }

  setViewport(): void {
    const viewportContent = 'width=1400px,user-scalable=no,shrink-to-fit=yes';
    const elm = document.querySelector("meta[name='viewport']");
    if (elm) {
      elm.setAttribute('content', viewportContent);
    }
  }

  visibleToTopButton(): void {
    this.toTopButtonVisible = window.pageYOffset >= this.windowHeight;
  }
}
