import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-map-thumbnail',
  templateUrl: './map-thumbnail.component.html',
  styleUrls: ['./map-thumbnail.component.css'],
})
export class MapThumbnailComponent implements OnInit {
  @Input() mapID!: string;
  imgUrl = '';

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.mapID) {
      this.getThumImage();
    }
  }

  getThumImage(): void {
    // this.imgUrl = `${environment.strolyURL}/media/thumbnails/${this.mapID}_512.jpg`;
    this.imgUrl = `https://stroly.com/media/thumbnails/${this.mapID}_512.jpg`;
    this.cd.markForCheck(); // marks path
  }
}
