// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chart {
  height: 23rem;
  margin: 1rem 0 2rem 0;
  background-color: #fff;
  padding: 0.8rem;
}

.pie-charts {
  margin: 1rem 0 3rem 0;
}

.chart-title {
  font-size: 1.3rem;
  font-weight: bold;
  color: #7a7b81;
  padding-bottom: 0.3rem;
  margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/components/charts/chart.component.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qBAAqB;EACrB,sBAAsB;EACtB,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;EACd,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":[".chart {\n  height: 23rem;\n  margin: 1rem 0 2rem 0;\n  background-color: #fff;\n  padding: 0.8rem;\n}\n\n.pie-charts {\n  margin: 1rem 0 3rem 0;\n}\n\n.chart-title {\n  font-size: 1.3rem;\n  font-weight: bold;\n  color: #7a7b81;\n  padding-bottom: 0.3rem;\n  margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
