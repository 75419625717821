import { L } from 'stroly-js';
import { LandmarkDisp } from '../../ui/pages/details/details-page/behavioral/constants';
import { LandmarkCategories } from '../../ui/pages/details/details-page/behavioral/models';
import { LandmarkMarker } from './landmark-marker';

type MapType = 'Stroly' | 'Standard';

export class LandmarkMarkers {
  constructor(
    private readonly landmarkCategories: LandmarkCategories[],
    private readonly mapType: MapType,
  ) {}

  private landmarkMarkers: L.Marker[] = [];
  readonly layer = L.featureGroup();
  setLandmarkMarkers(landmarkDisp: LandmarkDisp) {
    this.landmarkMarkers.forEach((marker) => {
      marker.remove();
    });
    this.landmarkMarkers = [];

    if (landmarkDisp === LandmarkDisp.None) {
      return;
    }

    this.createLandmarkMarkers(landmarkDisp);
  }

  private createLandmarkMarkers(landmarkDisp: LandmarkDisp) {
    this.landmarkCategories.forEach((category) => {
      category.landmark.forEach((landmark) => {
        const landmarkMarker = new LandmarkMarker(
          this.mapType === 'Stroly'
            ? L.latLng(landmark.x, landmark.y)
            : L.latLng(Number(landmark.lat), Number(landmark.lng)),
          category.icon,
          landmark.name['ja'],
          landmarkDisp === LandmarkDisp.Full,
        );

        this.landmarkMarkers = [...this.landmarkMarkers, landmarkMarker.marker];
        this.layer.addLayer(landmarkMarker.marker);
      });
    });
  }
}
