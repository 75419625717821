import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-failed-get-map-data',
  templateUrl: './failed-get-map-data.component.html',
  styleUrls: ['./failed-get-map-data.component.css'],
})
export class FailedGetMapDataComponent implements OnInit {
  titleText = '';
  message = '';
  forTop = '';
  contact = '';

  constructor(
    private meta: Meta,
    private title: Title,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    this.translate
      .get([
        'failedGetMapData.Title',
        'failedGetMapData.Caption',
        'pageNotFound.ForTop',
        'pageNotFound.Contact',
      ])
      .subscribe((translations) => {
        this.titleText = translations['failedGetMapData.Title'];
        this.message = translations['failedGetMapData.Caption'];
        this.forTop = translations['pageNotFound.ForTop'];
        this.contact = translations['pageNotFound.Contact'];

        this.title.setTitle(this.titleText);
        this.meta.updateTag({
          property: 'og:title',
          content: this.titleText,
        });
        this.meta.updateTag({
          property: 'og:site_name',
          content: this.titleText,
        });

        this.cd.detectChanges();
      });
  }

  ngOnInit(): void {}
}
