import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { CsvService } from 'src/app/dashboard/services/csv.service';
import { XlsxService } from '../../../../../../services/xlsx.service';
import { ColumnData, FileNameInfo, UserData } from './user-data';

@Component({
  selector: 'app-user-data-table',
  templateUrl: './user-data-table.component.html',
  styleUrls: [
    '../../../../../../../dashboard/common.css',
    './user-data-table.component.css',
  ],
})
export class UserDataTableComponent implements OnInit {
  @Input() tableData!: UserData[];
  @Input() categoryLabel!: string;
  @Input() columns!: ColumnData[];
  @Input() fileNameInfo!: FileNameInfo;

  language =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language;
  openFormatList = false;

  // 翻訳済テキスト
  download = '';

  constructor(
    private translate: TranslateService,
    private csvService: CsvService,
    private xlsxService: XlsxService,
  ) {
    this.translate.get(['Download']).subscribe((translations) => {
      this.download = translations['Download'];
    });
  }

  ngOnInit(): void {}

  exportTable(fileType: 'csv' | 'xlsx'): void {
    const filename =
      this.fileNameInfo.header +
      '_' +
      this.fileNameInfo.fromDate +
      '-' +
      this.fileNameInfo.toDate;

    const exportData: string[][] = [];
    const labelRow = [this.categoryLabel];
    for (let i = 0; i < this.columns.length; i++) {
      if (this.language.indexOf('ja') != -1) {
        labelRow.push(this.columns[i].header.join(''));
      } else {
        labelRow.push(this.columns[i].header.join(' '));
      }
    }
    exportData.push(labelRow);

    for (let i = 0; i < this.tableData.length; i++) {
      const values: string[] = [];
      Object.values(this.tableData[i]).map((value) => {
        values.push(value.toString());
      });
      exportData.push(values);
    }

    switch (fileType) {
      case 'csv':
        this.csvService.exportChartData(exportData, filename);
        break;
      case 'xlsx':
        this.xlsxService.exportExcel(exportData, filename);
        break;
      default:
        throw Error('Error: 不正なパラメータです');
    }

    this.openFormatList = false;
  }
}
