import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateFilterForm } from './date-filter-form';
import { DashboardDatepickerComponent } from '../../../../components/dashboard-datepicker/dashboard-datepicker.component';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DashboardDatepickerComponent],
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
})
export class DateFilterComponent implements OnInit {
  private readonly translate = inject(TranslateService);

  @Input()
  form!: DateFilterForm;

  // 翻訳済みテキスト
  search = '';
  alertMessage = '';
  get filterCaption$(): Observable<string> {
    return this.translate.get('dashboard.Span', {
      days: this.form.diffDays(),
    });
  }

  @Output()
  private onClickSearchButton = new EventEmitter<never>();

  onClick() {
    this.onClickSearchButton.emit();
  }

  ngOnInit(): void {
    this.initTranslation();
  }

  private initTranslation(): void {
    this.translate
      .get(['dashboard.Search', 'dashboard.Alert'])
      .subscribe((translations) => {
        this.search = translations['dashboard.Search'];
        this.alertMessage = translations['dashboard.Alert'];
      });
  }
}
