// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  text-align: center;
}

.icon {
  margin: 100px auto 0;
  display: block;
  width: 103px;
  height: auto;
}

.message-title {
  margin-top: 21px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  color: #99c;
}

.message-detail {
  margin-top: 28px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.for-top {
  margin-top: 50px;
  padding: 12px 30px;
  display: inline-block;
  text-decoration: none;
  background: #a69fd5;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  box-shadow: 5px 5px 5px #d2d7e7;
  border-radius: 3px;
  cursor: pointer;
}

.for-top:active {
  transform: translateY(4px);
  box-shadow: 0px 0px 1px #d2d7e7;
}

.contact {
  margin-top: 20px;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #666;
}

/* mobile */
@media screen and (max-width: 1024px) {
  .icon {
    margin-top: 70px;
  }
  .message-title {
    margin-top: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/pages/not-found-page/not-found.page.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,oBAAA;EACA,cAAA;EACA,YAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,qBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,+BAAA;EACA,kBAAA;EACA,eAAA;AACF;;AAEA;EAEE,0BAAA;EACA,+BAAA;AACF;;AAEA;EACE,gBAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AACF;;AAEA,WAAA;AACA;EACE;IACE,gBAAA;EACF;EAEA;IACE,gBAAA;EAAF;AACF","sourcesContent":[":host {\n  display: block;\n  text-align: center;\n}\n\n.icon {\n  margin: 100px auto 0;\n  display: block;\n  width: 103px;\n  height: auto;\n}\n\n.message-title {\n  margin-top: 21px;\n  margin-bottom: 0;\n  font-size: 18px;\n  font-weight: 700;\n  color: #99c;\n}\n\n.message-detail {\n  margin-top: 28px;\n  margin-bottom: 0;\n  font-size: 14px;\n  font-weight: 500;\n  color: #333;\n}\n\n.for-top {\n  margin-top: 50px;\n  padding: 12px 30px;\n  display: inline-block;\n  text-decoration: none;\n  background: #a69fd5;\n  font-size: 12px;\n  font-weight: 700;\n  color: #fff;\n  box-shadow: 5px 5px 5px #d2d7e7;\n  border-radius: 3px;\n  cursor: pointer;\n}\n\n.for-top:active {\n  -webkit-transform: translateY(4px);\n  transform: translateY(4px);\n  box-shadow: 0px 0px 1px #d2d7e7;\n}\n\n.contact {\n  margin-top: 20px;\n  display: inline-block;\n  font-size: 12px;\n  font-weight: 400;\n  color: #666;\n}\n\n/* mobile */\n@media screen and (max-width: 1024px) {\n  .icon {\n    margin-top: 70px;\n  }\n\n  .message-title {\n    margin-top: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
