// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  text-align: center;
}

.icon {
  margin: 0 auto;
  display: block;
  width: 103px;
  height: auto;
}

.not-found {
  margin-top: 32px;
}

.nothing {
  margin-top: 90px;
  width: 125px;
  height: auto;
}

.message-title {
  margin-top: 35px;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  color: #99c;
}

.message-detail {
  margin-top: 28px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.make-map {
  margin-top: 50px;
  padding: 12px 30px;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  background: #a69fd5;
  color: #fff;
  box-shadow: 5px 5px 5px #d2d7e7;
  border-radius: 3px;
}

.make-map:active {
  transform: translateY(4px);
  box-shadow: 0px 0px 1px #d2d7e7;
}

/* mobile */
@media screen and (max-width: 1024px) {
  .nothing {
    margin-top: 70px;
  }

  .message-title {
    margin-top: 25px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/pages/index-page/no-map/no-map.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;EACX,+BAA+B;EAC/B,kBAAkB;AACpB;;AAEA;EAEE,0BAA0B;EAC1B,+BAA+B;AACjC;;AAEA,WAAW;AACX;EACE;IACE,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[":host {\n  display: block;\n  text-align: center;\n}\n\n.icon {\n  margin: 0 auto;\n  display: block;\n  width: 103px;\n  height: auto;\n}\n\n.not-found {\n  margin-top: 32px;\n}\n\n.nothing {\n  margin-top: 90px;\n  width: 125px;\n  height: auto;\n}\n\n.message-title {\n  margin-top: 35px;\n  margin-bottom: 0;\n  font-size: 18px;\n  font-weight: 700;\n  color: #99c;\n}\n\n.message-detail {\n  margin-top: 28px;\n  margin-bottom: 0;\n  font-size: 14px;\n  font-weight: 500;\n  color: #333;\n}\n\n.make-map {\n  margin-top: 50px;\n  padding: 12px 30px;\n  display: inline-block;\n  text-decoration: none;\n  font-weight: 700;\n  background: #a69fd5;\n  color: #fff;\n  box-shadow: 5px 5px 5px #d2d7e7;\n  border-radius: 3px;\n}\n\n.make-map:active {\n  -webkit-transform: translateY(4px);\n  transform: translateY(4px);\n  box-shadow: 0px 0px 1px #d2d7e7;\n}\n\n/* mobile */\n@media screen and (max-width: 1024px) {\n  .nothing {\n    margin-top: 70px;\n  }\n\n  .message-title {\n    margin-top: 25px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
