// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-datepicker {
  width: 115px;
  position: relative;
}

.filter-select {
  padding: 10px;
  width: inherit;
  background-color: #fff;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  -webkit-text-fill-color: #666;
  opacity: 1;
  border: 1px solid #e4e4e4;
}

.calendar-icon-box {
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: 100%;
  cursor: pointer;
}

.icon-calendar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  width: 14px;
  height: auto;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/components/dashboard-datepicker/dashboard-datepicker.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;EACA,cAAA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,6BAAA;EACA,UAAA;EACA,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,cAAA;EACA,YAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;EACA,QAAA;EACA,2BAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AACF","sourcesContent":[".dashboard-datepicker {\n  width: 115px;\n  position: relative;\n}\n\n.filter-select {\n  padding: 10px;\n  width: inherit;\n  background-color: #fff;\n  font-size: 14px;\n  font-weight: 400;\n  color: #666;\n  -webkit-text-fill-color: #666;\n  opacity: 1;\n  border: 1px solid #e4e4e4;\n}\n\n.calendar-icon-box {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: inherit;\n  height: 100%;\n  cursor: pointer;\n}\n\n.icon-calendar {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  right: 10px;\n  width: 14px;\n  height: auto;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
