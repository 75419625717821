// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-users i::before {
  color: #b4addf;
}

.iframe-users i::before {
  color: #cbcad1;
}

.page-views i::before {
  color: #efe08f;
}

.iframe-views i::before {
  color: #8fb8ef;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/components/charts/chart-uu/chart-uu.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".page-users i::before {\n  color: #b4addf;\n}\n\n.iframe-users i::before {\n  color: #cbcad1;\n}\n\n.page-views i::before {\n  color: #efe08f;\n}\n\n.iframe-views i::before {\n  color: #8fb8ef;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
