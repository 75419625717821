import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { MapOptionsDto } from './dto/map-options.dto';

@Injectable({ providedIn: 'root' })
export class MapOptionsRepository {
  private readonly http = inject(HttpClient);

  fetch = (mapKey: string): Promise<MapOptionsDto> => {
    const apiUrl = `https://stroly.com/api/v1/r/mapbykey/${mapKey}/map-options`;
    return firstValueFrom(this.http.get<MapOptionsDto>(apiUrl));
  };
}
