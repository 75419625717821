import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  logout(): void {
    window.location.assign(`${environment.authApiHost}/logout`);
  }
}
