import { Injectable, inject } from '@angular/core';
import { DatareportRepository } from '../repositories/datareport.repository';
import { LatlngsDto } from '../repositories/dto/datareport.dto';

export type CentralData = LatlngsDto['data'][number];

export interface LatlngData {
  v4: {
    data: CentralData[];
  };
}

@Injectable({ providedIn: 'root' })
export class HeatmapLatlngDataUsecase {
  private readonly datareportRepository = inject(DatareportRepository);

  async fetchLatlngData(
    mapID: string,
    fromDateStr: string,
    toDateStr: string,
  ): Promise<LatlngData> {
    const latlngsDtos = await this.datareportRepository.fetchLatlngs(
      mapID,
      fromDateStr,
      toDateStr,
    );
    const latlngData = {
      v4: {
        data: this.filterData(
          latlngsDtos.flatMap((latlngsDto) => latlngsDto.data),
        ),
      },
    };
    return latlngData;
  }

  private filterData(data: CentralData[]): CentralData[] {
    return data.filter(
      (val) =>
        val.lat >= -90 && val.lat <= 90 && val.long >= -180 && val.long <= 180,
    );
  }
}
