import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  DateFilterFn,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  DASHBOARD_DATE_FORMATS,
  DashboardDateAdapter,
} from './dashboard-date-adapter';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

const browserLang =
  (window.navigator.languages && window.navigator.languages[0]) ||
  window.navigator.language;

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatFormFieldModule,
  ],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    {
      provide: MAT_DATE_LOCALE,
      useValue: browserLang.indexOf('ja') != -1 ? 'ja-JP' : 'en-US',
    },
    {
      provide: DateAdapter,
      useClass: DashboardDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: DASHBOARD_DATE_FORMATS,
    },
  ],
  selector: 'app-dashboard-datepicker',
  templateUrl: './dashboard-datepicker.component.html',
  styleUrls: ['./dashboard-datepicker.component.scss'],
})
export class DashboardDatepickerComponent {
  @Input()
  datepickerFilter!: DateFilterFn<Date | null>;
  @Input()
  form!: FormControl<Date>;
}
