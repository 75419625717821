import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DetailsPage } from './dashboard/ui/pages/details/details-page/details.page';
import { IndexPage } from './dashboard/ui/pages/index-page/index.page';

import { NotFoundPage } from './dashboard/ui/pages/not-found-page/not-found.page';
import { ComponentsListPage } from './dashboard/ui/pages-dev/dev/components-list/components-list.page';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  { path: '', component: IndexPage },
  {
    path: 'details/:mapID/',
    component: DetailsPage,
  },
  {
    path: 'details/:mapID',
    component: DetailsPage,
  },
  { path: '**', loadComponent: () => NotFoundPage },
];

// NOTE: dashboardリファクタ作業で利用します.
// 用途: コンポーネント分割する際に親のcssに依存しない書き方をするために, 作業場としてこのページを利用します. リファクタ作業が完了したら消します.
if (environment.envName === 'dev') {
  routes.unshift({
    path: 'dev/components-list',
    loadComponent: () => ComponentsListPage,
  });
}

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
