import dayjs from 'dayjs';

export function getStrLength(strSrc: string): number {
  let len = 0;
  strSrc = escape(strSrc);
  for (let i = 0; i < strSrc.length; i++, len++) {
    if (strSrc.charAt(i) == '%') {
      if (strSrc.charAt(++i) == 'u') {
        i += 3;
        len++;
      }
      i++;
    }
  }
  return len;
}

export function multiByteSubStr(str: string, length: number): string {
  let isSlice = false;

  while (getStrLength(str) > length) {
    str = str.slice(0, str.length - 1);
    isSlice = true;
  }

  if (isSlice) {
    str += '...';
  }

  return str;
}

/* グラフの日付整形 */
export function formatDateLabel(dateLabel: string[]): string[] {
  const ret: string[] = [];
  for (let i = 0; i < dateLabel.length; i++) {
    ret.push(dayjs(dateLabel[i]).format('MM/DD'));
  }
  return ret;
}

export function sumValues(data: number[]): number {
  return Object.values(data).reduce((a: number, x: number) => (a += x), 0);
}

export function sumCommaValues(data: { string: string }): number {
  return Object.values(data)
    .map((val) => Number(val.split(',')[1]))
    .reduce((a: number, x: number) => (a += x), 0);
}

export function isBussiness(url: string): boolean {
  if (typeof url !== 'string') {
    return false;
  }
  url =
    url.indexOf('mtest.stroly.com') !== -1 ? url.replace('mtest', 'm') : url;
  return url.indexOf('m.stroly.com') !== -1;
}
