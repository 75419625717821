import { MatDateFormats, NativeDateAdapter } from '@angular/material/core';
import dayjs from 'dayjs';

/** Dashboardで扱うDatepicker用のMatDateFormats */
export const DASHBOARD_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY/MM/DD',
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'M/YYYY',
    dateA11yLabel: 'YYYY/MM/DD',
    monthYearA11yLabel: 'M/YYYY',
  },
};

/**
 * Dashboardで扱うDatepicker用のDateAdapter.
 *
 * NOTE: @angular/material公式のdayjsのDateAdapterがないため必要最低限で自作した.
 * dayjsを一貫して利用するために, 公式のmomentやdate-fnsのDateAdapterを利用しない.
 */
export class DashboardDateAdapter extends NativeDateAdapter {
  override getDateNames(): string[] {
    // NOTE: localeがjaのときは `日` がついてしまうので除去している.
    return super.getDateNames().map((dateNames) => dateNames.replace('日', ''));
  }
  override parse(value: string | Date, parseFormat?: string): Date | null {
    const day = dayjs(value, parseFormat);
    return day.isValid() ? day.toDate() : null;
  }
  override format(date: Date, displayFormat: string): string {
    return dayjs(date).format(displayFormat);
  }
}
