// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  margin-top: 100px;
}

.referrer {
  min-height: 533px;
}

:host ::ng-deep .p-datatable tbody tr td:first-of-type {
  text-align: left;
}

:host ::ng-deep .p-datatable tbody tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 5px;
}

:host ::ng-deep .p-datatable tbody tr:last-of-type td:first-of-type {
  border-radius: 0;
}

:host ::ng-deep .referrer .p-datatable td:first-of-type {
  padding-left: 20px;
  text-align: left;
  width: 380px;
}

:host ::ng-deep .referrer .p-paginator .p-paginator-current {
  left: 50%;
}

.page-views {
  display: flex;
}

.all-inmap-table th:nth-of-type(n + 2),
.all-inmap-table td:nth-of-type(n + 2) {
  width: calc(1048px / 4);
}

.referrer-table th:nth-of-type(n + 2),
.referrer-table td:nth-of-type(n + 2) {
  width: calc(758px / 4);
}

.referrer-table th:nth-of-type(n + 2) > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.referrer-table th:nth-of-type(n + 2) > span > span {
  max-width: 7rem;
  white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/pages/details/details-page/detailed/detailed.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,uBAAuB;AACzB;;AAEA;;EAEE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,qBAAqB;AACvB","sourcesContent":["h2 {\n  margin-top: 100px;\n}\n\n.referrer {\n  min-height: 533px;\n}\n\n:host ::ng-deep .p-datatable tbody tr td:first-of-type {\n  text-align: left;\n}\n\n:host ::ng-deep .p-datatable tbody tr:last-of-type td:first-of-type {\n  border-bottom-left-radius: 5px;\n}\n\n:host ::ng-deep .p-datatable tbody tr:last-of-type td:first-of-type {\n  border-radius: 0;\n}\n\n:host ::ng-deep .referrer .p-datatable td:first-of-type {\n  padding-left: 20px;\n  text-align: left;\n  width: 380px;\n}\n\n:host ::ng-deep .referrer .p-paginator .p-paginator-current {\n  left: 50%;\n}\n\n.page-views {\n  display: flex;\n}\n\n.all-inmap-table th:nth-of-type(n + 2),\n.all-inmap-table td:nth-of-type(n + 2) {\n  width: calc(1048px / 4);\n}\n\n.referrer-table th:nth-of-type(n + 2),\n.referrer-table td:nth-of-type(n + 2) {\n  width: calc(758px / 4);\n}\n\n.referrer-table th:nth-of-type(n + 2) > span {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.referrer-table th:nth-of-type(n + 2) > span > span {\n  max-width: 7rem;\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
