// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
  position: relative;
  display: inline-block;
}

.detail {
  display: none;
  position: absolute;
  z-index: 1000;
  padding: 8px;
  width: 100%;
  box-sizing: content-box;
  font-size: 12px;
  word-break: break-all;
  color: #fff;
  border-radius: 5px;
  background: rgba(51, 51, 51, 0.8);
}

.tooltip:hover .detail {
  display: inline-block;
}

.top:hover .detail {
  top: -70px;
  left: -30px;
}

.right:hover .detail {
  top: 0px;
  left: 100%;
}

.bottom:hover .detail {
  top: 18px;
  left: 10px;
}

.left:hover .detail {
  top: 0px;
  left: -130px;
}
`, "",{"version":3,"sources":["webpack://./src/app/shared/ui/components/tooltip/tooltip.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,YAAY;EACZ,WAAW;EACX,uBAAuB;EACvB,eAAe;EACf,qBAAqB;EACrB,WAAW;EACX,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;EACV,WAAW;AACb;;AAEA;EACE,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,QAAQ;EACR,YAAY;AACd","sourcesContent":[".tooltip {\n  position: relative;\n  display: inline-block;\n}\n\n.detail {\n  display: none;\n  position: absolute;\n  z-index: 1000;\n  padding: 8px;\n  width: 100%;\n  box-sizing: content-box;\n  font-size: 12px;\n  word-break: break-all;\n  color: #fff;\n  border-radius: 5px;\n  background: rgba(51, 51, 51, 0.8);\n}\n\n.tooltip:hover .detail {\n  display: inline-block;\n}\n\n.top:hover .detail {\n  top: -70px;\n  left: -30px;\n}\n\n.right:hover .detail {\n  top: 0px;\n  left: 100%;\n}\n\n.bottom:hover .detail {\n  top: 18px;\n  left: 10px;\n}\n\n.left:hover .detail {\n  top: 0px;\n  left: -130px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
