import { Injectable, inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { DateFilterFn } from '@angular/material/datepicker';
import dayjs from 'dayjs';

export interface DateFilterFormType {
  toDate: FormControl<Date>;
  fromDate: FormControl<Date>;
}

@Injectable({ providedIn: 'root' })
export class DateFilterFormBuilder {
  private readonly fb = inject(FormBuilder);
  constructor() {}

  build = (
    fromDate: Date = this.fourWeeksDaysAgo(),
    toDate: Date = new Date(),
  ): DateFilterForm => {
    const form = this.fb.nonNullable.group(
      {
        fromDate: [fromDate],
        toDate: [toDate],
      },
      {
        validators: [DateFilterForm.searchPeriodValidator],
      },
    );
    return new DateFilterForm(form);
  };

  private fourWeeksDaysAgo = () => dayjs().add(-28, 'day').toDate();
}

export class DateFilterForm {
  private static readonly OneYearDays = 366;
  private static readonly MinDate = new Date('2019/01/01');
  constructor(private form: FormGroup<DateFilterFormType>) {}

  get fromDate(): Date {
    return this.form.controls.fromDate.getRawValue();
  }

  get toDate(): Date {
    return this.form.controls.toDate.getRawValue();
  }

  get fromDateControl(): FormControl<Date> {
    return this.form.controls.fromDate;
  }
  get toDateControl(): FormControl<Date> {
    return this.form.controls.toDate;
  }

  diffDays = (): number => {
    return dayjs(this.toDate).diff(this.fromDate, 'day') + 1;
  };

  isInvalid = (): boolean => this.form.invalid;

  // NOTE: matDatepickerFilterはカレンダーの表示する日付をdisabledにするだけでなく, formControlのvalidationとしても利用される.
  // dがnullになるのはMatDatepickerInputの初期化時の一瞬くらい.
  fromDateFilter: DateFilterFn<Date | null> = (d: Date | null): boolean => {
    const minDate = DateFilterForm.MinDate;
    return !!d && minDate <= d && d <= this.toDateControl.getRawValue();
  };

  toDateFilter: DateFilterFn<Date | null> = (d: Date | null): boolean => {
    const today = new Date();
    return !!d && this.fromDateControl.getRawValue() <= d && d < today;
  };

  /** 検索期間が広すぎないかの検証 */
  static searchPeriodValidator: ValidatorFn = ((
    form: FormGroup<DateFilterFormType>,
  ): ValidationErrors | null => {
    const fromDate = form.controls.fromDate.getRawValue();
    const toDate = form.controls.toDate.getRawValue();
    const diff = dayjs(toDate).diff(fromDate, 'day');

    return diff > this.OneYearDays ? { isInvalidSearchPeriod: true } : null;
  }) as ValidatorFn; // 実装時点でのAngularバージョンではValidatorFnの型合わせがうまくできていないのでasしている. 挙動に問題はない. https://github.com/angular/angular/issues/47911

  isInvalidSearchPeriod = (): boolean =>
    this.form.errors?.isInvalidSearchPeriod ?? false;
}
