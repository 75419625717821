import { Injectable, inject } from '@angular/core';
import { Firestore, collectionGroup, getDocs } from '@angular/fire/firestore';

import { RankData } from '../ui/pages/index-page/index.page';
import dayjs from 'dayjs';
import { Observable, firstValueFrom } from 'rxjs';
import {
  DocumentData,
  DocumentReference,
  QuerySnapshot,
  doc,
  onSnapshot,
  query,
  where,
} from '@angular/fire/firestore';
import { DatareportRepository } from '../repositories/datareport.repository';
import { DateUtil } from 'src/app/shared/utils/date-util';

interface firestoreData {
  pv: [];
  pv_iframe: [];
  uu: [];
  uu_iframe: [];
}

interface FsV1Data {
  allarea: {
    pv?: Record<string, number>;
    pv_iframe?: Record<string, number>;
    uu?: Record<string, number>;
    uu_iframe?: Record<string, number>;
  };
  inmap?: {
    pv?: Record<string, number>;
    pv_iframe?: Record<string, number>;
    uu?: Record<string, number>;
    uu_iframe?: Record<string, number>;
  };
}

export const firestoreDataProp = ['pv', 'pv_iframe', 'uu', 'uu_iframe'];

@Injectable({
  providedIn: 'root',
})
export class FireStoreService {
  private readonly firestore: Firestore = inject(Firestore);
  private readonly datareportRepository = inject(DatareportRepository);

  constructor() {}

  getFirestore(): Firestore {
    return this.firestore;
  }

  // Ver.0 用 (廃止予定)
  // Deprecated. DateUtil.rangeWithFormatを利用してください.
  // makeDateLabel(fromDate: string, toDate: string): string[] {
  //   const dateArray: string[] = [];
  //   let currentDate = dayjs(fromDate);
  //   const stopDate = dayjs(toDate);
  //   while (currentDate <= stopDate) {
  //     dateArray.push(dayjs(currentDate).format('YYYY-MM-DD'));
  //     currentDate = dayjs(currentDate).add(1, 'day');
  //   }
  //   return dateArray;
  // }

  makeDataWithDateLabel(
    dateLabel: string[],
    data: Record<string, number>,
  ): number[] {
    const newDataValue: number[] = [];
    // console.log(data);
    const labelKeys = Object.keys(data);
    // console.log(labelKeys, data);
    for (const date of dateLabel) {
      newDataValue.push(labelKeys.indexOf(date) >= 0 ? data[date] : 0);
    }
    return newDataValue;
  }

  makeDataWithDateLabelForDistance(
    dateLabel: string[],
    //data: Record<string, Record<string, number>>,
    data: Record<string, [number, number]>,
  ): number[][] {
    const newDataValue1: number[] = [];
    const newDataValue2: number[] = [];
    const labelKeys = Object.getOwnPropertyNames(data);
    for (const date of dateLabel) {
      newDataValue1.push(labelKeys.indexOf(date) >= 0 ? data[date][0] : 0);
      newDataValue2.push(labelKeys.indexOf(date) >= 0 ? data[date][1] : 0);
    }
    return [newDataValue1, newDataValue2];
  }

  makeDataWithDateLabelForTimeflag(
    dateLabel: string[],
    data: Record<string, number[]>,
  ): number[][] {
    const newDataValue: number[][] = [[], [], [], []];
    const labelKeys = Object.getOwnPropertyNames(data);
    for (const date of dateLabel) {
      for (let idx = 0; idx < 4; idx++) {
        newDataValue[idx].push(
          labelKeys.indexOf(date) >= 0 ? data[date][idx] : 0,
        );
      }
    }

    return newDataValue;
  }

  // Ver.0 用 (廃止予定)
  // reshapeDataPropDate(origData: any) {
  //   const newData: Record<string, Record<string, number>> = {};
  //   for (const date of Object.keys(origData)) {
  //     for (const prop of Object.keys(origData[date])) {
  //       if (!newData[prop]) {
  //         newData[prop] = {};
  //         newData[prop][date] = origData[date][prop];
  //       } else {
  //         newData[prop][date] = origData[date][prop];
  //       }
  //     }
  //   }
  //   return newData;
  // }

  // Ver.0 用 (廃止予定)
  // reshapeDataPropDateSum(origData: any) {
  //   const newData = {};
  //   for (const date of Object.keys(origData)) {
  //     for (const prop of Object.keys(origData[date])) {
  //       if (!newData[prop]) {
  //         newData[prop] = {};
  //       }
  //       if (
  //         origData[date][prop].length == 1 &&
  //         typeof origData[date][prop][0] == 'number'
  //       ) {
  //         newData[prop][date] = origData[date][prop][0];
  //       } else {
  //         newData[prop][date] = this.sumCommaValues(origData[date][prop]);
  //       }
  //     }
  //   }
  //   return newData;
  // }

  // Ver.0 用 (廃止予定)
  // buildChartAndTableData(
  //   filteredData: Record<string, Record<string, number>>,
  // ): [Record<string, number>, Record<string, Record<string, number>>] {
  //   // use in pie chart and table data
  //   const newFilteredData = {};
  //   Object.keys(filteredData).map((val) => {
  //     newFilteredData[val] = filteredData[val]['pv'];
  //   });

  //   const tableData = this.reshapeDataPropDate(filteredData);

  //   const aggData = this.aggregateFSData(newFilteredData);
  //   tableData['pv'] = this.aggregateFSData(tableData['pv']);
  //   tableData['uu'] = this.aggregateFSData(tableData['uu']);
  //   tableData['pv_iframe'] = this.aggregateFSData(tableData['pv_iframe']);
  //   tableData['uu_iframe'] = this.aggregateFSData(tableData['uu_iframe']);
  //   return [aggData, tableData];
  // }

  // Ver.0 用 (廃止予定)
  // makeDocumentStream(dataType: string, mapID: string): Observable<RankData> {
  //   const itemDocRef: DocumentReference<RankData> = doc(
  //     this.firestore,
  //     `${dataType}/${mapID}`,
  //   ) as DocumentReference<RankData>;
  //   return new Observable((observer) => {
  //     return onSnapshot(
  //       itemDocRef,
  //       (snapshot) => observer.next(snapshot.data()),
  //       (error) => observer.error(error.message),
  //     );
  //   });
  // }

  // Ver.0 用 (廃止予定)
  // dataToCount(
  //   dataObj: Record<string, Record<string, string[]>>,
  // ): Record<string, number> {
  //   const aggObj = {};
  //   for (const key in dataObj) {
  //     const dataList = dataObj[key];
  //     let count = 0;

  //     if (typeof dataList === 'object') {
  //       for (const data of Object.keys(dataList)) {
  //         const aggKey = data.split(',')[0];
  //         if (aggKey === '0' || aggKey === 'nan' || aggKey === 'None') {
  //           continue;
  //         }

  //         const num = Number(data.split(',')[1]);
  //         count += num;
  //       }
  //     }
  //     aggObj[key] = count;
  //   }
  //   return aggObj;
  // }

  // Ver.0 用 (廃止予定)
  // filterDataLandmark(
  //   data: Record<string, unknown>,
  //   fromDate: string,
  //   toDate: string,
  // ): Record<string, Record<string, string[]>> {
  //   if (typeof data === 'undefined') {
  //     // console.log('data naiyan~');
  //     return data;
  //   }

  //   const filteredData = {};
  //   fromDate = this.getWithHyphenDate(fromDate);
  //   toDate = this.getWithHyphenDate(toDate);
  //   Object.getOwnPropertyNames(data)
  //     .filter((key) => key >= fromDate && key <= toDate)
  //     .map((key) => {
  //       filteredData[key] = data[key];
  //     });
  //   return filteredData;
  // }

  // Ver.0 用 (廃止予定)
  // aggregateFSData(dataObj: Record<string, string[]>): Record<string, number> {
  //   const aggObj = {};
  //   for (const key in dataObj) {
  //     const dataList = dataObj[key];
  //     if (typeof dataList === 'object') {
  //       for (const data of dataList) {
  //         // 最後の要素以外を抽出(URLにカンマが含まれるものの対策)
  //         const aggKey = data.split(',').slice(0, -1).join(',');
  //         if (
  //           // 開発利用の参照元を出さないようにする
  //           // IPアドレスの参照元は除外
  //           aggKey.match(
  //             /^https?:\/\/(localhost|stroly-viewer-preview|((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9]))/i,
  //           ) ||
  //           aggKey === '0' ||
  //           aggKey === 'nan' ||
  //           aggKey === 'None'
  //         ) {
  //           continue;
  //         }

  //         // 最後の要素を抽出(URLにカンマが含まれるものの対策)
  //         const num = Number(data.split(',').reverse()[0]);
  //         if (typeof aggObj[aggKey] !== 'undefined') {
  //           aggObj[aggKey] += num;
  //         } else {
  //           aggObj[aggKey] = num;
  //         }
  //       }
  //     }
  //   }
  //   return aggObj;
  // }

  // Ver.0 用 (廃止予定)
  // filterData(
  //   data: Record<string, unknown>,
  //   fromDate: string,
  //   toDate: string,
  // ): Record<string, Record<string, number>> {
  //   if (typeof data === 'undefined') {
  //     // console.log('data naiyan~');
  //     return data;
  //   }

  //   const filteredData = {};
  //   fromDate = this.getWithHyphenDate(fromDate);
  //   toDate = this.getWithHyphenDate(toDate);
  //   Object.getOwnPropertyNames(data)
  //     .filter((key) => key >= fromDate && key <= toDate)
  //     .map((key) => {
  //       filteredData[key] = data[key];
  //     });
  //   return filteredData;
  // }

  getWithHyphenDate(date: string): string {
    if (date.length === 8) {
      return `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`;
    } else {
      return date;
    }
  }

  sumValues(data: number[]): number {
    return Object.values(data).reduce((a: number, x: number) => (a += x), 0);
  }

  // Ver.0 用 (廃止予定)
  // util.tsのsumCommaValuesを利用してください.
  // sumCommaValues(data: { string: string }): number {
  //   return Object.values(data)
  //     .map((val) => Number(val.split(',')[1]))
  //     .reduce((a: number, x: number) => (a += x), 0);
  // }

  sortByValue(data: Record<string, number>): Map<string, number> {
    return new Map(
      Object.entries(data).sort(function (a, b) {
        // ソート処理
        const genreA = a[1];
        const genreB = b[1];

        let comparison = 0;
        if (genreA > genreB) {
          comparison = 1;
        } else if (genreA < genreB) {
          comparison = -1;
        }
        return comparison * -1;
      }),
    );
  }

  // TODO: こちらのメソッドの単体テストを書く
  // TODO: 処理を分割してください.
  async getV1Data(
    collection: string,
    mapID: string,
    fromDate: string,
    toDate: string,
    timezone?: string,
    isDaily = false,
  ): Promise<any> {
    const dates = DateUtil.dateRangeWithFormat(fromDate, toDate);
    const res = await this.datareportRepository.fetchByDates(
      collection,
      mapID,
      fromDate,
      toDate,
    );

    // dailyの場合は日付ごとに集計する. そうでない場合はキーごとに集計する.
    const sumFunc = isDaily ? this.sumV1DataByDate : this.sumV1Data;

    const convFunc: Function = this.convV1Data;
    const ret = convFunc(res, sumFunc);
    // dailyの場合は日付のrange内でもキーにない日付を0で埋める
    if (isDaily) {
      for (const date of dates) {
        if (ret.allarea && !(date in ret.allarea.pv)) {
          ret.allarea.pv[date] =
            ret.allarea.pv_iframe[date] =
            ret.allarea.uu[date] =
            ret.allarea.uu_iframe[date] =
              0;
        }
        if (ret.inmap && !(date in ret.inmap.pv)) {
          ret.inmap.pv[date] =
            ret.inmap.pv_iframe[date] =
            ret.inmap.uu[date] =
            ret.inmap.uu_iframe[date] =
              0;
        }
      }
    }
    return ret;
  }

  convV1Data(res: DocumentData[], sumFunc: Function): FsV1Data {
    const newData = {
      allarea: {
        pv: {},
        pv_iframe: {},
        uu: {},
        uu_iframe: {},
      },
      inmap: {
        pv: {},
        pv_iframe: {},
        uu: {},
        uu_iframe: {},
      },
    };

    for (const d of res) {
      if (d.allarea) {
        const allarea = d.allarea;
        if (allarea.pv.all) {
          sumFunc(allarea.pv.all, newData.allarea.pv, d);
        }
        if (allarea.pv.iframe) {
          sumFunc(allarea.pv.iframe, newData.allarea.pv_iframe, d);
        }
        if (allarea.uu.all) {
          sumFunc(allarea.uu.all, newData.allarea.uu, d);
        }
        if (allarea.uu.iframe) {
          sumFunc(allarea.uu.iframe, newData.allarea.uu_iframe, d);
        }
      }
      if (d.inmap) {
        const inmap = d.inmap;
        if (inmap.pv.all) {
          sumFunc(inmap.pv.all, newData.inmap.pv, d);
        }
        if (inmap.pv.iframe) {
          sumFunc(inmap.pv.iframe, newData.inmap.pv_iframe, d);
        }
        if (inmap.uu.all) {
          sumFunc(inmap.uu.all, newData.inmap.uu, d);
        }
        if (inmap.uu.iframe) {
          sumFunc(inmap.uu.iframe, newData.inmap.uu_iframe, d);
        }
      }
    }
    return newData;
  }

  sumV1Data(
    kvs: Record<string, number>,
    all: Record<string, number>,
    props: { date: string; map_id: string },
  ): Record<string, number> {
    for (const key in kvs) {
      if (
        key.match(
          /^https?:\/\/(localhost|stroly-viewer-preview|((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9]))/i,
        ) ||
        key === '0' ||
        key === 'nan' ||
        key === 'None'
      ) {
        continue;
      }
      all[key] = +all[key] ? all[key] + kvs[key] : kvs[key];
    }
    return all;
  }

  sumV1DataByDate(
    kvs: Record<string, number> | number,
    all: Record<string, number>,
    props: { date: string; map_id: string },
  ): Record<string, number> {
    const date = props.date;
    if (typeof kvs === 'number') {
      if (date) {
        all[date] = +all[date] ? all[date] + kvs : kvs;
      }
    } else {
      for (const key in kvs) {
        if (
          key.match(
            /^https?:\/\/(localhost|stroly-viewer-preview|((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9]?[0-9]))/i,
          ) ||
          key === '0' ||
          key === 'nan' ||
          key === 'None'
        ) {
          continue;
        }
        if (date) {
          all[date] = +all[date] ? all[date] + kvs[key] : kvs[key];
        }
      }
    }
    return all;
  }
}
