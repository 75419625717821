import { Injectable } from '@angular/core';
import { MapDetailDto } from '../repositories/dto/map-detail.dto';

@Injectable({
  providedIn: 'root',
})
export class LangService {
  getMapLangs = (
    mapLangs: MapDetailDto['map_image']['langs'],
    browserLang: string,
  ): MapDetailDto['map_image']['langs'][number] | undefined => {
    if (mapLangs.length === 0) {
      return undefined;
    }

    // ブラウザ言語設定とサービス内で使用する言語設定で差異があるものを変換する
    const convertedBrowserLang =
      {
        'zh-CN': 'zh-Hans',
        'zh-TW': 'zh-Hant',
      }[browserLang] ?? browserLang;

    const mapLangToApply =
      mapLangs.find(({ lang }) => lang === convertedBrowserLang) ||
      mapLangs.find(({ lang }) => lang === 'en') || // Viewerと同じく英語を優先的に表示させる仕様
      mapLangs[0];

    return mapLangToApply;
  };
}
