import { inject, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import dayjs from 'dayjs';
import { UserRepository } from '../repositories/user.repository';

// TODO: 不要なフィールドを削除して domains に移動してください.
export interface AuthedUserProfile {
  avatar: string | null;
  background_image: string | null;
  description: string;
  id: string;
  is_internal: boolean;
  user: string;
  extended_userprofile: {
    email: string;
    id: number;
    last_login: string;
    name: string;
    username: string;
    stripe_customer: unknown[];
    active_stripe_customer_id: string | null;
    plan_subscriptions: [{ period_end: string }] | [];
  };
  isProPlanUser: boolean;
  isStrolyStaff: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class AuthedUserProfileService {
  private readonly userRepository = inject(UserRepository);
  // TODO: BehaviorSubject (storeパターン)に変更してください.
  private authedUserProfile: AuthedUserProfile;

  constructor() {
    this.authedUserProfile = {
      avatar: '',
      background_image: '',
      description: '',
      id: '',
      is_internal: false,
      user: '',
      extended_userprofile: {
        email: '',
        id: 0,
        last_login: '',
        name: '',
        username: '',
        stripe_customer: [],
        active_stripe_customer_id: '',
        plan_subscriptions: [],
      },
      isProPlanUser: false,
      isStrolyStaff: false,
    };
  }

  async initAuthedUserProfile(): Promise<void> {
    if (this.authedUserProfile.id) {
      return;
    }

    // FIXME: 不適切なキャストです. 認証周りのリファクタの際に修正してください. 現状はエラーの時は `{detail: string}` を保持している.
    this.authedUserProfile = (await this.userRepository
      .fetchAuthedUserProfile()
      .catch((e) => e.error)) as AuthedUserProfile;
    if (!this.authedUserProfile?.id) {
      return;
    }

    this.authedUserProfile.isStrolyStaff = this.checkStorlyStaff();
    this.authedUserProfile.isProPlanUser = this.checkProPlanUser();
    if (
      !this.authedUserProfile.avatar ||
      this.authedUserProfile.avatar.length === 0
    ) {
      this.authedUserProfile.avatar =
        environment.authApiHost + '/mypage/v2/assets/images/silhouette.svg';
    } else if (this.authedUserProfile.avatar.indexOf('https://') === -1) {
      this.authedUserProfile.avatar =
        environment.authApiHost + this.authedUserProfile.avatar;
    }
  }

  private checkStorlyStaff(): boolean {
    return (
      this.authedUserProfile.extended_userprofile.email.indexOf(
        '@stroly.jp',
      ) !== -1 ||
      // `is_internal`フラグにより社内メンバーと同一権限(全地図閲覧可能)にする
      this.authedUserProfile.is_internal === true
    );
  }

  private checkProPlanUser(): boolean {
    const now = dayjs();
    const planSubscriptions =
      this.authedUserProfile.extended_userprofile.plan_subscriptions;

    // planSubscriptionsがないユーザーは無料ユーザー
    if (planSubscriptions.length === 0) {
      return false;
    }
    if (!planSubscriptions[0].period_end) {
      // NOTE: パターンとしてあるかわからないが、planSubscriptionsがあってperiod_endがない場合無料ユーザー
      console.log('no period_end');
      return false;
    }

    // period_endが現在より過去なら無料ユーザー
    if (dayjs(planSubscriptions[0].period_end) < now) {
      return false;
    }

    return true;
  }

  getAuthedUserProfile(): AuthedUserProfile {
    return this.authedUserProfile;
  }
}
