import { environment } from 'src/environments/environment';

export enum LayerId {
  mapboxStreets = 'mapbox.streets',
  mapboxSatellite = 'mapbox.satellite',
}

export const LAYER_LABEL_JP = {
  mapboxStreets: 'OSM（道路地図）',
  eKokudoStandard: '電子国土地図',
  mapboxSatellite: '衛星地図',
};

export const LAYER_LABEL_EN = {
  mapboxStreets: 'OSM (Streets)',
  eKokudoStandard: 'Electronic Map of Japan',
  mapboxSatellite: 'Satellite',
};

/** Mapboxのアトリビューション */
// mapbox の Attribution について
// - https://docs.mapbox.com/help/getting-started/attribution/
// OpenStreetMap の Attribution Text について
// - https://wiki.osmfoundation.org/wiki/Licence/Attribution_Guidelines#Attribution_text
export const MAPBOX_ATTRIBUTION = `
  © <a href="https://www.mapbox.com/about/maps/" target="_blank" rel="noopener noreferrer">Mapbox</a>,
  <a href="https://www.mapbox.com/map-feedback/" target="_blank" rel="noopener noreferrer">Improve this map</a>,
  © <a href="http://www.openstreetmap.org/copyright" target="_blank" rel="noopener noreferrer">OpenStreetMap</a>
`;

/**
 * Mapbox のアカウント名とAPIのURL
 * mapboxマップのスタイルを編集する場合は以下のmapbox studioから行う（ログイン必須）。
 * IDもmapbox studioから取得できる。
 */
const MAPBOX_ACCOUNT = 'creative';
const MAPBOX_API_URL = 'https://api.mapbox.com/styles/v1';

export const MAPBOX_ACCESS_TOKEN = environment.mapboxAccessToken;

/**
 * Mapboxの道路地図(Street Japan)のスタイルID
 * https://studio.mapbox.com/styles/creative/clukm1ndx02wg01oi0dtugi28/edit/
 * https://studio.mapbox.com/styles/creative/clutoaotv003901omaf20hwfb/edit/
 */
const MAPBOX_STREET_STYLE_ID_JP = 'clukm1ndx02wg01oi0dtugi28';
const MAPBOX_STREET_STYLE_ID_EN = 'clutoaotv003901omaf20hwfb';
export const STREET_TILE_JP =
  `${MAPBOX_API_URL}/${MAPBOX_ACCOUNT}/${MAPBOX_STREET_STYLE_ID_JP}/tiles/512/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}` as const;
export const STREET_TILE_EN =
  `${MAPBOX_API_URL}/${MAPBOX_ACCOUNT}/${MAPBOX_STREET_STYLE_ID_EN}/tiles/512/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}` as const;

/** Mapboxの衛星地図のタイルURL */
export const SATELLITE_TILE =
  `${MAPBOX_API_URL}/mapbox/satellite-v9/tiles/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}` as const;

/** 電子国土地図のタイルURL */
export const EKOKUDO_PALE_TILE =
  'http://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png';

/** 国土地理院のアトリビューション */
// https://maps.gsi.go.jp/development/ichiran.html
export const EKOKUDO_ATTRIBUTION = `<a href="https://maps.gsi.go.jp/development/ichiran.html" target="_blank" rel="noopener">国土地理院</a>`;

export enum LandmarkDisp {
  Full = 'full',
  Pin = 'pin',
  None = 'none',
}

export const LANDMARK_ICON = {
  name: 'スポット',
  icon: {
    href: 'https://stroly.com/static/viewer/images/map_pin-unselected.png',
    width: 35,
    height: 37,
    hotSpotPxX: 17.5,
    hotSpotPxY: 37,
  },
  selectedIcon: {
    href: 'https://stroly.com/static/viewer/images/map_pin-selected.png',
    width: 35,
    height: 37,
    hotSpotPxX: 17.5,
    hotSpotPxY: 37,
  },
};

export const ILLUSTMAP_ROOT_URL = `https://ods3.illustmap.org`;

export const DEFAULT_HEATMAP_RADIUS = 10;

export const ADJUSTED_HEATMAP_RADIUS = 15;

export const LIMIT_DISTANCE = 100000;
