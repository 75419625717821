// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-col {
  position: relative;
  width: 110px;
  height: 100%;
  text-align: center;
}

.nav {
  margin: 0;
  padding: 0;
  position: sticky;
  top: 0;
  width: 110px;
  list-style: none;
  font-size: 12px;
}

.nav a {
  padding: 6px 0;
  display: block;
  text-decoration: none;
  font-weight: 500;
  color: #999;
}

.nav svg {
  display: block;
  margin: 15px auto;
  width: 30px;
  height: 30px;
}

.nav li {
  border-left: 3px solid #f0f2f8;
}

.nav li svg path,
.nav li svg rect {
  fill: #c3c7e4;
}

.nav li.on,
.nav li:hover {
  border-left-color: #66c;
}

.nav li.on svg path,
.nav li.on svg rect {
  fill: #6471b4;
}

.nav li:hover svg path,
.nav li:hover svg rect {
  fill: #6471b4;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/components/side-col/side-col.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,MAAM;EACN,YAAY;EACZ,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,qBAAqB;EACrB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,8BAA8B;AAChC;;AAEA;;EAEE,aAAa;AACf;;AAEA;;EAEE,uBAAuB;AACzB;;AAEA;;EAEE,aAAa;AACf;;AAEA;;EAEE,aAAa;AACf","sourcesContent":[".side-col {\n  position: relative;\n  width: 110px;\n  height: 100%;\n  text-align: center;\n}\n\n.nav {\n  margin: 0;\n  padding: 0;\n  position: sticky;\n  top: 0;\n  width: 110px;\n  list-style: none;\n  font-size: 12px;\n}\n\n.nav a {\n  padding: 6px 0;\n  display: block;\n  text-decoration: none;\n  font-weight: 500;\n  color: #999;\n}\n\n.nav svg {\n  display: block;\n  margin: 15px auto;\n  width: 30px;\n  height: 30px;\n}\n\n.nav li {\n  border-left: 3px solid #f0f2f8;\n}\n\n.nav li svg path,\n.nav li svg rect {\n  fill: #c3c7e4;\n}\n\n.nav li.on,\n.nav li:hover {\n  border-left-color: #66c;\n}\n\n.nav li.on svg path,\n.nav li.on svg rect {\n  fill: #6471b4;\n}\n\n.nav li:hover svg path,\n.nav li:hover svg rect {\n  fill: #6471b4;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
