import * as Constants from '../../ui/pages/details/details-page/behavioral/constants';
import { L } from 'stroly-js';

import { LatLngHeatmapData, LatLngHeatLayer } from './latlng-heat-layer';
import { LandmarkCategories } from '../../ui/pages/details/details-page/behavioral/models';
import { LandmarkMarkers } from './landmark-markers';

export class StandardMap {
  private readonly heatLayer = new LatLngHeatLayer();
  private landmarkMarkers!: LandmarkMarkers;
  private innerStandardMap!: L.Map;

  init(
    element: HTMLElement,
    landmarkCategories: LandmarkCategories[],
    data: LatLngHeatmapData,
    landmarkDisp: Constants.LandmarkDisp,
  ) {
    if (this.innerStandardMap) {
      return;
    }

    this.innerStandardMap = L.map(element, {
      scrollWheelZoom: false,
      doubleClickZoom: true,
      rotateControl: false,
      rotate: false,
      touchRotate: false,
    } as L.MapOptions & { rotate: boolean; touchRotate: boolean });

    const tileLayers = this.createMapLayers();
    this.innerStandardMap.addLayer(Object.values(tileLayers)[0]);
    L.control
      .layers(tileLayers, {}, { position: 'bottomleft' })
      .addTo(this.innerStandardMap);

    this.refreshHeatLayer(data);
    this.innerStandardMap.addLayer(this.heatLayer.layer);

    if (!landmarkCategories.length) {
      return;
    }
    this.landmarkMarkers = new LandmarkMarkers(landmarkCategories, 'Standard');
    this.landmarkMarkers.setLandmarkMarkers(landmarkDisp);
    this.innerStandardMap.addLayer(this.landmarkMarkers.layer);

    const scaledBounds = this.scaleBounds(
      this.landmarkMarkers.layer.getBounds(),
      1.5,
    );
    this.innerStandardMap.fitBounds(
      L.latLngBounds(scaledBounds.getSouthWest(), scaledBounds.getNorthEast()),
    );
    this.innerDefaultBoundsDistance = scaledBounds
      .getSouthWest()
      .distanceTo(scaledBounds.getNorthEast());
  }

  private innerDefaultBoundsDistance!: number;
  get defaultBoundsDistance() {
    return this.innerDefaultBoundsDistance ?? 0;
  }

  /**
   * 各種TileLayerを作成する
   */
  private createMapLayers() {
    const isJapanese = window.navigator.language.includes('ja');
    // MapboxStreets
    const mapboxStreetsTileUrl = isJapanese
      ? Constants.STREET_TILE_JP
      : Constants.STREET_TILE_EN;
    const mapboxStreetsLayer = L.tileLayer(mapboxStreetsTileUrl, {
      attribution: Constants.MAPBOX_ATTRIBUTION,
      id: Constants.LayerId.mapboxStreets,
      accessToken: Constants.MAPBOX_ACCESS_TOKEN,
    });

    // 国土地理院
    const eKokudoStandardLayer = L.tileLayer(Constants.EKOKUDO_PALE_TILE, {
      attribution: Constants.EKOKUDO_ATTRIBUTION,
    });

    // Mapbox 衛星画像
    const satelliteLayer = L.tileLayer(Constants.SATELLITE_TILE, {
      attribution: Constants.MAPBOX_ATTRIBUTION,
      id: Constants.LayerId.mapboxSatellite,
      accessToken: Constants.MAPBOX_ACCESS_TOKEN,
    });

    const layerLabel = isJapanese
      ? Constants.LAYER_LABEL_JP
      : Constants.LAYER_LABEL_EN;
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      [layerLabel.mapboxStreets]: mapboxStreetsLayer,
      [layerLabel.eKokudoStandard]: eKokudoStandardLayer,
      [layerLabel.mapboxSatellite]: satelliteLayer,
      /* eslint-enable @typescript-eslint/naming-convention */
    } as const;
  }

  private scaleBounds(
    bounds: L.LatLngBounds,
    scaleFactor: number,
  ): L.LatLngBounds {
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();

    const newLatDiff =
      ((northEast.lat - southWest.lat) * (scaleFactor - 1)) / 2;
    const newLngDiff =
      ((northEast.lng - southWest.lng) * (scaleFactor - 1)) / 2;

    const newNorthEast = new L.LatLng(
      northEast.lat + newLatDiff,
      northEast.lng + newLngDiff,
    );
    const newSouthWest = new L.LatLng(
      southWest.lat - newLatDiff,
      southWest.lng - newLngDiff,
    );

    return new L.LatLngBounds(newSouthWest, newNorthEast);
  }

  refreshHeatLayer(data: LatLngHeatmapData) {
    this.heatLayer.refreshHeatLayer(data);
  }

  refreshLandmarkMarkers(landmarkDisp: Constants.LandmarkDisp) {
    this.landmarkMarkers.setLandmarkMarkers(landmarkDisp);
  }

  get hasHeatmapData() {
    return this.heatLayer.hasHeatmapData;
  }

  get center() {
    return this.innerStandardMap?.getCenter();
  }

  resize(center: L.LatLngExpression) {
    this.innerStandardMap.invalidateSize();
    this.innerStandardMap.setView(center, this.innerStandardMap.getZoom());
  }
}
