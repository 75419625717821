// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-img {
  height: 80px;
  width: 80px;
}

@media screen and (max-width: 414px) {
  .grid-img {
    height: 70px;
    width: 70px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/ui/components/map-thumbnail/map-thumbnail.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE;IACE,YAAY;IACZ,WAAW;EACb;AACF","sourcesContent":[".grid-img {\n  height: 80px;\n  width: 80px;\n}\n\n@media screen and (max-width: 414px) {\n  .grid-img {\n    height: 70px;\n    width: 70px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
