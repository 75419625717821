import { NgChartsModule } from 'ng2-charts';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { TableModule } from 'primeng/table';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule } from '@angular/core';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DecimalPipe } from '@angular/common';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChartDeviceComponent } from './dashboard/ui/components/charts/chart-device/chart-device.component';
import { ChartLangComponent } from './dashboard/ui/components/charts/chart-lang/chart-lang.component';
import { ChartUuComponent } from './dashboard/ui/components/charts/chart-uu/chart-uu.component';
import { DetailsPage } from './dashboard/ui/pages/details/details-page/details.page';
import { FunctionApiService } from './dashboard/services/function-api.service';
import { ToolbarComponent } from './dashboard/ui/components/toolbar/toolbar.component';
import { MapRefComponent } from './dashboard/ui/pages/details/details-page/behavioral/map-ref/map-ref.component';
import { MapThumbnailComponent } from './dashboard/ui/components/map-thumbnail/map-thumbnail.component';
import { SideColComponent } from './dashboard/ui/components/side-col/side-col.component';
import { ChartDistanceComponent } from './dashboard/ui/components/charts/chart-distance/chart-distance.component';
import { LandmarkRankingComponent } from './dashboard/ui/pages/details/details-page/detailed/landmark-ranking/landmark-ranking.component';
import { WeeklyComponent } from './dashboard/ui/pages/details/details-page/weekly/weekly.component';
import { DetailedComponent } from './dashboard/ui/pages/details/details-page/detailed/detailed.component';
import { UserComponent } from './dashboard/ui/pages/details/details-page/user/user.component';
import { IndexPage } from './dashboard/ui/pages/index-page/index.page';
import { NoMapComponent } from './dashboard/ui/pages/index-page/no-map/no-map.component';
import { DisplayDatePipe } from './shared/pipes/display-date.pipe';
import { ChartLandmarkComponent } from './dashboard/ui/components/charts/chart-landmark/chart-landmark.component';
import { DataCompilationComponent } from './dashboard/ui/pages/index-page/data-compilation/data-compilation.component';

import { SubstringPipe } from './shared/pipes/substr.pipe';
import { RoundPipe } from './shared/pipes/round.pipe';
import { ChartTimeflagComponent } from './dashboard/ui/components/charts/chart-timeflag/chart-timeflag.component';
import { ChartOsComponent } from './dashboard/ui/components/charts/chart-os/chart-os.component';
import { ChartUserAreaComponent } from './dashboard/ui/components/charts/chart-user-area/chart-user-area.component';
import { IrregularDataComponent } from './dashboard/ui/components/irregular-data/irregular-data.component';
import { TooltipComponent } from './shared/ui/components/tooltip/tooltip.component';
import { UserDataTableComponent } from './dashboard/ui/pages/details/details-page/user/user-data-table/user-data-table.component';
import { FailedGetMapDataComponent } from './dashboard/ui/pages/details/details-page/failed-get-map-data/failed-get-map-data.component';
import { DateFilterComponent } from './dashboard/ui/pages/details/details-page/date-filter/date-filter.component';
import { DashboardDatepickerComponent } from './dashboard/ui/components/dashboard-datepicker/dashboard-datepicker.component';
import { FireStoreService } from './dashboard/services/fire-store.service';
import { LoadingComponent } from './shared/ui/components/loading/loading.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { InputRangeComponent } from './shared/ui/components/input-range/input-range.component';
import { UserMovementComponent } from './dashboard/ui/pages/details/details-page/behavioral/user-movement/user-movement.component';
import { BehavioralComponent } from './dashboard/ui/pages/details/details-page/behavioral/behavioral.component';
import { HeatmapViewComponent } from './dashboard/ui/pages/details/details-page/behavioral/heatmap-view/heatmap-view.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MapRefComponent,
    ToolbarComponent,
    ChartUuComponent,
    ChartLangComponent,
    DetailsPage,
    IndexPage,
    ChartDeviceComponent,
    MapThumbnailComponent,
    SideColComponent,
    ChartDistanceComponent,
    LandmarkRankingComponent,
    WeeklyComponent,
    DetailedComponent,
    BehavioralComponent,
    UserMovementComponent,
    UserComponent,
    NoMapComponent,
    DataCompilationComponent,
    SubstringPipe,
    RoundPipe,
    DisplayDatePipe,
    ChartLandmarkComponent,
    ChartTimeflagComponent,
    ChartOsComponent,
    ChartUserAreaComponent,
    IrregularDataComponent,
    TooltipComponent,
    UserDataTableComponent,
    FailedGetMapDataComponent,
    LoadingComponent,
    HeatmapViewComponent,
  ],
  providers: [FunctionApiService, FireStoreService, DecimalPipe],
  bootstrap: [AppComponent],
  imports: [
    DateFilterComponent,
    InputRangeComponent,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    // Papa,
    NgxPageScrollCoreModule.forRoot({
      scrollOffset: 300,
    }),
    NgxPageScrollModule,
    NgChartsModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    MatProgressSpinnerModule,
    OverlayModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTooltipModule,
    HttpClientModule,
    TableModule,
    ProgressSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    DashboardDatepickerComponent,
    MatSnackBarModule,
  ],
})
export class AppModule {}
