/**
 * 与えられたcolorの彩度をchangeVal分変更します
 * @param color 彩度を変更したいRGBカラーコード
 * @param changeVal 変更後の彩度(相対指定) 0 〜 2まで小数点可。 ex) 20%彩度を上げたいときは1.2
 * @returns
 */
export const changeSaturation = (color: string, changeVal: number): string => {
  color = color.substring(1);

  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  const hsl = rgbToHsl(r, g, b);

  hsl[1] = Math.min(hsl[1] * changeVal, 1.0);

  const [newR, newG, newB] = hslToRgb(hsl[0], hsl[1], hsl[2]);

  const newRHex = newR.toString(16).padStart(2, '0');
  const newGHex = newG.toString(16).padStart(2, '0');
  const newBHex = newB.toString(16).padStart(2, '0');

  return `#${newRHex}${newGHex}${newBHex}`;
};

const rgbToHsl = (r: number, g: number, b: number): number[] => {
  (r /= 255), (g /= 255), (b /= 255);

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }

    h = h / 6;
  }

  return [h, s, l];
};

const hslToRgb = (h: number, s: number, l: number): number[] => {
  let r = 0;
  let g = 0;
  let b = 0;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (
      lightness: number,
      intermediateValue1: number,
      intermediateValue2: number,
    ) => {
      if (intermediateValue2 < 0) {
        intermediateValue2 += 1;
      }
      if (intermediateValue2 > 1) {
        intermediateValue2 -= 1;
      }
      if (intermediateValue2 < 1 / 6) {
        return (
          lightness + (intermediateValue1 - lightness) * 6 * intermediateValue2
        );
      }
      if (intermediateValue2 < 1 / 2) {
        return intermediateValue1;
      }
      if (intermediateValue2 < 2 / 3) {
        return (
          lightness +
          (intermediateValue1 - lightness) * (2 / 3 - intermediateValue2) * 6
        );
      }
      return lightness;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};
