import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { LandmarkRankingComponent } from './landmark-ranking/landmark-ranking.component';
import { ChartUuComponent } from 'src/app/dashboard/ui/components/charts/chart-uu/chart-uu.component';
import {
  lineChartOptions,
  lineChartParams,
  lineChartDataOptions,
} from 'src/app/dashboard/ui/components/charts/chart-options';
import { CsvService } from 'src/app/dashboard/services/csv.service';
import { XlsxService } from '../../../../../services/xlsx.service';
import { FireStoreService } from 'src/app/dashboard/services/fire-store.service';

import { getStrLength } from '../../../../../../util';
import { sumValues } from 'src/app/util';

interface ReferrerData {
  referrer: string;
  page_views: number;
  page_users: number;
  iframe_views: number;
  iframe_users: number;
}
interface ColumnData {
  field: string;
  header: string;
}
interface WeeklyData {
  category: string;
  page_views: number;
  page_users: number;
  iframe_views: number;
  iframe_users: number;
}
interface TooltipContext {
  PageViews: string;
  iframeViews: string;
}
const initTooltipContext = (): TooltipContext => {
  return {
    PageViews: '',
    iframeViews: '',
  };
};
@Component({
  selector: 'app-detailed',
  templateUrl: './detailed.component.html',
  styleUrls: [
    './detailed.component.css',
    '../../../../../../dashboard/common.css',
  ],
})
export class DetailedComponent implements OnInit {
  @Input() mapID!: string;
  @Input() fromDateStr!: string;
  @Input() toDateStr!: string;

  @ViewChild(LandmarkRankingComponent, { static: true })
  landmarkRankingComponent!: LandmarkRankingComponent;

  @ViewChild(ChartUuComponent, { static: true })
  chartUuComponent!: ChartUuComponent;

  pageViews = 0;
  iframeViews = 0;

  cols: ColumnData[] = [];
  weeklyData: WeeklyData[] = [];

  referrerData: ReferrerData[] = [];
  referrerCols: ColumnData[] = [];
  referrerMaxPaging = 7;
  referrerPerPage = 10;
  referrerPageNum = 1;

  openFormatList = false;
  language =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language;

  timezone: string;

  // 翻訳済みテキスト
  title = '';
  views = '';
  pageViewsLabel = '';
  iframeViewsLabel = '';
  download = '';
  referrer = '';
  tooltipContext: TooltipContext = initTooltipContext();
  dataNotFound = '';
  dateLabel = '';

  // グラフ
  dataTypeLandmark = 'landmark';
  chartTitleLandmark = 'landmark';
  chartParamsLandmark = lineChartParams;
  chartOptionsLandmark = lineChartOptions;
  chartDataOptionsLandmark = lineChartDataOptions;

  aggDataKeys: string[] = [];
  constructor(
    private fsService: FireStoreService,
    private translate: TranslateService,
    private csvService: CsvService,
    private xlsxService: XlsxService,
  ) {
    this.translate
      .get([
        'weekly.PageViewsTooltip',
        'weekly.iframeViewsTooltip',
        'weekly.PageViews',
        'weekly.PageUsers',
        'weekly.iframeViews',
        'weekly.iframeUsers',
        'detailed.referrer',
        'detailed.Title',
        'detailed.Views',
        'Download',
        'dashboard.DataNotFound',
        'export.Date',
      ])
      .subscribe((translations) => {
        this.tooltipContext = {
          PageViews: translations['weekly.PageViewsTooltip'],
          iframeViews: translations['weekly.iframeViewsTooltip'],
        };
        this.cols = [
          { field: 'page_views', header: translations['weekly.PageViews'] },
          { field: 'page_users', header: translations['weekly.PageUsers'] },
          {
            field: 'iframe_views',
            header: translations['weekly.iframeViews'],
          },
          {
            field: 'iframe_users',
            header: translations['weekly.iframeUsers'],
          },
        ];
        this.referrerCols = [
          { field: 'referrer', header: translations['detailed.referrer'] },
          { field: 'page_views', header: translations['weekly.PageViews'] },
          { field: 'page_users', header: translations['weekly.PageUsers'] },
          {
            field: 'iframe_views',
            header: translations['weekly.iframeViews'],
          },
          {
            field: 'iframe_users',
            header: translations['weekly.iframeUsers'],
          },
        ];
        this.title = translations['detailed.Title'];
        this.views = translations['detailed.Views'];
        this.pageViewsLabel = translations['weekly.PageViews'];
        this.iframeViewsLabel = translations['weekly.iframeViews'];
        this.download = translations['Download'];
        this.referrer = translations['detailed.referrer'];
        this.dataNotFound = translations['dashboard.DataNotFound'];
        this.dateLabel = translations['export.Date'];
      });

    try {
      this.timezone =
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Tokyo'
          ? 'jst'
          : 'utc';
    } catch (e) {
      const language =
        (window.navigator.languages && window.navigator.languages[0]) ||
        window.navigator.language;
      this.timezone = language.indexOf('ja') != -1 ? 'jst' : 'utc';
    }
  }

  ngOnInit(): void {
    this.refreshChart();
  }

  ngDoCheck(): void {
    this.setData();
  }

  refreshChart(): void {
    this.landmarkRankingComponent.getData(
      this.mapID,
      this.fromDateStr,
      this.toDateStr,
      this.timezone,
    );
    this.chartUuComponent.getAndDrawData(
      this.mapID,
      this.fromDateStr,
      this.toDateStr,
      this.timezone,
    );
    this.getReferrerData();
  }

  setData(): void {
    // FIXME: 何度も呼ばれるので、値をチェックして制御したい
    // if (this.pageViews == undefined) return;
    // else if (this.setDataFlag) return;
    // console.log(this.pageViews != undefined, this.setDataFlag);
    // this.setDataFlag = true;

    this.pageViews = sumValues(this.chartUuComponent.chartData[0].data);
    this.iframeViews = sumValues(this.chartUuComponent.chartData[1].data);

    const gdip: Record<string, number> =
      this.chartUuComponent.graphDataInmap['pv'];
    const gdiu: Record<string, number> =
      this.chartUuComponent.graphDataInmap['uu'];
    const gdipi: Record<string, number> =
      this.chartUuComponent.graphDataInmap['pv_iframe'];
    const gdiui: Record<string, number> =
      this.chartUuComponent.graphDataInmap['uu_iframe'];
    this.weeklyData = [
      {
        category: 'All',
        page_views: sumValues(this.chartUuComponent.chartData[0].data),
        iframe_views: sumValues(this.chartUuComponent.chartData[1].data),
        page_users: sumValues(this.chartUuComponent.chartData[2].data),
        iframe_users: sumValues(this.chartUuComponent.chartData[3].data),
      },
      {
        category: 'inMap',
        page_views: sumValues(Object.values(gdip ? gdip : [0])),
        page_users: sumValues(Object.values(gdiu ? gdiu : [0])),
        iframe_views: sumValues(Object.values(gdipi ? gdipi : [0])),
        iframe_users: sumValues(Object.values(gdiui ? gdiui : [0])),
      },
    ];
  }

  // Ver.1 用
  async getReferrerData(): Promise<void> {
    const { allarea } = await this.fsService.getV1Data(
      'v1referrer',
      this.mapID,
      this.fromDateStr,
      this.toDateStr,
      this.timezone,
    );
    const tableData = allarea;
    const aggData = allarea.pv;

    const sortedData = this.fsService.sortByValue(aggData);
    this.referrerData = [];
    Array.from(sortedData.keys()).map((key) => {
      this.referrerData.push(this.buildTableData(key, tableData));
    });

    this.aggDataKeys = Array.from(sortedData.keys());
    // this.cd.markForCheck(); // marks path
  }

  // Ver.0 用 (廃止予定)
  /*
  getReferrerData(): void {
    // timezone追加版
    // this.item = this.fsService.makeDocumentStream("referrer_" + this.timezone, this.mapID);
    this.item = this.fsService.makeDocumentStream('referrer', this.mapID);
    this.item.subscribe(async docData => {
      const filteredData = this.fsService.filterData(
        docData,
        this.fromDateStr,
        this.toDateStr,
      );
      if (!filteredData) {
        this.aggData = filteredData;
        return;
      }
      const [aggData, tableData] = this.fsService.buildChartAndTableData(
        filteredData,
      );

      const sortedData = this.fsService.sortByValue(aggData);
      this.referrerData = [];
      Array.from(sortedData.keys()).map(key => {
        this.referrerData.push(this.buildTableData(key, tableData));
      });

      this.aggDataKeys = Array.from(sortedData.keys());
      // this.cd.markForCheck(); // marks path
    });
  }
*/

  buildTableData(
    keyString: string,
    data: Record<string, Record<string, number>>,
  ): ReferrerData {
    const obj: ReferrerData = {
      referrer: '',
      page_views: 0,
      page_users: 0,
      iframe_views: 0,
      iframe_users: 0,
    };
    obj['referrer'] = keyString;
    obj['page_views'] = data['pv'][keyString] ? data['pv'][keyString] : 0;
    obj['page_users'] = data['uu'][keyString] ? data['uu'][keyString] : 0;
    obj['iframe_views'] = data['pv_iframe'][keyString]
      ? data['pv_iframe'][keyString]
      : 0;
    obj['iframe_users'] = data['uu_iframe'][keyString]
      ? data['uu_iframe'][keyString]
      : 0;
    return obj;
  }

  exportTable(fileType: 'csv' | 'xlsx'): void {
    const filename =
      'map-page_embedded-views_' + this.fromDateStr + '-' + this.toDateStr;
    const exportData: string[][] = [];
    const inmapData = this.chartUuComponent.graphDataInmap;

    const labelRow = [this.dateLabel];
    labelRow.push(this.chartUuComponent.chartData[0].label);
    labelRow.push(this.chartUuComponent.chartData[2].label);
    labelRow.push(this.chartUuComponent.chartData[1].label);
    labelRow.push(this.chartUuComponent.chartData[3].label);
    labelRow.push(this.chartUuComponent.chartData[0].label + '(inMap)');
    labelRow.push(this.chartUuComponent.chartData[2].label + '(inMap)');
    labelRow.push(this.chartUuComponent.chartData[1].label + '(inMap)');
    labelRow.push(this.chartUuComponent.chartData[3].label + '(inMap)');
    exportData.push(labelRow);

    for (let i = 0; i < this.chartUuComponent.dateLabel.length; i++) {
      const values: string[] = [];
      const targetDate = this.chartUuComponent.dateLabel[i];
      values.push(targetDate);
      values.push(this.chartUuComponent.chartData[0].data[i].toString());
      values.push(this.chartUuComponent.chartData[2].data[i].toString());
      values.push(this.chartUuComponent.chartData[1].data[i].toString());
      values.push(this.chartUuComponent.chartData[3].data[i].toString());
      values.push((Object.values(inmapData)[0][targetDate] || 0).toString());
      values.push((Object.values(inmapData)[2][targetDate] || 0).toString());
      values.push((Object.values(inmapData)[1][targetDate] || 0).toString());
      values.push((Object.values(inmapData)[3][targetDate] || 0).toString());
      exportData.push(values);
    }

    switch (fileType) {
      case 'csv':
        this.csvService.exportChartData(exportData, filename);
        break;
      case 'xlsx':
        this.xlsxService.exportExcel(exportData, filename);
        break;
      default:
        throw Error('Error: 不正なパラメータです');
    }
  }

  getLength(str: string): number {
    return getStrLength(str);
  }
}
