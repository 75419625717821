import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-data-compilation',
  templateUrl: './data-compilation.component.html',
  styleUrls: ['./data-compilation.component.css'],
})
export class DataCompilationComponent implements OnInit {
  message: string[] = [];

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate
      .get(['dataCompilation.Message1', 'dataCompilation.Message2'])
      .subscribe((translations) => {
        this.message = [
          translations['dataCompilation.Message1'],
          translations['dataCompilation.Message2'],
        ];
      });
  }
}
