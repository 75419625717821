import { ChartOptions, ChartType } from 'chart.js';

import { changeSaturation } from './color-converter';

interface ChartParams {
  chartType: ChartType;
  chartLegend: boolean;
  chartColors: { [key: string]: string | string[] | number }[];
}

export const donutsChartOptions: ChartOptions = {
  responsive: true,
  // maintainAspectRatio: false,
  aspectRatio: 1.75,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        boxWidth: 12,
        color: '#333',
      },
    },
    tooltip: {
      mode: 'nearest',
      backgroundColor: '#6666cc',
      borderColor: '#fff',
      borderWidth: 1,
      titleFont: {
        style: 'normal',
      },
      bodySpacing: 6,
    },
  },
};

export const donutsChartParams: ChartParams = {
  chartType: 'doughnut',
  chartLegend: true,
  chartColors: [
    {
      borderWidth: 0,
      backgroundColor: [
        '#6b76cb',
        '#9263bf',
        '#c874be',
        '#ff7daa',
        '#fe9c84',
        '#dbb16e',
        '#e0cb73',
        '#e8e389',
        '#faf871',
        '#b5e274',
        '#87cc5e',
        '#6db73d',
        '#3ec675',
        '#1faa57',
        '#108e43',
        '#087233',
        '#045324',
        '#054d4f',
        '#052a49',
        '#2b418e',
      ],
    },
  ],
};

export const getDonutsChartBackgroundColor = (index: number): string => {
  return (
    [
      '#6b76cb',
      '#9263bf',
      '#c874be',
      '#ff7daa',
      '#fe9c84',
      '#dbb16e',
      '#e0cb73',
      '#e8e389',
      '#faf871',
      '#b5e274',
      '#87cc5e',
      '#6db73d',
      '#3ec675',
      '#1faa57',
      '#108e43',
      '#087233',
      '#045324',
      '#054d4f',
      '#052a49',
      '#2b418e',
    ][index] ?? '#DBDDE2'
  );
};

export const getDonutsChartHoverBackgroundColor = (index: number): string => {
  const backgroundColor = getDonutsChartBackgroundColor(index);
  return changeSaturation(backgroundColor, 1.8);
};
export const lineChartOptions: ChartOptions = {
  elements: {
    point: {
      radius: 4,
    },
  },
  scales: {
    x: {
      ticks: {
        autoSkip: true,
        autoSkipPadding: 10,
        color: '#989eb3',
      },
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        color: '#989eb3',
        maxTicksLimit: 4,
      },
      grid: {
        color: '#d8dde5',
        drawBorder: false,
      },
    },
  },
  plugins: {
    tooltip: {
      mode: 'index',
      backgroundColor: '#6666cc',
      borderColor: '#fff',
      borderWidth: 1,
      titleFont: {
        style: 'normal',
      },
      bodySpacing: 6,
    },
  },
  responsive: true,
  aspectRatio: 4,
  // maintainAspectRatio: false
};

export const lineChartParams: ChartParams = {
  chartType: 'line',
  chartLegend: true,
  chartColors: [
    {
      borderWidth: 0,
      backgroundColor: [
        '#2580BF',
        '#14A1C7',
        '#17C1BC',
        '#3CDC9B',
        '#98EF74',
        '#F7FA5E',
      ],
    },
  ],
};

export const lineChartDataOptions = {
  lineTension: 0,
  fill: false,
  borderWidth: 4,
  pointBorderWidth: 2,
  borderColor: '#67D6AB',
  pointBackgroundColor: '#67E6AB',
  pointHoverBackgroundColor: '#67E6AB',
};

export const adjustDataForChart = (
  keys: string[],
  values: number[],
  limitSize: number,
): { keys: string[]; values: number[] } => {
  let otherCount = 0;
  const adjustedKeys: string[] = [];
  const adjustedValues: number[] = [];
  for (let i = 0; i < keys.length; i++) {
    if (i < limitSize) {
      adjustedKeys.push(keys[i]);
      adjustedValues.push(values[i]);
    } else {
      otherCount += values[i];
    }
  }

  adjustedKeys.push('Others');
  adjustedValues.push(otherCount);

  return {
    keys: adjustedKeys,
    values: adjustedValues,
  };
};
