import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthedUserProfileDto } from './dto/user.dto';

@Injectable({ providedIn: 'root' })
export class UserRepository {
  private readonly http = inject(HttpClient);

  /** ログインユーザのプロフィールを取得する. */
  fetchAuthedUserProfile = async (): Promise<AuthedUserProfileDto> => {
    const apiUrl = `${environment.authApiHost}/api/v1/r/authed-user-profile/`;

    // NOTE: 仕様が残ってないのでリストで返ってくる意図は不明.
    return (
      await firstValueFrom(
        this.http.get<AuthedUserProfileDto[]>(apiUrl, {
          withCredentials: true,
        }),
      )
    )[0];
  };
}
